import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Divider,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Chip,
  Tab,
  Tabs,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  Sync as SyncIcon,
  Add as AddIcon,
  AccountBalance as BankIcon,
  CurrencyBitcoin as CryptoIcon,
  ContentCopy as CopyIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  VisibilityOff as HideIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  CreditCard as CreditCard,
  Edit as EditIcon,
  KeyboardArrowUp as ArrowUpIcon,
  KeyboardArrowDown as ArrowDownIcon
} from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';
import PlaidLink from '../components/PlaidLink';
import AccountList from '../components/AccountList';
import Layout from '../components/Layout';
import { useAuth } from '../contexts/AuthContext';
import { useError } from '../contexts/ErrorContext';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import { colors } from '../styles';
import BitcoinAddressModal from '../components/BitcoinAddressModal';
import CountUp from 'react-countup';
import { syncTransactions } from '../services/transactionService';
import PricingModal from '../components/PricingModal';
import config from '../config';
import { StyledDialog, StyledTextField, StyledButton } from '../components/common/StyledInputs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: colors.white,
  boxShadow: 'none',
  padding: '32px',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: 'none'
  }
}));

const StyledStatCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: colors.white,
  boxShadow: 'none',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: 'none'
  }
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${colors.primary}10`,
  marginBottom: theme.spacing(2),
  '& svg': {
    color: colors.primary,
    fontSize: '24px'
  }
}));

const StatCard = ({ title, icon, amount, subtext, error }) => (
  <StyledStatCard>
    <CardContent sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CategoryIcon>
          {icon}
        </CategoryIcon>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" sx={{ 
          mb: 1, 
          fontWeight: 600,
          color: error ? 'error.main' : 'inherit'
        }}>
          ${Math.abs(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </Typography>
        {subtext && (
          <Typography variant="body2" color="text.secondary" sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 0.5 
          }}>
            {subtext}
          </Typography>
        )}
      </Box>
    </CardContent>
  </StyledStatCard>
);

const AddressField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: `${colors.primary}05`,
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 2
      }
    }
  }
}));

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinningIcon = styled(SyncIcon)(({ spinning }) => ({
  animation: spinning ? `${spin} 2s linear infinite` : 'none'
}));

const RenameAccountDialog = ({ open, onClose, accountId, currentName = '', onRename }) => {
  const [newName, setNewName] = useState(currentName || '');
  const { showError, showSuccess } = useError();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Reset the name when the dialog opens with a new account
  React.useEffect(() => {
    if (open && currentName) {
      setNewName(currentName);
    }
  }, [open, currentName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newName?.trim()) {
      setErrorMessage('Account name is required');
      return;
    }
    setErrorMessage('');
    setLoading(true);

    try {
      await api.patch(`/accounts/${accountId}/display-name`, { 
        displayName: newName.trim() 
      });
      
      onRename(accountId, newName.trim());
      showSuccess('Account renamed successfully');
      onClose();
    } catch (err) {
      console.error('Error renaming account:', err);
      const message = err.response?.data?.error || err.message || 'Failed to update account name';
      setErrorMessage(typeof message === 'string' ? message : 'An unexpected error occurred');
      showError('Failed to rename account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="xs" 
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ 
          p: 0, 
          mb: 3,
          fontSize: '1.5rem',
          fontWeight: 600
        }}>
          Rename Account
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <StyledTextField
            autoFocus
            label="Account Name"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage || ' '}
            disabled={loading}
            placeholder="Enter account name"
          />
        </DialogContent>
        <DialogActions sx={{ 
          p: 0,
          pt: 3, 
          gap: 2
        }}>
          <StyledButton 
            onClick={onClose} 
            disabled={loading}
            variant="outlined"
            fullWidth
          >
            Cancel
          </StyledButton>
          <StyledButton 
            type="submit" 
            variant="contained" 
            disabled={loading || !newName?.trim()}
            fullWidth
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </StyledButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

const Money = () => {
  const { user, hasFeature } = useAuth();
  const navigate = useNavigate();
  const { showError, showSuccess } = useError();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [newAddress, setNewAddress] = useState('');
  const [cryptoAccounts, setCryptoAccounts] = useState([]);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [syncingAccounts, setSyncingAccounts] = useState(new Set());
  const [lastSyncTimes, setLastSyncTimes] = useState({});
  const [isSyncing, setIsSyncing] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else {
      // Check if user has access to transaction tracking
      if (hasFeature('transaction_tracking')) {
        fetchAccounts();
        fetchBitcoinAddresses();
        fetchTransactions();
      } else {
        showError('Please upgrade to access this feature');
        navigate('/pricing');
      }
    }
  }, [user, navigate, refreshTrigger, hasFeature]);

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/plaid/accounts');
      
      // Track syncing accounts and last sync times
      const syncing = new Set();
      const syncTimes = {};
      
      response.data.forEach(pa => {
        pa.accounts.forEach(acc => {
          if (acc.syncStatus === 'syncing') {
            syncing.add(acc.id);
          }
          if (acc.lastSuccessfulSync) {
            syncTimes[acc.id] = new Date(acc.lastSuccessfulSync);
          }
        });
      });
      
      setSyncingAccounts(syncing);
      setLastSyncTimes(syncTimes);

      // Sort accounts within each institution by their sort order
      const sortedAccounts = response.data.map(pa => ({
        ...pa,
        accounts: [...pa.accounts].sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
      }));

      setAccounts(sortedAccounts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      showError('Failed to fetch accounts');
      setLoading(false);
    }
  };

  const fetchBitcoinAddresses = async () => {
    try {
      const response = await api.get('/bitcoin/addresses');
      setCryptoAccounts(response.data.map(addr => ({
        id: addr._id,
        address: addr.address,
        balance: addr.balance.btc,
        usdValue: addr.balance.usd,
        change24h: 0, // We'll need to calculate this from historical data
        type: 'BTC'
      })));
    } catch (error) {
      console.error('Error fetching Bitcoin addresses:', error);
      showError('Failed to fetch Bitcoin addresses');
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await api.get('/transactions', {
        params: {
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 2)).toISOString(),
          endDate: new Date().toISOString()
        }
      });
      setTransactions(response.data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      showError('Failed to fetch transactions');
    }
  };

  const handlePlaidSuccess = async () => {
    try {
      // Check if adding another account would exceed the limit
      if (!hasFeature('unlimited_accounts') && accounts.length >= 2) {
        setShowUpgradeModal(true);
        return;
      }

      setLoading(true);
      await fetchAccounts();
      showSuccess('Account connected successfully! Initial sync has started.');
    } catch (error) {
      console.error('Error refreshing accounts:', error);
      showError('Failed to refresh accounts list');
    } finally {
      setLoading(false);
    }
  };

  const handlePlaidExit = (error) => {
    if (error) {
      console.error('Plaid Link error:', error);
    }
  };

  const handleSync = async (accountId) => {
    try {
      console.log('Starting sync for account:', accountId);
      setSyncingAccounts(prev => new Set([...prev, accountId]));
      const result = await syncTransactions(accountId, true);
      const { stats } = result;
      console.log('Sync results:', stats);

      // Call the default categorizer for new transactions
      if (stats.added > 0 || stats.modified > 0) {
        console.log('New or modified transactions found, fetching uncategorized transactions...');
        try {
          const uncategorizedTransactions = await api.get('/transactions/uncategorized');
          console.log('Uncategorized transactions:', uncategorizedTransactions.data);
          
          if (uncategorizedTransactions.data.length > 0) {
            console.log('Calling categorizer model...');
            // Call the default categorizer model
            const categorizerResponse = await api.post('/ai/categorize-transactions', {
              transactions: uncategorizedTransactions.data
            });
            console.log('Categorizer response:', categorizerResponse.data);

            // Apply categories only to transactions without user-set categories
            if (categorizerResponse.data.categorizedTransactions) {
              console.log('Applying categories...');
              const applyResponse = await api.post('/transactions/apply-categories', {
                transactions: categorizerResponse.data.categorizedTransactions,
                preserveUserCategories: true
              });
              console.log('Apply categories response:', applyResponse.data);

              // Store any categorization issues
              if (applyResponse.data.issues?.length > 0) {
                console.log('Categorization issues found:', applyResponse.data.issues);
                const issues = {
                  timestamp: new Date().toISOString(),
                  count: applyResponse.data.issues.length,
                  details: applyResponse.data.issues
                };

                // Store in localStorage
                const storedIssues = JSON.parse(localStorage.getItem('categorizerIssues') || '[]');
                storedIssues.unshift(issues);
                localStorage.setItem('categorizerIssues', JSON.stringify(storedIssues.slice(0, 10)));

                // Emit event for activity page
                console.log('Emitting categorizer issues event');
                window.dispatchEvent(new CustomEvent('categorizerIssues', { 
                  detail: issues 
                }));
              }
            }
          } else {
            console.log('No uncategorized transactions found');
          }
        } catch (categorizerError) {
          console.error('Error running transaction categorizer:', categorizerError);
          console.log('Categorizer error details:', {
            message: categorizerError.message,
            response: categorizerError.response?.data
          });
          
          // Store the error as an issue
          const errorIssue = {
            timestamp: new Date().toISOString(),
            count: 1,
            details: [{
              type: 'error',
              message: categorizerError.message || 'Failed to categorize transactions',
              error: categorizerError
            }]
          };

          // Store in localStorage
          const storedIssues = JSON.parse(localStorage.getItem('categorizerIssues') || '[]');
          storedIssues.unshift(errorIssue);
          localStorage.setItem('categorizerIssues', JSON.stringify(storedIssues.slice(0, 10)));

          // Emit event for activity page
          console.log('Emitting categorizer error event');
          window.dispatchEvent(new CustomEvent('categorizerIssues', { 
            detail: errorIssue 
          }));
        }
      } else {
        console.log('No new or modified transactions to categorize');
      }
      
      // Update last sync time
      setLastSyncTimes(prev => ({
        ...prev,
        [accountId]: new Date()
      }));
      
      showSuccess(
        `Sync completed: ${stats.total} transactions (${stats.added} new, ${stats.modified} updated, ${stats.removed} removed)${
          stats.isFullSync ? ' - Full 2-year sync' : ''
        }`
      );
      
      setSyncingAccounts(prev => {
        const next = new Set(prev);
        next.delete(accountId);
        return next;
      });
      
      await fetchAccounts();
      await fetchTransactions();
    } catch (error) {
      console.error('Error syncing transactions:', error);
      console.log('Sync error details:', {
        message: error.message,
        response: error.response?.data
      });
      showError('Failed to sync transactions. Please try again.');
      
      setSyncingAccounts(prev => {
        const next = new Set(prev);
        next.delete(accountId);
        return next;
      });
    }
  };

  const handleAddCryptoAddress = async (address) => {
    if (!address) return;
    
    try {
      setLoading(true);
      const response = await api.post('/bitcoin/addresses', { address });
      const newBitcoinAccount = {
        id: response.data._id,
        address: response.data.address,
        balance: response.data.balance.btc,
        usdValue: response.data.balance.usd,
        change24h: 0,
        type: 'BTC'
      };
      
      setCryptoAccounts(prev => [...prev, newBitcoinAccount]);
      showSuccess('Bitcoin address added successfully!');
    } catch (error) {
      showError(error.response?.data?.error || 'Failed to add Bitcoin address. Please verify the address and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCryptoAddress = async (addressId) => {
    try {
      const addressToRemove = cryptoAccounts.find(acc => acc.id === addressId);
      if (!addressToRemove) return;

      await api.delete(`/bitcoin/addresses/${addressToRemove.address}`);
      setCryptoAccounts(prev => prev.filter(account => account.id !== addressId));
      showSuccess('Bitcoin address removed successfully!');
    } catch (error) {
      showError('Failed to remove Bitcoin address. Please try again.');
    }
  };

  const syncBitcoinBalances = async () => {
    try {
      const response = await api.post('/bitcoin/sync');
      setCryptoAccounts(response.data.map(addr => ({
        id: addr._id,
        address: addr.address,
        balance: addr.balance.btc,
        usdValue: addr.balance.usd,
        change24h: 0,
        type: 'BTC'
      })));
      showSuccess('Bitcoin balances updated successfully!');
    } catch (error) {
      showError('Failed to sync Bitcoin balances');
    }
  };

  const handleTestWebhook = async (account) => {
    if (!account.accessToken) {
      showError('Access token not available. Please reconnect the account.');
      return;
    }

    if (!account.accessToken.includes('sandbox')) {
      showError('This account is not a sandbox account. Please use a test account to fire webhooks.');
      return;
    }

    try {
      await api.post('/plaid/sandbox/fire_webhook', {
        access_token: account.accessToken,
        webhook_code: 'SYNC_UPDATES_AVAILABLE'
      });
      
      showSuccess('Test webhook fired successfully');
    } catch (error) {
      console.error('Error firing test webhook:', error);
      const errorMessage = error.response?.data?.error_message || error.message || 'Failed to fire test webhook';
      showError(`Failed to fire test webhook: ${errorMessage}`);
    }
  };

  const renderSyncButton = (account) => {
    const isSyncing = syncingAccounts.has(account.id);
    const lastSync = lastSyncTimes[account.id];
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Tooltip title={lastSync ? `Last synced: ${lastSync}` : 'Sync account'}>
          <IconButton
            onClick={() => handleSync(account.id)}
            disabled={isSyncing}
            size="small"
          >
            <SpinningIcon spinning={isSyncing ? 1 : 0} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    showSuccess('Address copied to clipboard!');
  };

  const getTotalBalance = () => {
    if (!accounts) return 0;
    
    return accounts.reduce((sum, institution) => {
      return sum + institution.accounts
        .filter(account => account?.type !== 'credit' && account?.subtype !== 'credit card')
        .reduce((accSum, account) => accSum + (account?.balance?.current || 0), 0);
    }, 0);
  };

  const getBitcoinBalance = () => {
    return cryptoAccounts.reduce((total, account) => total + (account.usdValue || 0), 0);
  };

  const getCreditCardBalance = () => {
    if (!accounts) return 0;
    
    return accounts.reduce((sum, institution) => {
      return sum + institution.accounts
        .filter(account => account?.type === 'credit' || account?.subtype === 'credit card')
        .reduce((accSum, account) => accSum + (account?.balance?.current || 0), 0);
    }, 0);
  };

  const getRemainingCredit = () => {
    if (!accounts) return 0;
    
    return accounts
      .filter(account => account?.type === 'credit' || account?.subtype === 'credit card')
      .reduce((total, account) => total + (account?.balance?.available || 0), 0);
  };

  const getMonthlyIncome = () => {
    if (!transactions.length) return 0;
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    return transactions
      .filter(t => {
        const transactionDate = new Date(t.date);
        return transactionDate.getMonth() === currentMonth &&
               transactionDate.getFullYear() === currentYear &&
               t.amount > 0 && // Positive amounts are credits/income
               !t.pending;
      })
      .reduce((sum, t) => sum + t.amount, 0);
  };

  const getIncomeChange = () => {
    if (!transactions.length) return 0;
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const currentYear = currentDate.getFullYear();
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    
    const currentMonthIncome = transactions
      .filter(t => {
        const transactionDate = new Date(t.date);
        return transactionDate.getMonth() === currentMonth &&
               transactionDate.getFullYear() === currentYear &&
               t.amount > 0 &&
               !t.pending;
      })
      .reduce((sum, t) => sum + t.amount, 0);
    
    const lastMonthIncome = transactions
      .filter(t => {
        const transactionDate = new Date(t.date);
        return transactionDate.getMonth() === lastMonth &&
               transactionDate.getFullYear() === lastMonthYear &&
               t.amount > 0 &&
               !t.pending;
      })
      .reduce((sum, t) => sum + t.amount, 0);
    
    if (lastMonthIncome === 0) return 0;
    return Math.round(((currentMonthIncome - lastMonthIncome) / lastMonthIncome) * 100);
  };

  const getMonthlyExpenses = () => {
    if (!transactions.length) return 0;
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    return Math.abs(transactions
      .filter(t => {
        const transactionDate = new Date(t.date);
        return transactionDate.getMonth() === currentMonth &&
               transactionDate.getFullYear() === currentYear &&
               t.amount < 0 && // Negative amounts are debits/expenses
               !t.pending;
      })
      .reduce((sum, t) => sum + t.amount, 0));
  };

  const handleRenameClick = (accountId, currentName) => {
    setSelectedAccount({
      id: accountId,
      name: currentName
    });
    setRenameDialogOpen(true);
  };

  const handleRenameComplete = async (accountId, newName) => {
    // Update local state immediately
    setAccounts(prevAccounts => 
      prevAccounts.map(pa => ({
        ...pa,
        accounts: pa.accounts.map(acc => 
          acc.id === accountId 
            ? { 
                ...acc, 
                displayName: newName
              }
            : acc
        )
      }))
    );
    
    // Refresh accounts in the background
    setTimeout(async () => {
      try {
        const response = await api.get('/plaid/accounts');
        if (response.data) {
          setAccounts(currentAccounts => {
            const newAccounts = response.data;
            // Preserve display names from current state
            return newAccounts.map(newPA => ({
              ...newPA,
              accounts: newPA.accounts.map(newAcc => {
                const currentAcc = currentAccounts
                  .flatMap(pa => pa.accounts)
                  .find(acc => acc.id === newAcc.id);
                return {
                  ...newAcc,
                  displayName: currentAcc?.displayName || newAcc.displayName || newAcc.name
                };
              })
            }));
          });
        }
      } catch (err) {
        console.error('Error refreshing accounts after rename:', err);
      }
    }, 2000); // Increased delay to ensure server has processed the update
  };

  const renderAccounts = () => {
    if (!accounts) return null;

    const flatAccounts = accounts.reduce((acc, pa) => {
      return acc.concat(pa.accounts.map(account => ({
        ...account,
        institution: pa.institution,
        itemId: pa.itemId
      })));
    }, []);

    flatAccounts.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));

    const handleDragEnd = async (result) => {
      if (!result.destination) return;

      const items = Array.from(flatAccounts);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // Update sort orders (100, 200, 300, etc.)
      const sortOrder = items.map((account, index) => ({
        accountId: account.id,
        order: (index + 1) * 100
      }));

      try {
        await api.patch('/accounts/sort-order', { sortOrder });

        // Group accounts back by institution
        const groupedAccounts = accounts.map(pa => ({
          ...pa,
          accounts: items
            .filter(acc => acc.itemId === pa.itemId)
            .map(acc => ({
              ...acc,
              sortOrder: sortOrder.find(so => so.accountId === acc.id)?.order || 0
            }))
        }));

        setAccounts(groupedAccounts);
        showSuccess('Account order updated successfully');
      } catch (err) {
        console.error('Error updating account order:', err);
        showError('Failed to update account order');
        await fetchAccounts();
      }
    };

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-accounts">
          {(provided) => (
            <div 
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
            >
              {flatAccounts.map((account, index) => (
                <Draggable 
                  key={account.id} 
                  draggableId={account.id} 
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <StyledCard
                        sx={{
                          transform: snapshot.isDragging ? 'scale(1.02)' : 'none',
                          transition: 'transform 0.2s ease-in-out'
                        }}
                      >
                        <Grid 
                          container 
                          spacing={2} 
                          alignItems="center" 
                          wrap="nowrap"
                          sx={{ minHeight: 'unset' }}
                        >
                          <Grid 
                            item
                            sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              color: 'text.secondary',
                              '&:hover': {
                                color: 'text.primary'
                              }
                            }}
                          >
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <path 
                                fill="currentColor" 
                                d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
                              />
                            </svg>
                          </Grid>
                          <Grid item>
                            {account.institution?.logo ? (
                              <Box
                                component="img"
                                src={`data:image/png;base64,${account.institution.logo}`}
                                alt={account.institution.name}
                                sx={{
                                  width: 36,
                                  height: 36,
                                  borderRadius: '8px',
                                  objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <CategoryIcon sx={{ width: 36, height: 36, mb: 0 }}>
                                <BankIcon />
                              </CategoryIcon>
                            )}
                          </Grid>
                          <Grid item xs>
                            <Typography 
                              variant="subtitle1" 
                              sx={{ 
                                fontWeight: 700,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 0.5
                              }}
                            >
                              {account.institution?.name || 'Unknown Institution'}
                              <Chip
                                label={account.subtype?.toUpperCase() || account.type?.toUpperCase()}
                                size="small"
                                sx={{
                                  backgroundColor: `${colors.primary}10`,
                                  color: colors.primary,
                                  fontWeight: 600,
                                  height: 20,
                                  '& .MuiChip-label': { px: 1, py: 0 }
                                }}
                              />
                            </Typography>
                            <Typography 
                              variant="body2" 
                              color="text.secondary"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5
                              }}
                            >
                              {account.displayName || account.name} {account.mask ? `••••${account.mask}` : ''}
                              <Tooltip title="Rename Account">
                                <IconButton
                                  size="small"
                                  onClick={() => handleRenameClick(account.id, account.displayName || account.name)}
                                  sx={{ 
                                    opacity: 0.6, 
                                    '&:hover': { opacity: 1 },
                                    p: 0.25
                                  }}
                                >
                                  <EditIcon sx={{ fontSize: '1rem' }} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Box sx={{ 
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 0.5
                            }}>
                              <Typography 
                                variant="h6" 
                                sx={{ 
                                  fontWeight: 700,
                                  lineHeight: 1.1
                                }}
                              >
                                ${(account.balance?.current || 0).toLocaleString()}
                              </Typography>
                              {account.balance?.available !== undefined && (
                                <Typography 
                                  variant="body2" 
                                  color="text.secondary"
                                  sx={{ lineHeight: 1.1 }}
                                >
                                  ${(account.balance?.available || 0).toLocaleString()} available
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                          <Grid item sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                            <Chip
                              label={account.syncStatus?.toUpperCase() || 'UNKNOWN'}
                              size="small"
                              sx={{
                                backgroundColor: account.syncStatus?.toLowerCase() === 'ready' 
                                  ? '#4CAF5010' 
                                  : account.syncStatus?.toLowerCase() === 'syncing'
                                  ? '#FF980010'
                                  : account.syncStatus?.toLowerCase() === 'error'
                                  ? '#FD587510'
                                  : '#9E9E9E10',
                                color: account.syncStatus?.toLowerCase() === 'ready'
                                  ? '#4CAF50'
                                  : account.syncStatus?.toLowerCase() === 'syncing'
                                  ? '#FF9800'
                                  : account.syncStatus?.toLowerCase() === 'error'
                                  ? '#FD5875'
                                  : '#9E9E9E',
                                fontWeight: 600,
                                height: 20,
                                '& .MuiChip-label': { px: 1, py: 0 }
                              }}
                            />
                            {renderSyncButton(account)}
                            <IconButton 
                              size="small" 
                              color="error"
                              sx={{ p: 0.75 }}
                              onClick={async () => {
                                try {
                                  await api.delete(`/plaid/accounts/${account.id}`);
                                  showSuccess('Account removed successfully');
                                  fetchAccounts();
                                } catch (error) {
                                  console.error('Error removing account:', error);
                                  showError('Failed to remove account. Please try again.');
                                }
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </StyledCard>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ 
        p: { xs: 3, sm: 6, md: 8 }  // Apply consistent padding on all sides
      }}>
        <Grid container spacing={3}>
          {/* Header with Balances - Analytics no longer gated */}
          <Grid 
            container 
            spacing={2}  // Consistent spacing
            sx={{
              mb: 3,  // Normal margin bottom
              width: '100%'  // Full width
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <StatCard
                title="Total Balance"
                icon={<BankIcon sx={{ fontSize: '28px' }} />}
                amount={getTotalBalance()}
                subtext={
                  <>
                    <TrendingUpIcon fontSize="small" />
                    +3.2% total
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard
                title="Monthly Income"
                icon={<TrendingUpIcon sx={{ fontSize: '24px' }} />}
                amount={getMonthlyIncome()}
                subtext={
                  <>
                    <TrendingUpIcon fontSize="small" />
                    {getIncomeChange()}% vs last month
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard
                title="Monthly Expenses"
                icon={<TrendingDownIcon sx={{ fontSize: '24px' }} />}
                amount={getMonthlyExpenses()}
                subtext={
                  <>
                    <TrendingDownIcon fontSize="small" />
                    -12% vs last month
                  </>
                }
                error={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatCard
                title="Credit Cards"
                icon={<CreditCard sx={{ fontSize: '24px' }} />}
                amount={getCreditCardBalance()}
                subtext={`$${getRemainingCredit().toLocaleString()} available`}
                error={true}
              />
            </Grid>
          </Grid>

          {/* Tabs */}
          <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Tabs 
                value={activeTab} 
                onChange={(e, newValue) => setActiveTab(newValue)}
                sx={{
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '1rem',
                    minWidth: 100,
                    color: 'text.secondary',
                    '&.Mui-selected': {
                      color: colors.primary
                    }
                  }
                }}
              >
                <Tab 
                  icon={<BankIcon />} 
                  iconPosition="start" 
                  label="Banks" 
                />
                <Tab 
                  icon={<CryptoIcon />} 
                  iconPosition="start" 
                  label="Bitcoin" 
                />
              </Tabs>
              {activeTab === 0 && accounts?.length > 0 ? (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  {process.env.REACT_APP_PLAID_ENV === 'sandbox' && (
                    <Button
                      variant="outlined"
                      startIcon={<SyncIcon />}
                      onClick={async () => {
                        // Find the first sandbox account
                        const sandboxAccount = accounts.reduce((found, institution) => {
                          if (found) return found;
                          return institution.accounts.find(acc => acc.accessToken?.includes('sandbox'));
                        }, null);

                        if (sandboxAccount) {
                          handleTestWebhook(sandboxAccount);
                        } else {
                          showError('No sandbox accounts available. Please connect a test account using the Plaid Sandbox.');
                        }
                      }}
                      sx={{
                        borderRadius: '50px',
                        textTransform: 'none',
                        px: 3,
                        py: 1,
                        borderColor: colors.primary,
                        color: colors.primary,
                        '&:hover': {
                          borderColor: colors.primary,
                          backgroundColor: colors.primary + '10'
                        }
                      }}
                    >
                      Test Webhook
                    </Button>
                  )}
                  <PlaidLink
                    onSuccess={handlePlaidSuccess}
                    onExit={handlePlaidExit}
                    buttonProps={{
                      sx: {
                        borderRadius: '50px',
                        textTransform: 'none',
                        px: 3,
                        py: 1,
                        backgroundColor: colors.primary,
                        color: 'white',
                        '&:hover': {
                          backgroundColor: colors.primary,
                          opacity: 0.9
                        }
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AddIcon /> Add Account
                    </Box>
                  </PlaidLink>
                </Box>
              ) : activeTab === 1 && cryptoAccounts.length > 0 ? (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenAddressModal(true)}
                  sx={{
                    borderRadius: '50px',
                    textTransform: 'none',
                    px: 3,
                    py: 1,
                    backgroundColor: colors.primary,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: colors.primary,
                      opacity: 0.9
                    }
                  }}
                >
                  Add Account
                </Button>
              ) : null}
            </Box>
          </Box>

          {/* Bank Accounts Tab - Only account limits are gated */}
          {activeTab === 0 && (
            <Box sx={{ width: '100%' }}>
              <Typography variant="h6" sx={{ fontWeight: 700, mb: 3 }}>
                Bank Accounts
              </Typography>

              {accounts?.length > 0 && renderAccounts()}

              {(!accounts || accounts.length === 0) && !loading && (
                <StyledCard>
                  <CardContent sx={{ 
                    textAlign: 'center',
                    py: 6,
                    minHeight: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <BankIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                    <Typography variant="h6" gutterBottom>
                      No Bank Accounts Connected
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                      Connect your bank account to track your finances in one place.
                    </Typography>
                    <PlaidLink
                      onSuccess={handlePlaidSuccess}
                      onExit={handlePlaidExit}
                      buttonProps={{
                        sx: {
                          borderRadius: '50px',
                          textTransform: 'none',
                          px: 3,
                          py: 1,
                          backgroundColor: colors.primary,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: colors.primary,
                            opacity: 0.9
                          }
                        }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AddIcon /> Add Account
                      </Box>
                    </PlaidLink>
                  </CardContent>
                </StyledCard>
              )}

              {loading && (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  py: 6,
                  minHeight: '400px'
                }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          )}

          {/* Bitcoin Tab */}
          {activeTab === 1 && (
            <Box sx={{ width: '100%' }}>
              <Typography variant="h6" sx={{ fontWeight: 700, mb: 3 }}>
                Bitcoin Addresses
              </Typography>

              {cryptoAccounts.length > 0 && (
                <Grid container spacing={3}>
                  {cryptoAccounts.map((account) => (
                    <Grid item xs={12} key={account.id}>
                      <StyledCard>
                        <CardContent>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <CategoryIcon sx={{ mb: 0 }}>
                                <CryptoIcon />
                              </CategoryIcon>
                            </Grid>
                            <Grid item xs>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                  Bitcoin Address
                                </Typography>
                                <Chip
                                  label={account.type}
                                  size="small"
                                  sx={{
                                    backgroundColor: `${colors.primary}10`,
                                    color: colors.primary,
                                    fontWeight: 600
                                  }}
                                />
                                <Chip
                                  label={account.change24h >= 0 ? `+${account.change24h}%` : `${account.change24h}%`}
                                  size="small"
                                  icon={account.change24h >= 0 ? <TrendingUpIcon /> : <TrendingDownIcon />}
                                  sx={{
                                    backgroundColor: account.change24h >= 0 ? `${colors.status.ready}10` : `${colors.status.error}10`,
                                    color: account.change24h >= 0 ? colors.status.ready : colors.status.error,
                                    fontWeight: 600,
                                    '& .MuiChip-icon': {
                                      color: 'inherit'
                                    }
                                  }}
                                />
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="body2" color="text.secondary" sx={{ 
                                  fontFamily: 'monospace',
                                  fontSize: '0.9rem'
                                }}>
                                  {account.address.slice(0, 8)}...{account.address.slice(-8)}
                                </Typography>
                                <IconButton size="small" onClick={() => copyToClipboard(account.address)}>
                                  <CopyIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Grid>
                            <Grid item>
                              <Box sx={{ textAlign: 'right' }}>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                  ${account.usdValue.toLocaleString()}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {account.balance.toFixed(8)} BTC
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item>
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <IconButton 
                                  size="small" 
                                  onClick={syncBitcoinBalances}
                                >
                                  <SyncIcon />
                                </IconButton>
                                <IconButton 
                                  size="small" 
                                  color="error"
                                  onClick={() => handleRemoveCryptoAddress(account.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </StyledCard>
                    </Grid>
                  ))}
                </Grid>
              )}

              {cryptoAccounts.length === 0 && !loading && (
                <StyledCard>
                  <CardContent sx={{ 
                    textAlign: 'center',
                    py: 6,
                    minHeight: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <CryptoIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                    <Typography variant="h6" gutterBottom>
                      No Bitcoin Addresses
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                      Add a Bitcoin address to track its balance and transactions.
                    </Typography>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => setOpenAddressModal(true)}
                      sx={{
                        borderRadius: '50px',
                        textTransform: 'none',
                        px: 3,
                        py: 1,
                        backgroundColor: colors.primary,
                        color: 'white',
                        '&:hover': {
                          backgroundColor: colors.primary,
                          opacity: 0.9
                        }
                      }}
                    >
                      Add Account
                    </Button>
                  </CardContent>
                </StyledCard>
              )}

              {loading && (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  py: 6,
                  minHeight: '400px'
                }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Container>
      <BitcoinAddressModal
        open={openAddressModal}
        onClose={() => setOpenAddressModal(false)}
        onSave={handleAddCryptoAddress}
      />
      <RenameAccountDialog
        open={renameDialogOpen}
        onClose={() => {
          setRenameDialogOpen(false);
          setSelectedAccount(null);
        }}
        accountId={selectedAccount?.id}
        currentName={selectedAccount?.name}
        onRename={handleRenameComplete}
      />
      <PricingModal 
        open={showUpgradeModal} 
        onClose={() => setShowUpgradeModal(false)} 
      />
    </Layout>
  );
};

export default Money;

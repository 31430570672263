import React, { createContext, useContext, useState, useCallback } from 'react';
import * as alertService from '../services/alertService';
import { useAuth } from './AuthContext';
import Logger from '../utils/logger';

const AlertContext = createContext();

export const useAlerts = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlerts must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    hasMore: false
  });

  // Fetch alerts with optional filters
  const fetchAlerts = useCallback(async (filters = {}) => {
    if (!isAuthenticated) {
      Logger.debug('Skipping alert fetch - user not authenticated');
      return;
    }
    
    Logger.debug('Starting alert fetch with filters:', filters);
    setLoading(true);
    setError(null);
    
    try {
      Logger.debug('Making API call to fetch alerts');
      const response = await alertService.getAlerts(filters);
      Logger.debug('Alert fetch response:', {
        alertCount: response.alerts?.length,
        pagination: response.pagination,
        firstAlert: response.alerts?.[0]
      });
      
      // Update alerts based on whether we're appending or replacing
      if (filters.page > 1) {
        setAlerts(prev => [...prev, ...response.alerts]);
      } else {
        setAlerts(response.alerts);
      }
      setPagination(response.pagination);
    } catch (err) {
      const errorMessage = err.message || 'Failed to fetch alerts';
      Logger.error('Error fetching alerts:', {
        error: err,
        message: errorMessage,
        status: err.response?.status,
        responseData: err.response?.data
      });
      setError(errorMessage);
    } finally {
      setLoading(false);
      Logger.debug('Alert fetch completed, state updated');
    }
  }, [isAuthenticated]);

  // Mark alerts as read
  const markAsRead = useCallback(async (alertIds) => {
    try {
      Logger.debug('Marking alerts as read:', alertIds);
      await alertService.markAlertsAsRead(alertIds);
      setAlerts(prev => 
        prev.map(alert => 
          alertIds.includes(alert._id) 
            ? { ...alert, status: 'read' }
            : alert
        )
      );
    } catch (error) {
      Logger.error('Error marking alerts as read:', error);
      throw error;
    }
  }, []);

  // Mark alert as resolved
  const resolve = useCallback(async (alertId) => {
    try {
      Logger.debug('Marking alert as resolved:', alertId);
      await alertService.markAlertAsResolved(alertId);
      setAlerts(prev => 
        prev.map(alert => 
          alert._id === alertId 
            ? { ...alert, status: 'resolved' }
            : alert
        )
      );
    } catch (error) {
      Logger.error('Error marking alert as resolved:', error);
      throw error;
    }
  }, []);

  // Dismiss alerts
  const dismiss = useCallback(async (alertIds) => {
    try {
      Logger.debug('Dismissing alerts:', alertIds);
      await alertService.dismissAlerts(alertIds);
      setAlerts(prev => prev.filter(alert => !alertIds.includes(alert._id)));
    } catch (error) {
      Logger.error('Error dismissing alerts:', error);
      throw error;
    }
  }, []);

  const value = {
    alerts,
    loading,
    error,
    pagination,
    fetchAlerts,
    markAsRead,
    resolve,
    dismiss
  };

  return (
    <AlertContext.Provider value={value}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext; 
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, Select, FormControl, InputLabel, Box } from '@mui/material';
import { colors } from '../../styles';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: colors.secondary,
    fontSize: '0.95rem',
    fontWeight: 500,
    '&.Mui-focused': {
      color: colors.primary
    }
  }
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    position: 'relative',
    transform: 'none',
    marginBottom: '8px',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    fontWeight: 400,
    '&.Mui-focused': {
      color: theme.palette.text.secondary
    }
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.grey[50],
    transition: 'all 0.2s ease-in-out',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-input': {
    padding: '16px',
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 0.7
    }
  },
  '& .MuiInputAdornment-root': {
    '& .MuiTypography-root': {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1)
    }
  }
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: theme.palette.grey[100],
  transition: 'all 0.2s ease-in-out',
  '&.Mui-focused': {
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[200]
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&.Mui-focused': {
    color: theme.palette.primary.main
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  padding: '12px 32px',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1rem',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none'
  },
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  '&.MuiButton-outlined': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300]
    }
  }
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
  }
}));

export const StyledIconButton = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: `${colors.primary}05`,
  color: colors.primary,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: `${colors.primary}10`,
    transform: 'translateY(-2px)'
  }
}));

export const StyledIconGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  maxWidth: '240px',
  maxHeight: '300px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.grey[100],
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[300],
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.grey[400]
    }
  }
})); 
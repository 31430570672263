import api from './api';

export const getBudgets = async () => {
  const response = await api.get('/budgets');
  return response.data;
};

export const getBudget = async (id) => {
  const response = await api.get(`/budgets/${id}`);
  return response.data;
};

export const createBudget = async (budgetData) => {
  const response = await api.post('/budgets', budgetData);
  return response.data;
};

export const updateBudget = async (budgetId, budgetData) => {
  const response = await api.put(`/budgets/${budgetId}`, budgetData);
  return response.data;
};

export const deleteBudget = async (budgetId) => {
  const response = await api.delete(`/budgets/${budgetId}`);
  return response.data;
};

export const updateCategorySpending = async (budgetId, categoryId, amount) => {
  const response = await api.post(`/budgets/${budgetId}/category-spending`, {
    categoryId,
    amount
  });
  return response.data;
};

export const getBudgetInsights = async (id, params = {}) => {
  const response = await api.get(`/budgets/${id}/insights`, { params });
  return response.data;
};

export const getSuggestedBudget = async () => {
  const response = await api.get('/budgets/suggested');
  return response.data;
};

export const updateBudgetCategory = async (budgetId, categoryId, categoryData) => {
  const response = await api.put(`/budgets/${budgetId}/categories/${categoryId}`, categoryData);
  return response.data;
}; 
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Container,
  Paper,
  IconButton,
  InputBase,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Stack,
  Divider,
  FormGroup,
  Switch,
  Chip,
  Icon
} from '@mui/material';
import {
  Search as SearchIcon,
  TuneRounded as FilterIcon,
  SwapVertRounded as SortIcon,
  Download as DownloadIcon,
  AccountBalance as AvailableIcon,
  AttachMoney as CashIcon,
  Savings as SavingsIcon,
  Close as CloseIcon,
  ArrowUpward as ArrowUpIcon,
  ArrowDownward as ArrowDownIcon,
  AutoAwesome as SparkleIcon,
  NotificationsNone as AlertIcon,
  CheckCircleOutline as ResolvedIcon,
  RemoveCircleOutline as DismissedIcon,
  VisibilityOff as ReadIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
  Check as SuccessIcon,
  Celebration as CelebrationIcon,
  Stars as StarsIcon,
  Sailing as SailingIcon,
  EmojiEvents as EmojiEventsIcon,
  ThumbUp as ThumbUpIcon,
  Add as AddIcon,
  Check as CheckIcon,
  TrendingUp as TrendingUpIcon,
  CalendarToday as CalendarTodayIcon,
  DateRange as DateRangeIcon
} from '@mui/icons-material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Layout from '../components/Layout';
import TransactionList from '../components/TransactionList';
import CategorizerIssuesCard from '../components/CategorizerIssuesCard';
import { getTransactions, getConnectedAccounts } from '../services/transactionService';
import Logger from '../utils/logger';
import { StyledDialog, StyledTextField, StyledButton, StyledSelect } from '../components/common/StyledInputs';
import api from '../services/api';
import { useError } from '../contexts/ErrorContext';
import SparkleButton from '../components/SparkleButton';
import { useAlerts } from '../contexts/AlertContext';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { completeOnboardingStep } from '../services/onboardingService';
import { toast } from 'react-hot-toast';

// Add immediate logging to verify it's working
Logger.debug('Activity component loaded');

const STORAGE_KEY = 'activityFilters';

const getInitialFilters = () => {
  const savedFilters = localStorage.getItem(STORAGE_KEY);
  if (savedFilters) {
    try {
      const parsedFilters = JSON.parse(savedFilters);
      
      // Always return empty arrays for account selections to ensure
      // we show all accounts by default, regardless of saved filter state
      return {
        ...parsedFilters,
        selectedAccounts: [],
        selectedInstitutions: []
      };
    } catch (e) {
      Logger.error('Failed to parse saved filters', e);
    }
  }
  return {
    dateFilter: 'any',
    amountFilter: 'any',
    minAmount: '',
    maxAmount: '',
    showTransfers: false,
    showIncome: false,
    showUncategorized: false,
    selectedAccounts: [],
    selectedInstitutions: []
  };
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: '20px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  transition: 'transform 80ms cubic-bezier(0, 0.3, 1, 0.5)',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: 'none'
  },
  '&:active': {
    transform: 'scale(0.95)'
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  minWidth: 48,  // Keep minWidth to prevent squishing
  borderRadius: '50%',  // Make it perfectly circular
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  '& svg': {
    color: theme.palette.primary.main,
    fontSize: '24px'
  }
}));

const AlertTypeIcon = ({ type, metadata }) => {
  const theme = useTheme();
  
  // If there's a custom icon in metadata, use that
  if (metadata?.icon) {
    return (
      <Icon 
        sx={{ 
          color: theme.palette.primary.main,
          fontSize: '24px'
        }}
      >
        {metadata.icon}
      </Icon>
    );
  }

  // Otherwise fall back to type-based icons
  switch (type) {
    case 'warning':
      return <WarningIcon sx={{ color: theme.palette.warning.main }} />;
    case 'error':
      return <ErrorIcon sx={{ color: theme.palette.error.main }} />;
    case 'success':
      return <SuccessIcon sx={{ color: theme.palette.success.main }} />;
    default:
      return <InfoIcon sx={{ color: theme.palette.info.main }} />;
  }
};

const AlertSourceChip = ({ source }) => {
  const theme = useTheme();
  const getSourceColor = () => {
    switch (source) {
      case 'webhook':
        return theme.palette.primary.main;
      case 'ai':
        return theme.palette.success.main;
      case 'budget':
        return theme.palette.warning.main;
      default:
        return theme.palette.info.main;
    }
  };

  return (
    <Chip
      label={source}
      size="small"
      sx={{
        backgroundColor: alpha(getSourceColor(), 0.15),
        color: getSourceColor(),
        borderRadius: '6px',
        height: '24px',
        '& .MuiChip-label': {
          px: 1,
        },
      }}
    />
  );
};

// Add the relative time formatter
const getRelativeTime = (date) => {
  const now = new Date();
  const diff = now - new Date(date);
  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}m ago`;
  } else {
    return 'just now';
  }
};

const AlertCard = ({ alert, onDismiss }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showError, showSuccess } = useError();
  const [loading, setLoading] = useState(false);

  const handleAction = async (action) => {
    try {
      if (alert.metadata?.action === 'confirmAccounts') {
        if (action === 'confirm') {
          setLoading(true);
          Logger.info('Starting account confirmation process');

          try {
            // Use the consistent onboarding step completion pattern
            await api.post('/onboarding/complete', { step: 'accounts' });
            
            // Dismiss the alert after operation succeeds
            if (alert._id) {
              onDismiss([alert._id]);
            }

            Logger.info('Account confirmation complete');
            showSuccess('Your accounts have been confirmed and your initial budget is being prepared!');
          } catch (error) {
            Logger.error('Error during account confirmation:', error);
            // Revert the confirmation if it failed
            try {
              await api.post('/onboarding/revert', { step: 'accounts' });
            } catch (revertError) {
              Logger.error('Failed to revert account confirmation:', revertError);
            }
            throw error;
          }
        } else if (action === 'add_more') {
          // Store the current alert ID to prevent immediate reappearance
          const currentAlertId = alert._id;
          
          // Navigate to money page
          navigate('/money');
          
          // Dismiss the alert after navigation
          if (currentAlertId) {
            onDismiss([currentAlertId]);
          }
        }
      } else if (alert.metadata?.action === 'reviewBudget' && action === 'approve') {
        try {
          // Mark the budget step as complete
          await completeOnboardingStep('budget');
          
          // Dismiss the alert
          if (alert._id) {
            onDismiss([alert._id]);
          }
          
          // Show success message
          toast.success('Budget approved successfully!');
        } catch (error) {
          console.error('Error approving budget:', error);
          showError('Failed to approve budget. Please try again.');
        }
      } else if (alert.metadata?.action === 'retryBudgetInit' && action === 'retry') {
        setLoading(true);
        Logger.info('Starting budget initialization retry');

        try {
          await api.post('/onboarding/complete', { 
            step: 'accounts',
            isRetry: true 
          });
          
          // Dismiss the alert after operation succeeds
          if (alert._id) {
            onDismiss([alert._id]);
          }

          Logger.info('Budget initialization retry complete');
          showSuccess('Budget creation started successfully!');
        } catch (error) {
          Logger.error('Error during budget initialization retry:', error);
          showError('Failed to retry budget creation. Please try again.');
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      Logger.error('Error handling alert action:', error);
      showError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledCard sx={{ 
      minWidth: 300, 
      maxWidth: 300, 
      mr: 2, 
      minHeight: 280,
      position: 'relative',
      cursor: alert.metadata?.route ? 'pointer' : 'default',
      '&:hover': alert.metadata?.route ? {
        transform: 'translateY(-4px)',
        transition: 'transform 80ms cubic-bezier(0, 0.3, 1, 0.5)'
      } : {},
      '&:active': alert.metadata?.route ? {
        transform: 'scale(0.95)'
      } : {}
    }} onClick={(e) => {
      // Only navigate if we have a route and the click wasn't on the dismiss button
      if (alert.metadata?.route && !e.defaultPrevented) {
        navigate(alert.metadata.route);
      }
    }}>
      <CardContent sx={{ 
        p: 3, 
        height: '100%',
        pb: '3 !important'
      }}>
        {/* Top section with icon and close button */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconWrapper>
              <AlertTypeIcon type={alert.type} metadata={alert.metadata} />
            </IconWrapper>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {alert.title}
            </Typography>
          </Box>
          {alert.metadata?.dismissible && (
            <IconButton 
              size="small" 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDismiss([alert._id]);
              }}
              sx={{ 
                mt: -1, 
                mr: -1,
                color: 'text.secondary',
                opacity: 0.5,
                '&:hover': {
                  opacity: 1,
                  backgroundColor: 'transparent'
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>

        {/* Content section */}
        <Box sx={{ pr: 0, pb: 4 }}> {/* Add padding bottom to prevent content from overlapping with time */}
          <Typography variant="body2" color="text.secondary">
            {alert.message}
          </Typography>
          
          {/* Action Buttons */}
          {alert.metadata?.actions && (
            <Box sx={{ 
              display: 'flex', 
              gap: 1, 
              mt: 2,
              justifyContent: 'flex-start'
            }}>
              {alert.metadata.actions.map((action, index) => (
                <StyledButton
                  key={index}
                  variant={action.variant || (action.primary ? 'contained' : 'outlined')}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(action.value);
                  }}
                  startIcon={action.value === 'add_more' ? <AddIcon /> : <CheckIcon />}
                  sx={{ px: 2.5, minHeight: '28px' }}
                >
                  {action.label}
                </StyledButton>
              ))}
            </Box>
          )}
        </Box>

        {/* Time section - positioned relative to the card */}
        <Typography 
          variant="caption" 
          color="text.secondary" 
          sx={{ 
            opacity: 0.7,
            position: 'absolute',
            bottom: 24,
            right: 24,
            lineHeight: 1
          }}
        >
          {getRelativeTime(alert.createdAt)}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

const Activity = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [filterCategory, setFilterCategory] = useState('all');
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ITEMS_PER_PAGE = 30;
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState(getInitialFilters().dateFilter);
  const [amountFilter, setAmountFilter] = useState(getInitialFilters().amountFilter);
  const [minAmount, setMinAmount] = useState(getInitialFilters().minAmount);
  const [maxAmount, setMaxAmount] = useState(getInitialFilters().maxAmount);
  const [showTransfers, setShowTransfers] = useState(getInitialFilters().showTransfers);
  const [showIncome] = useState(true);
  const [showUncategorized, setShowUncategorized] = useState(getInitialFilters().showUncategorized);
  const [accounts, setAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [selectedAccounts, setSelectedAccounts] = useState(getInitialFilters().selectedAccounts || []);
  const [selectedInstitutions, setSelectedInstitutions] = useState(getInitialFilters().selectedInstitutions || []);
  const [appliedFilters, setAppliedFilters] = useState(getInitialFilters());
  const [sortConfig, setSortConfig] = useState({
    field: 'date',
    direction: 'desc'
  });
  const transactionListRef = useRef(null);
  const mainContainerRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const { 
    alerts,
    loading: alertsLoading,
    error: alertsError,
    pendingAlerts,
    readAlerts,
    resolvedAlerts,
    dismissedAlerts,
    markAsRead,
    markAsResolved,
    dismiss,
    fetchAlerts
  } = useAlerts();
  const [selectedStatus, setSelectedStatus] = useState('all');
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, authLoading } = useAuth();
  const { showError, showSuccess } = useError();

  // Mock balances - replace with real data from your API
  const balances = {
    availableToSpend: 2500.00,
    cash: 1500.00,
    savings: 1000.00
  };

  const BalanceCard = ({ title, icon, amount, subtext }) => (
    <StyledCard>
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <IconWrapper>
            {icon}
          </IconWrapper>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h4" sx={{ mb: 1, fontWeight: 600 }}>
            ${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </Typography>
          {subtext && (
            <Typography variant="body2" color="text.secondary">
              {subtext}
            </Typography>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );

  const getDateRange = (filterType) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    switch (filterType) {
      case 'today':
        return {
          start: today,
          end: new Date(today.getTime() + 24 * 60 * 60 * 1000)
        };
      case 'week':
        const weekStart = new Date(today);
        weekStart.setDate(today.getDate() - today.getDay()); // Start of current week (Sunday)
        return {
          start: weekStart,
          end: now
        };
      case 'month':
        return {
          start: new Date(today.getFullYear(), today.getMonth(), 1), // Start of current month
          end: now
        };
      case 'year':
        return {
          start: new Date(today.getFullYear(), 0, 1), // Start of current year
          end: now
        };
      case 'any':
      default:
        return null;
    }
  };

  // Add filterTransactions function
  const filterTransactions = useCallback((transactions, searchTerm) => {
    if (!searchTerm) return transactions;
    
    const searchLower = searchTerm.toLowerCase();
    return transactions.filter(transaction => {
      // Search in merchant name
      if (transaction.merchantName?.toLowerCase().includes(searchLower)) return true;
      
      // Search in description
      if (transaction.description?.toLowerCase().includes(searchLower)) return true;
      
      // Search in amount (convert to string for partial matches)
      const amount = Math.abs(transaction.amount).toFixed(2);
      if (amount.includes(searchTerm)) return true;
      
      // Search in category name
      if (transaction.budgetCategory?.name?.toLowerCase().includes(searchLower)) return true;
      
      return false;
    });
  }, []);

  // Replace the search term effect with local filtering
  useEffect(() => {
    if (!searchTerm) {
      // If search is cleared, show all transactions
      setFilteredTransactions(transactions);
      return;
    }
    
    // Apply local filtering
    const filtered = filterTransactions(transactions, searchTerm);
    setFilteredTransactions(filtered);
  }, [searchTerm, transactions, filterTransactions]);

  // Update the fetchTransactions function to remove search parameter
  const fetchTransactions = async (pageNum = 1, append = false) => {
    try {
      setLoading(true);
      Logger.debug('Fetching transactions with filters:', appliedFilters);

      const dateRange = appliedFilters.dateFilter !== 'any' ? getDateRange(appliedFilters.dateFilter) : null;

      // Construct request params (remove search)
      const requestParams = {
        page: pageNum,
        limit: ITEMS_PER_PAGE,
        sortBy: sortConfig.field,
        sortDirection: sortConfig.direction,
        showTransfers: appliedFilters.showTransfers,
        showIncome: true,
        showUncategorized: appliedFilters.showUncategorized,
        startDate: dateRange?.start?.toISOString(),
        endDate: dateRange?.end?.toISOString(),
        minAmount: appliedFilters.minAmount ? parseFloat(appliedFilters.minAmount) : undefined,
        maxAmount: appliedFilters.maxAmount ? parseFloat(appliedFilters.maxAmount) : undefined,
        category: filterCategory !== 'all' ? filterCategory : undefined,
        accountIds: appliedFilters.selectedAccounts.length > 0 ? appliedFilters.selectedAccounts : undefined,
        institutionIds: appliedFilters.selectedInstitutions.length > 0 ? appliedFilters.selectedInstitutions : undefined
      };

      Logger.debug('Request params:', requestParams);

      const response = await getTransactions(requestParams);
      
      // Handle response
      const newTransactions = response.transactions || response || [];
      const totalPages = response.pages || response.totalPages || 1;
      
      Logger.debug('Received transactions:', {
        count: newTransactions.length,
        totalPages,
        hasMore: pageNum < totalPages
      });

      setTotalPages(totalPages);
      setHasMore(pageNum < totalPages);
      
      if (append) {
        const updatedTransactions = [...transactions, ...newTransactions];
        setTransactions(updatedTransactions);
        // Apply current search filter to new transactions
        setFilteredTransactions(filterTransactions(updatedTransactions, searchTerm));
      } else {
        setTransactions(newTransactions);
        // Apply current search filter to new transactions
        setFilteredTransactions(filterTransactions(newTransactions, searchTerm));
      }
    } catch (error) {
      Logger.error('Failed to fetch transactions', error);
      setError('Failed to fetch transactions');
    } finally {
      setLoading(false);
    }
  };

  // Effect to refetch when filters change
  useEffect(() => {
    // Reset pagination when filters change
    setPage(1);
    setHasMore(true);
    setTotalPages(1);
    
    // Fetch with new filters
    fetchTransactions(1, false);
  }, [appliedFilters]);

  // Effect to refetch when search term changes
  useEffect(() => {
    // Reset pagination when search term changes
    setPage(1);
    setHasMore(true);
    setTotalPages(1);
    
    // Fetch with new search term
    fetchTransactions(1, false);
  }, [searchTerm]);

  const handlePageScroll = (event) => {
    const container = event.target;
    const { scrollTop, clientHeight, scrollHeight } = container;
    
    // When user scrolls near bottom of the page
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      // Load more transactions if available
      if (!loading && hasMore) {
        Logger.debug('Loading more transactions', { 
          nextPage: page + 1,
          currentTransactions: transactions.length,
          scrollPosition: { scrollTop, clientHeight, scrollHeight }
        });
        setPage(prevPage => prevPage + 1);
        fetchTransactions(page + 1, true);
      }
    }
  };

  const handleTransactionListScroll = (event) => {
    if (isAutoScrolling) return;

    const list = event.target;
    const { scrollTop, clientHeight, scrollHeight } = list;

    // When scrolling near top of transaction list
    if (scrollTop <= clientHeight * 0.2) {
      // Find the main container and scroll it to top
      const mainContainer = document.querySelector('.activity-main-container');
      if (mainContainer) {
        setIsAutoScrolling(true);
        mainContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        setTimeout(() => setIsAutoScrolling(false), 1000);
      }
    }

    // When scrolling near bottom of transaction list
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      // Load more transactions if available
      if (!loading && hasMore) {
        setPage(prevPage => prevPage + 1);
        fetchTransactions(page + 1, true);
      }
    }
  };

  const handleFilterApply = () => {
    Logger.debug('Applying filters:', {
      dateFilter,
      amountFilter,
      minAmount,
      maxAmount,
      selectedAccounts,
      selectedInstitutions,
      showTransfers,
      showIncome,
      showUncategorized
    });

    // Reset the page and transactions before applying new filters
    setPage(1);
    setTransactions([]);
    
    const newFilters = {
      dateFilter,
      amountFilter,
      minAmount,
      maxAmount,
      showTransfers,
      showIncome,
      showUncategorized,
      selectedAccounts,
      selectedInstitutions
    };

    Logger.debug('New filters:', newFilters);
    setAppliedFilters(newFilters);
    setFilterDialogOpen(false);

    // Save to localStorage immediately
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(newFilters));
      Logger.debug('Saved filters to localStorage', newFilters);
    } catch (e) {
      Logger.error('Failed to save filters to localStorage', e);
    }
  };

  const handleFilterReset = () => {
    const defaultFilters = getInitialFilters();
    setDateFilter(defaultFilters.dateFilter);
    setAmountFilter(defaultFilters.amountFilter);
    setMinAmount(defaultFilters.minAmount);
    setMaxAmount(defaultFilters.maxAmount);
    setShowTransfers(defaultFilters.showTransfers);
    setShowUncategorized(defaultFilters.showUncategorized);
    setSelectedAccounts(defaultFilters.selectedAccounts || []);
    setSelectedInstitutions(defaultFilters.selectedInstitutions || []);
  };

  const handleColumnSort = (field) => {
    setSortConfig(prevConfig => ({
      field,
      direction: 
        prevConfig.field === field && prevConfig.direction === 'desc' 
          ? 'asc' 
          : 'desc'
    }));
  };

  const SortableColumnHeader = ({ field, label, width }) => (
    <Box 
      onClick={() => handleColumnSort(field)}
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: field === 'description' ? 'flex-start' : 'flex-end',
        gap: 0.5,
        cursor: 'pointer',
        userSelect: 'none',
        width: width || 'auto',
        '&:hover': {
          color: theme.palette.primary.main
        }
      }}
    >
      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
        {label}
      </Typography>
      {sortConfig.field === field && (
        sortConfig.direction === 'desc' ? <ArrowDownIcon sx={{ fontSize: 16 }} /> : <ArrowUpIcon sx={{ fontSize: 16 }} />
      )}
    </Box>
  );

  const uniqueCategories = [...new Set(filteredTransactions.map(t => t.category).filter(Boolean))];

  const handleDownload = () => {
    Logger.info('Starting transaction download', {
      filters: appliedFilters,
      sortConfig,
      transactionCount: transactions.length
    });
    // TODO: Implement CSV download
  };

  const groupTransactionsByMonth = (transactions) => {
    const groups = {};
    transactions.forEach(transaction => {
      const date = new Date(transaction.date);
      const key = `${date.getFullYear()}-${date.getMonth()}`;
      if (!groups[key]) {
        groups[key] = {
          monthYear: new Date(date.getFullYear(), date.getMonth(), 1),
          transactions: []
        };
      }
      groups[key].transactions.push(transaction);
    });
    return Object.values(groups).sort((a, b) => b.monthYear - a.monthYear);
  };

  // Update the groupedTransactions to use filteredTransactions instead of transactions
  const groupedTransactions = groupTransactionsByMonth(filteredTransactions);

  // Calculate total spent for a given date range
  const getTotalSpentForRange = (transactions, startDate, endDate) => {
    return transactions
      .filter(t => {
        const txDate = new Date(t.date);
        return txDate >= startDate && txDate <= endDate && t.amount > 0;
      })
      .reduce((total, t) => total + t.amount, 0);
  };

  // Get spending for today
  const getTotalSpentToday = (transactions) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    
    return getTotalSpentForRange(transactions, today, tomorrow);
  };

  // Get spending for this week
  const getTotalSpentThisWeek = (transactions) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay()); // Start of week (Sunday)
    
    return getTotalSpentForRange(transactions, startOfWeek, now);
  };

  // Add to the existing getTotalSpentThisMonth function
  const getTotalSpentThisMonth = (transactions) => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    
    return getTotalSpentForRange(transactions, firstDayOfMonth, now);
  };

  const handleTransactionUpdate = (updatedTransaction) => {
    Logger.debug('Handling transaction update', {
      transactionId: updatedTransaction._id,
      isBulkUpdate: updatedTransaction.bulkUpdate,
      updatedCount: updatedTransaction.updatedCount,
      similarCriteria: updatedTransaction.similarCriteria
    });
    
    if (updatedTransaction.bulkUpdate && updatedTransaction.similarCriteria) {
      // For bulk updates, update all similar transactions in-place
      setTransactions(prevTransactions => 
        prevTransactions.map(transaction => {
          // Check if this transaction matches ALL the criteria for similar transactions
          const isMatch = (
            // If merchantName is provided, it must match exactly
            (!updatedTransaction.similarCriteria.merchantName || 
             transaction.merchantName === updatedTransaction.similarCriteria.merchantName) &&
            // If no merchantName but description is provided, it must match exactly
            (!updatedTransaction.similarCriteria.merchantName && 
             updatedTransaction.similarCriteria.description && 
             transaction.description === updatedTransaction.similarCriteria.description) &&
            // If merchantEntityId is provided, it must match exactly
            (!updatedTransaction.similarCriteria.merchantEntityId || 
             transaction.merchantEntityId === updatedTransaction.similarCriteria.merchantEntityId)
          );

          if (isMatch) {
            Logger.debug('Updating similar transaction', {
              transactionId: transaction._id,
              merchantName: transaction.merchantName,
              description: transaction.description,
              merchantEntityId: transaction.merchantEntityId
            });
            // Apply the same category info to matching transactions
            return {
              ...transaction,
              budget: updatedTransaction.budget,
              budgetCategory: updatedTransaction.budgetCategory
            };
          }
          return transaction;
        })
      );
    } else {
      // For single transaction updates, update just that transaction
      setTransactions(prevTransactions => 
        prevTransactions.map(transaction => 
          transaction._id === updatedTransaction._id ? updatedTransaction : transaction
        )
      );
    }
  };

  // Update the accounts fetching effect
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        setAccountsLoading(true);
        const response = await getConnectedAccounts();
        
        // Flatten the nested accounts structure
        const flattenedAccounts = response.reduce((acc, pa) => {
          return acc.concat(pa.accounts.map(account => ({
            id: account.id,
            name: account.displayName || account.name,
            mask: account.mask,
            institutionName: pa.institution?.name || 'Other',
            institutionId: pa.institution?.id || 'other',
            type: account.type,
            subtype: account.subtype,
            balance: account.balance
          })));
        }, []);
        
        setAccounts(flattenedAccounts);
        
        // Always select all accounts by default, regardless of saved filters
        const newSelectedAccounts = flattenedAccounts.map(acc => acc.id);
        const newSelectedInstitutions = [...new Set(flattenedAccounts.map(acc => acc.institutionId))];
        setSelectedAccounts(newSelectedAccounts);
        setSelectedInstitutions(newSelectedInstitutions);
        
        // Update appliedFilters with all accounts selected
        setAppliedFilters(prev => {
          const updatedFilters = {
            ...prev,
            selectedAccounts: newSelectedAccounts,
            selectedInstitutions: newSelectedInstitutions
          };
          
          // Schedule a data fetch with the updated filters after this state update completes
          setTimeout(() => fetchTransactions(1, false), 0);
          
          return updatedFilters;
        });
      } catch (error) {
        Logger.error('Failed to fetch accounts', error);
        setAccounts([]);
      } finally {
        setAccountsLoading(false);
      }
    };
    
    fetchAccounts();
  }, []);

  const handleInstitutionToggle = (institutionAccounts, institutionId, checked) => {
    Logger.debug('Institution toggle:', {
      institutionId,
      checked,
      accountCount: institutionAccounts.length,
      accountIds: institutionAccounts.map(acc => acc.id)
    });

    // Get all account IDs for this institution
    const institutionAccountIds = institutionAccounts.map(acc => acc.id);

    let newSelectedAccounts;
    let newSelectedInstitutions;

    if (checked) {
      // Add all accounts for this institution
      newSelectedAccounts = [...new Set([...selectedAccounts, ...institutionAccountIds])];
      newSelectedInstitutions = [...new Set([...selectedInstitutions, institutionId])];
    } else {
      // Remove all accounts for this institution and the institution itself
      newSelectedAccounts = selectedAccounts.filter(id => !institutionAccountIds.includes(id));
      newSelectedInstitutions = selectedInstitutions.filter(id => id !== institutionId);
    }

    setSelectedAccounts(newSelectedAccounts);
    setSelectedInstitutions(newSelectedInstitutions);

    // Update appliedFilters immediately
    setAppliedFilters(prev => {
      const updatedFilters = {
        ...prev,
        selectedAccounts: newSelectedAccounts,
        selectedInstitutions: newSelectedInstitutions
      };
      
      // Schedule a data fetch with the updated filters after this state update completes
      setTimeout(() => fetchTransactions(1, false), 0);
      
      return updatedFilters;
    });
  };

  const handleAccountToggle = (account, institutionAccounts, checked) => {
    const institutionId = account.institutionId;
    const institutionAccountIds = institutionAccounts.map(acc => acc.id);

    // Update local state
    const newSelectedAccounts = checked ?
      [...selectedAccounts, account.id] :
      selectedAccounts.filter(id => id !== account.id);

    // Check if all accounts for this institution are selected
    const allInstitutionAccountsSelected = institutionAccountIds.every(
      id => checked ? 
        newSelectedAccounts.includes(id) : 
        (id !== account.id && selectedAccounts.includes(id))
    );

    const newSelectedInstitutions = allInstitutionAccountsSelected ?
      [...new Set([...selectedInstitutions, institutionId])] :
      selectedInstitutions.filter(id => id !== institutionId);

    setSelectedAccounts(newSelectedAccounts);
    setSelectedInstitutions(newSelectedInstitutions);

    // Update appliedFilters immediately
    setAppliedFilters(prev => {
      const updatedFilters = {
        ...prev,
        selectedAccounts: newSelectedAccounts,
        selectedInstitutions: newSelectedInstitutions
      };
      
      // Schedule a data fetch with the updated filters after this state update completes
      setTimeout(() => fetchTransactions(1, false), 0);
      
      return updatedFilters;
    });
  };

  const AccountsSection = () => {
    if (accountsLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <CircularProgress size={24} color="primary" />
        </Box>
      );
    }

    if (!accounts || accounts.length === 0) {
      return (
        <Box 
          sx={{ 
            py: 2,
            px: 3,
            bgcolor: 'action.hover',
            borderRadius: 2,
            textAlign: 'center'
          }}
        >
          <Typography variant="body2" color="text.secondary">
            No accounts available
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
            Connect a bank account to see transactions
          </Typography>
        </Box>
      );
    }

    const groupedAccounts = accounts.reduce((groups, account) => {
      const institution = account.institutionName || 'Other';
      if (!groups[institution]) {
        groups[institution] = [];
      }
      groups[institution].push(account);
      return groups;
    }, {});

    return (
      <Stack spacing={2}>
        {Object.entries(groupedAccounts).map(([institution, institutionAccounts]) => (
          <Box 
            key={institution}
            sx={{
              bgcolor: 'action.hover',
              borderRadius: 2,
              p: 2
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mb: 1
            }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: 'text.primary',
                  fontSize: '0.95rem'
                }}
              >
                {institution}
              </Typography>
              <Switch
                size="small"
                checked={institutionAccounts.every(acc => 
                  selectedAccounts.includes(acc.id)
                )}
                onChange={(e) => handleInstitutionToggle(
                  institutionAccounts,
                  institutionAccounts[0].institutionId,
                  e.target.checked
                )}
              />
            </Box>
            <Stack spacing={1}>
              {institutionAccounts.map(account => (
                <Box
                  key={account.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pl: 2  // Add left padding to indent account names
                  }}
                >
                  <Box>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: 'text.secondary',
                        fontSize: '0.875rem'
                      }}
                    >
                      {account.name}
                    </Typography>
                    {account.mask && (
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ opacity: 0.8 }}
                      >
                        ••••{account.mask}
                      </Typography>
                    )}
                  </Box>
                  <Switch
                    size="small"
                    checked={selectedAccounts.includes(account.id)}
                    onChange={(e) => handleAccountToggle(
                      account,
                      institutionAccounts,
                      e.target.checked
                    )}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>
    );
  };

  // Add an effect to save filters when they change
  useEffect(() => {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(appliedFilters));
      Logger.debug('Saved filters to localStorage', appliedFilters);
    } catch (e) {
      Logger.error('Failed to save filters to localStorage', e);
    }
  }, [appliedFilters]);

  // Add an effect to sync dialog state with applied filters when dialog opens
  useEffect(() => {
    if (filterDialogOpen) {
      setDateFilter(appliedFilters.dateFilter);
      setAmountFilter(appliedFilters.amountFilter);
      setMinAmount(appliedFilters.minAmount);
      setMaxAmount(appliedFilters.maxAmount);
      setShowTransfers(appliedFilters.showTransfers);
      setShowUncategorized(appliedFilters.showUncategorized);
      setSelectedAccounts(appliedFilters.selectedAccounts || []);
      setSelectedInstitutions(appliedFilters.selectedInstitutions || []);
    }
  }, [filterDialogOpen, appliedFilters]);

  // Add logging for alerts state
  useEffect(() => {
    Logger.debug('Alerts state in Activity:', {
      totalAlerts: alerts?.length,
      pending: pendingAlerts?.length,
      read: readAlerts?.length,
      resolved: resolvedAlerts?.length,
      dismissed: dismissedAlerts?.length,
      loading: alertsLoading,
      error: alertsError
    });
  }, [alerts, pendingAlerts, readAlerts, resolvedAlerts, dismissedAlerts, alertsLoading, alertsError]);

  // Update useEffect for fetching alerts
  useEffect(() => {
    if (!authLoading && isAuthenticated) {
      Logger.debug('Fetching alerts with params:', {
        authState: { isAuthenticated, authLoading }
      });

      // Fetch all relevant alerts (not just pending)
      fetchAlerts({
        page: 1,
        limit: ITEMS_PER_PAGE,
        status: ['pending', 'read'] // Include both pending and read alerts
      }).then(() => {
        Logger.debug('Alerts fetch completed');
      }).catch(error => {
        Logger.error('Error fetching alerts:', error);
      });
    }
  }, [fetchAlerts, isAuthenticated, authLoading]);

  const handleRecategorizeAll = async () => {
    try {
      Logger.info('Starting bulk recategorization of uncategorized transactions');
      setLoading(true);

      // Get uncategorized transactions from backend only
      const uncategorizedResponse = await api.get('/transactions/uncategorized');
      Logger.debug('Raw uncategorized response:', {
        data: uncategorizedResponse.data,
        count: uncategorizedResponse.data.length
      });

      const uncategorizedCount = uncategorizedResponse.data.length;
      
      if (uncategorizedCount === 0) {
        Logger.info('No uncategorized transactions found');
        showSuccess('All transactions are already categorized!');
        return;
      }

      Logger.info(`Found ${uncategorizedCount} uncategorized transactions`);

      // Call the categorizer
      const categorizerResponse = await api.post('/ai/categorize-transactions', {
        transactions: uncategorizedResponse.data
      });
      Logger.debug('Categorizer response:', categorizerResponse.data);

      // Apply the categories
      await api.post('/transactions/apply-categories', {
        transactions: categorizerResponse.data.data.categorizedTransactions,
        preserveUserCategories: true
      });

      Logger.info('Initial categorization complete', {
        processed: categorizerResponse.data.stats?.categorized || 0,
        issues: categorizerResponse.data.stats?.issues?.length || 0
      });

      // Handle any issues
      if (categorizerResponse.data.stats?.issues?.length > 0) {
        const issues = {
          timestamp: new Date().toISOString(),
          count: categorizerResponse.data.stats.issues.length,
          details: categorizerResponse.data.stats.issues
        };

        // Store in localStorage and emit event
        const storedIssues = JSON.parse(localStorage.getItem('categorizerIssues') || '[]');
        storedIssues.unshift(issues);
        localStorage.setItem('categorizerIssues', JSON.stringify(storedIssues.slice(0, 10)));
        window.dispatchEvent(new CustomEvent('categorizerIssues', { detail: issues }));
      }

      // Refresh the transaction list
      await fetchTransactions(1, false);
      showSuccess(`Successfully categorized ${categorizerResponse.data.stats?.categorized || 0} transactions`);
    } catch (error) {
      Logger.error('Error during bulk recategorization:', error);
      showError('Failed to recategorize transactions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Update the empty state messages to use the specific icons
  const emptyStateMessages = [
    { message: "Way to go! You're on top of everything!", icon: <CelebrationIcon /> },
    { message: "All clear! You're crushing it!", icon: <StarsIcon /> },
    { message: "Smooth sailing ahead! No alerts to worry about.", icon: <SailingIcon /> },
    { message: "You're all caught up! Time for a victory dance.", icon: <EmojiEventsIcon /> },
    { message: "Looking good! Everything's under control.", icon: <ThumbUpIcon /> }
  ];

  // Get random message for empty state
  const getRandomEmptyMessage = () => {
    const index = Math.floor(Math.random() * emptyStateMessages.length);
    return emptyStateMessages[index];
  };

  // Spending Metrics Section Component
  const SpendingMetrics = ({ transactions }) => {
    const spentToday = getTotalSpentToday(transactions);
    const spentThisWeek = getTotalSpentThisWeek(transactions);
    const spentThisMonth = getTotalSpentThisMonth(transactions);
    
    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
          Spending Overview
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <StyledCard>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <IconWrapper>
                    <CalendarTodayIcon />
                  </IconWrapper>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Spent Today
                  </Typography>
                  <Typography variant="h4" sx={{ mb: 1, fontWeight: 600 }}>
                    ${spentToday.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledCard>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <IconWrapper>
                    <DateRangeIcon />
                  </IconWrapper>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Spent This Week
                  </Typography>
                  <Typography variant="h4" sx={{ mb: 1, fontWeight: 600 }}>
                    ${spentThisWeek.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledCard>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <IconWrapper>
                    <TrendingUpIcon />
                  </IconWrapper>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Spent This Month
                  </Typography>
                  <Typography variant="h4" sx={{ mb: 1, fontWeight: 600 }}>
                    ${spentThisMonth.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Typography>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ py: 3 }} ref={mainContainerRef}>
        {/* Alerts Section */}
        <Box sx={{ 
          mb: 4,
          position: 'relative',
          left: 0,
          width: '100%',
          maxWidth: '100%',
          overflow: 'hidden'
        }}>
          <Box sx={{ 
            display: 'flex',
            overflowX: 'auto',
            width: '100%',
            pt: 1,
            '&::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none'
          }}>
            {alertsLoading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                <CircularProgress size={20} />
              </Box>
            ) : alerts.length > 0 ? (
              alerts.map(alert => (
                <AlertCard
                  key={alert._id}
                  alert={alert}
                  onDismiss={dismiss}
                />
              ))
            ) : (
              <Box 
                sx={{ 
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  py: 6
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  <IconWrapper 
                    sx={{ 
                      mx: 'auto',
                      mb: 3,
                      background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.error.light, 0.1)} 100%)`,
                      '& svg': {
                        fontSize: '28px',
                        color: theme.palette.error.light
                      }
                    }}
                  >
                    {getRandomEmptyMessage().icon}
                  </IconWrapper>
                  <Typography 
                    variant="subtitle1" 
                    sx={{
                      color: theme.palette.error.light,
                      fontWeight: 500
                    }}
                  >
                    {getRandomEmptyMessage().message}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {/* Spending Metrics Section */}
        <SpendingMetrics transactions={transactions} />

        {/* Transaction List Section */}
        {!error && (
          <Box 
            ref={mainContainerRef}
            sx={{ 
              bgcolor: 'background.paper',
              borderRadius: '20px',
              mb: 1,
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 80px)',
              overflow: 'auto',
              scrollBehavior: 'smooth',
              maxWidth: '100%',
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }}
            onScroll={handlePageScroll}
          >
            {/* Fixed Header - All filtering and search available in free tier */}
            <Box sx={{ 
              p: 3, 
              pb: 2,
              bgcolor: 'background.paper',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              position: 'sticky',
              top: 0,
              zIndex: 3
            }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <Paper
                    elevation={0}
                    sx={{
                      p: '6px 16px',
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: 'action.hover',
                      borderRadius: '9999px',
                      boxShadow: 'none',
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          '& > input': {
                            caretColor: 'primary.main'
                          }
                        }
                      },
                      '&:focus-within': {
                        outline: '2px solid',
                        outlineColor: 'primary.main',
                        bgcolor: 'background.paper'
                      }
                    }}
                  >
                    <IconButton sx={{ p: '10px' }}>
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ 
                        flex: 1,
                        '& .MuiInputBase-input': {
                          '&::placeholder': {
                            color: 'text.secondary',
                            opacity: 1
                          }
                        }
                      }}
                      placeholder="Search by name, description, or amount"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Paper>
                </Grid>

                <Grid item>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <SparkleButton
                      onClick={handleRecategorizeAll}
                      disabled={loading}
                      loading={loading}
                      color={theme.palette.primary.main}
                    />
                    <Button
                      startIcon={<FilterIcon />}
                      variant="text"
                      size="small"
                      onClick={() => {
                        Logger.debug('Filter button clicked');
                        setFilterDialogOpen(true);
                      }}
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Filter
                    </Button>
                    <Button
                      startIcon={<DownloadIcon />}
                      variant="text"
                      size="small"
                      onClick={handleDownload}
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Download
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {/* Column Headers */}
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: '1fr auto auto',
                gap: 2,
                mt: 2
              }}>
                <SortableColumnHeader field="description" label="Description" />
                <SortableColumnHeader field="date" label="Date" width={100} />
                <SortableColumnHeader field="amount" label="Amount" width={100} />
              </Box>
            </Box>

            {/* Transaction List */}
            <Box sx={{ flex: 1 }}>
              {transactions.length > 0 ? (
                groupedTransactions.map(({ monthYear, transactions: monthTransactions }) => (
                  <Box key={monthYear.getTime()}>
                    {/* Month Header */}
                    <Box sx={{ 
                      px: 3,
                      py: 1.5,
                      bgcolor: 'background.paper',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'sticky',
                      top: 'calc(72px + 48px)',
                      zIndex: 1
                    }}>
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          color: 'text.secondary',
                          fontWeight: 500,
                          fontSize: '0.875rem',
                          letterSpacing: '0.01em'
                        }}
                      >
                        {monthYear.toLocaleDateString('en-US', { 
                          month: 'long',
                          year: 'numeric'
                        })}
                      </Typography>
                    </Box>
                    <TransactionList 
                      transactions={monthTransactions} 
                      onTransactionUpdate={handleTransactionUpdate}
                    />
                  </Box>
                ))
              ) : !loading ? (
                <Typography variant="body1" color="text.secondary" align="center" sx={{ p: 3 }}>
                  No transactions found
                </Typography>
              ) : null}

              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Container>

      {/* Filter Dialog - All filtering available in free tier */}
      <StyledDialog 
        open={filterDialogOpen} 
        onClose={() => setFilterDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            p: 3
          }
        }}
      >
        <DialogTitle sx={{ p: 0, mb: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="div">
              Filter Transactions
            </Typography>
            <IconButton
              onClick={() => setFilterDialogOpen(false)}
              size="small"
              sx={{
                color: 'text.secondary',
                '&:hover': { color: 'text.primary' }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ 
          p: 0,
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          msOverflowStyle: 'none',  // IE and Edge
          scrollbarWidth: 'none'     // Firefox
        }}>
          <Stack spacing={3}>
            {/* Amount Filter */}
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: 'text.primary',
                  fontWeight: 600,
                  mb: 2
                }}
              >
                Amount Range
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledTextField
                    fullWidth
                    label="Min Amount"
                    value={minAmount}
                    onChange={(e) => setMinAmount(e.target.value)}
                    type="number"
                    placeholder="Enter min amount"
                    InputProps={{
                      startAdornment: (
                        <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                          $
                        </Typography>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextField
                    fullWidth
                    label="Max Amount"
                    value={maxAmount}
                    onChange={(e) => setMaxAmount(e.target.value)}
                    type="number"
                    placeholder="Enter max amount"
                    InputProps={{
                      startAdornment: (
                        <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                          $
                        </Typography>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Divider />

            {/* Date Filter */}
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: 'text.primary',
                  fontWeight: 600,
                  mb: 2
                }}
              >
                Date Range
              </Typography>
              <FormControl fullWidth>
                <StyledSelect
                  value={dateFilter}
                  onChange={(e) => setDateFilter(e.target.value)}
                >
                  <MenuItem value="any">All Time</MenuItem>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">This Week</MenuItem>
                  <MenuItem value="month">This Month</MenuItem>
                  <MenuItem value="year">This Year</MenuItem>
                </StyledSelect>
              </FormControl>
            </Box>

            <Divider />

            {/* Transaction Types Section */}
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: 'text.primary',
                  fontWeight: 600,
                  mb: 2
                }}
              >
                Transaction Types
              </Typography>
              <Stack spacing={1}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: 'action.hover',
                    borderRadius: 2,
                    p: 2
                  }}
                >
                  <Typography variant="body2">
                    Show Transfers
                  </Typography>
                  <Switch
                    size="small"
                    checked={showTransfers}
                    onChange={(e) => setShowTransfers(e.target.checked)}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                          color: theme.palette.primary.main,
                          '&:hover': {
                            bgcolor: alpha(theme.palette.primary.main, 0.08)
                          },
                          '& + .MuiSwitch-track': {
                            bgcolor: `${theme.palette.primary.main}40`
                          }
                        }
                      },
                      '& .MuiSwitch-track': {
                        bgcolor: 'action.selected'
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: 'action.hover',
                    borderRadius: 2,
                    p: 2
                  }}
                >
                  <Typography variant="body2">
                    Show Only Uncategorized
                  </Typography>
                  <Switch
                    size="small"
                    checked={showUncategorized}
                    onChange={(e) => setShowUncategorized(e.target.checked)}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                          color: theme.palette.primary.main,
                          '&:hover': {
                            bgcolor: alpha(theme.palette.primary.main, 0.08)
                          },
                          '& + .MuiSwitch-track': {
                            bgcolor: `${theme.palette.primary.main}40`
                          }
                        }
                      },
                      '& .MuiSwitch-track': {
                        bgcolor: 'action.selected'
                      }
                    }}
                  />
                </Box>
              </Stack>
            </Box>

            <Divider />

            {/* Accounts & Institutions Section */}
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: 'text.primary',
                  fontWeight: 600,
                  mb: 2
                }}
              >
                Accounts & Institutions
              </Typography>
              <AccountsSection />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 0, mt: 4 }}>
          <StyledButton variant="outlined" onClick={() => setFilterDialogOpen(false)}>
            Cancel
          </StyledButton>
          <StyledButton variant="contained" onClick={handleFilterApply}>
            Apply Filters
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </Layout>
  );
};

export default Activity;

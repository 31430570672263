import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './contexts/AuthContext';
import { ErrorProvider } from './contexts/ErrorContext';
import { AlertProvider } from './contexts/AlertContext';
import { PrivateRoute } from './components/PrivateRoute';
import { theme } from './styles';

// Pages
import Welcome from './pages/Welcome';
import Account from './pages/Account';
import Budgets from './pages/Budgets';
import Activity from './pages/Activity';
import Money from './pages/Money';
import Register from './pages/Register';
import SubscriptionCancel from './pages/SubscriptionCancel';
import Support from './pages/Support';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorProvider>
        <Router>
          <AuthProvider>
            <AlertProvider>
              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={<Navigate to="/activity" replace />} />
                <Route path="/activity" element={
                  <PrivateRoute>
                    <Activity />
                  </PrivateRoute>
                } />
                <Route path="/account" element={
                  <PrivateRoute>
                    <Account />
                  </PrivateRoute>
                } />
                <Route path="/budgets" element={
                  <PrivateRoute>
                    <Budgets />
                  </PrivateRoute>
                } />
                <Route path="/money" element={
                  <PrivateRoute>
                    <Money />
                  </PrivateRoute>
                } />
                <Route path="/subscription/cancel" element={
                  <PrivateRoute>
                    <SubscriptionCancel />
                  </PrivateRoute>
                } />
                <Route path="/support" element={
                  <PrivateRoute>
                    <Support />
                  </PrivateRoute>
                } />
              </Routes>
            </AlertProvider>
          </AuthProvider>
        </Router>
      </ErrorProvider>
    </ThemeProvider>
  );
};

export default App;

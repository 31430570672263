import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Button,
} from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import { colors } from '../styles';
import Layout from '../components/Layout';

const SubscriptionCancel = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Container maxWidth="sm">
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <WarningIcon
            sx={{
              fontSize: 64,
              color: colors.warning.main,
              mb: 2
            }}
          />
          <Typography variant="h5" gutterBottom fontWeight="bold">
            Subscription Not Completed
          </Typography>
          <Typography color="text.secondary" paragraph>
            Your subscription upgrade was not completed. No charges have been made to your account.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/dashboard')}
            sx={{ mt: 2 }}
          >
            Return to Dashboard
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
};

export default SubscriptionCancel; 
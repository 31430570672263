import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  InputAdornment,
  Chip,
  Menu,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format as formatDate } from 'date-fns';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Home as HomeIcon,
  DirectionsCar as CarIcon,
  Restaurant as FoodIcon,
  LocalHospital as HealthIcon,
  ShoppingCart as ShoppingCartIcon,
  School as EducationIcon,
  Theaters as EntertainmentIcon,
  Power as UtilitiesIcon,
  Savings as SavingsIcon,
  Category as OtherIcon,
  LocalLaundryService as LaundryIcon,
  Pets as PetsIcon,
  Phone as PhoneIcon,
  CreditCard as CreditCardIcon,
  ChildCare as ChildCareIcon,
  FitnessCenter as GymIcon,
  LocalParking as ParkingIcon,
  Commute as CommuteIcon,
  LocalGroceryStore as GroceryIcon,
  LocalCafe as CoffeeIcon,
  Wifi as InternetIcon,
  Celebration as GiftsIcon,
  BeachAccess as VacationIcon,
  LocalLibrary as BooksIcon,
  Brush as BeautyIcon,
  Construction as MaintenanceIcon,
  Medication as MedicationIcon,
  AccountBalance as InvestmentIcon,
  Policy as InsuranceIcon,
  SportsEsports as GamingIcon,
  VolunteerActivism as CharityIcon,
  SwapHoriz as SwapIcon,
  KeyboardArrowDown as ArrowDownIcon,
  Close as CloseIcon,
  LocalAtm as CashIcon,
  AccountBalanceWallet as WalletIcon,
  Receipt as BillsIcon,
  LocalTaxi as TaxiIcon,
  Train as TransitIcon,
  Flight as TravelIcon,
  LocalMall as ShoppingMallIcon,
  Store as RetailIcon,
  Redeem as GiftCardIcon,
  Apartment as RentIcon,
  Business as OfficeIcon,
  LocalHotel as HotelIcon,
  LocalBar as NightlifeIcon,
  SportsBasketball as SportsIcon,
  Spa as WellnessIcon,
  Devices as ElectronicsIcon,
  Palette as HobbyIcon,
  Cake as BirthdayIcon,
  Deck as OutdoorIcon,
  Storefront as BusinessIcon,
  LocalFlorist as GardenIcon,
  Umbrella as EmergencyIcon,
  Lightbulb as ElectricityIcon,
  WaterDrop as WaterIcon,
  LocalShipping as ShippingIcon,
  Newspaper as SubscriptionsIcon,
  Loyalty as RewardsIcon,
  LocalLaundryService as DryCleaningIcon,
  Healing as TherapyIcon,
  Tune as MaintenanceRepairIcon,
  Security as SecurityIcon,
  Dns as HostingIcon,
  Language as InternationalIcon,
  Forum as ConsultingIcon,
  School as TuitionIcon,
  LocalPrintshop as PrintingIcon,
  PhotoCamera as PhotographyIcon,
  MusicNote as MusicIcon,
  Mic as AudioIcon,
  Code as SoftwareIcon,
  Storage as StorageIcon,
  LocalParking as ParkingFeeIcon,
  Toll as TollsIcon,
  LocalGasStation as GasIcon,
  Build as ToolsIcon,
  Nature as SustainabilityIcon,
  MonetizationOn as InvestmentsIcon,
  AccountBalanceWallet as CryptoIcon,
  TrendingUp as StocksIcon,
  AttachMoney as LoansIcon,
  Payment as DebtIcon,
  AccountBalance as BankFeesIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { colors } from '../styles';
import * as budgetService from '../services/budgetService';

const CategoryBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  backgroundColor: `${colors.primary}05`,
  marginBottom: theme.spacing(2)
}));

const IconSelector = styled(Box)(({ theme, selected }) => ({
  width: 40,
  height: 40,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: selected ? `${colors.primary}20` : `${colors.primary}05`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: `${colors.primary}10`,
    transform: 'translateY(-2px)'
  }
}));

const IconMenuItem = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: `${colors.primary}10`,
    transform: 'scale(1.1)'
  }
}));

const IconGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  maxWidth: '240px',
  maxHeight: '300px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: `${colors.primary}05`,
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: `${colors.primary}20`,
    borderRadius: '4px',
    '&:hover': {
      background: `${colors.primary}30`
    }
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: `${colors.primary}05`,
    transition: 'all 0.2s ease-in-out',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    },
    '&:hover': {
      backgroundColor: `${colors.primary}10`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    borderWidth: 1
  },
  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      color: 'text.secondary',
      opacity: 0.7
    }
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: `${colors.primary}05`,
    transition: 'all 0.2s ease-in-out',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    },
    '&:hover': {
      backgroundColor: `${colors.primary}10`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    }
  },
  '&.MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: `${colors.primary}05`,
    transition: 'all 0.2s ease-in-out',
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    },
    '&:hover': {
      backgroundColor: `${colors.primary}10`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    borderWidth: 1
  }
}));

const ICONS = {
  // Housing & Utilities
  home: <HomeIcon />,
  rent: <RentIcon />,
  office: <OfficeIcon />,
  hotel: <HotelIcon />,
  utilities: <UtilitiesIcon />,
  electricity: <ElectricityIcon />,
  water: <WaterIcon />,
  maintenance: <MaintenanceIcon />,
  maintenanceRepair: <MaintenanceRepairIcon />,
  garden: <GardenIcon />,
  tools: <ToolsIcon />,

  // Transportation
  car: <CarIcon />,
  taxi: <TaxiIcon />,
  transit: <TransitIcon />,
  travel: <TravelIcon />,
  commute: <CommuteIcon />,
  parking: <ParkingIcon />,
  parkingFee: <ParkingFeeIcon />,
  tolls: <TollsIcon />,
  gas: <GasIcon />,

  // Food & Dining
  food: <FoodIcon />,
  grocery: <GroceryIcon />,
  coffee: <CoffeeIcon />,
  nightlife: <NightlifeIcon />,

  // Shopping & Retail
  shopping: <ShoppingCartIcon />,
  shoppingMall: <ShoppingMallIcon />,
  retail: <RetailIcon />,
  electronics: <ElectronicsIcon />,
  giftCard: <GiftCardIcon />,
  shipping: <ShippingIcon />,

  // Health & Wellness
  health: <HealthIcon />,
  medication: <MedicationIcon />,
  therapy: <TherapyIcon />,
  gym: <GymIcon />,
  wellness: <WellnessIcon />,
  beauty: <BeautyIcon />,
  drycleaning: <DryCleaningIcon />,
  laundry: <LaundryIcon />,

  // Entertainment & Hobbies
  entertainment: <EntertainmentIcon />,
  gaming: <GamingIcon />,
  sports: <SportsIcon />,
  hobby: <HobbyIcon />,
  outdoor: <OutdoorIcon />,
  books: <BooksIcon />,
  music: <MusicIcon />,
  audio: <AudioIcon />,
  photography: <PhotographyIcon />,

  // Financial
  cash: <CashIcon />,
  wallet: <WalletIcon />,
  bills: <BillsIcon />,
  savings: <SavingsIcon />,
  investment: <InvestmentIcon />,
  investments: <InvestmentsIcon />,
  crypto: <CryptoIcon />,
  stocks: <StocksIcon />,
  loans: <LoansIcon />,
  debt: <DebtIcon />,
  bankFees: <BankFeesIcon />,
  creditCard: <CreditCardIcon />,
  insurance: <InsuranceIcon />,
  emergency: <EmergencyIcon />,

  // Business & Professional
  business: <BusinessIcon />,
  consulting: <ConsultingIcon />,
  software: <SoftwareIcon />,
  hosting: <HostingIcon />,
  storage: <StorageIcon />,
  printing: <PrintingIcon />,
  international: <InternationalIcon />,
  security: <SecurityIcon />,

  // Education
  education: <EducationIcon />,
  tuition: <TuitionIcon />,

  // Subscriptions & Services
  internet: <InternetIcon />,
  phone: <PhoneIcon />,
  subscriptions: <SubscriptionsIcon />,
  rewards: <RewardsIcon />,

  // Family & Personal
  childcare: <ChildCareIcon />,
  pets: <PetsIcon />,
  gifts: <GiftsIcon />,
  birthday: <BirthdayIcon />,
  vacation: <VacationIcon />,

  // Miscellaneous
  charity: <CharityIcon />,
  sustainability: <SustainabilityIcon />,
  other: <OtherIcon />
};

const CATEGORY_NAMES = {
  // Housing & Utilities
  home: 'Housing',
  rent: 'Rent',
  office: 'Office Space',
  hotel: 'Hotels & Lodging',
  utilities: 'Utilities',
  electricity: 'Electricity',
  water: 'Water',
  maintenance: 'Home Maintenance',
  maintenanceRepair: 'Repairs',
  garden: 'Garden & Outdoor',
  tools: 'Tools & Equipment',

  // Transportation
  car: 'Car & Auto',
  taxi: 'Taxi & Rideshare',
  transit: 'Public Transit',
  travel: 'Travel',
  commute: 'Commute',
  parking: 'Parking',
  parkingFee: 'Parking Fees',
  tolls: 'Tolls',
  gas: 'Gas & Fuel',

  // Food & Dining
  food: 'Food & Dining',
  grocery: 'Groceries',
  coffee: 'Coffee & Snacks',
  nightlife: 'Nightlife',

  // Shopping & Retail
  shopping: 'Shopping',
  shoppingMall: 'Shopping Mall',
  retail: 'Retail',
  electronics: 'Electronics',
  giftCard: 'Gift Cards',
  shipping: 'Shipping',

  // Health & Wellness
  health: 'Healthcare',
  medication: 'Medications',
  therapy: 'Therapy',
  gym: 'Gym & Fitness',
  wellness: 'Wellness',
  beauty: 'Beauty & Personal Care',
  drycleaning: 'Dry Cleaning',
  laundry: 'Laundry',

  // Entertainment & Hobbies
  entertainment: 'Entertainment',
  gaming: 'Gaming',
  sports: 'Sports',
  hobby: 'Hobbies',
  outdoor: 'Outdoor Activities',
  books: 'Books',
  music: 'Music',
  audio: 'Audio',
  photography: 'Photography',

  // Financial
  cash: 'Cash',
  wallet: 'Wallet',
  bills: 'Bills',
  savings: 'Savings',
  investment: 'Investment',
  investments: 'Investments',
  crypto: 'Cryptocurrency',
  stocks: 'Stocks',
  loans: 'Loans',
  debt: 'Debt',
  bankFees: 'Bank Fees',
  creditCard: 'Credit Card',
  insurance: 'Insurance',
  emergency: 'Emergency Fund',

  // Business & Professional
  business: 'Business',
  consulting: 'Consulting',
  software: 'Software',
  hosting: 'Hosting',
  storage: 'Storage',
  printing: 'Printing',
  international: 'International',
  security: 'Security',

  // Education
  education: 'Education',
  tuition: 'Tuition',

  // Subscriptions & Services
  internet: 'Internet',
  phone: 'Phone',
  subscriptions: 'Subscriptions',
  rewards: 'Rewards',

  // Family & Personal
  childcare: 'Childcare',
  pets: 'Pets',
  gifts: 'Gifts',
  birthday: 'Birthday',
  vacation: 'Vacation',

  // Miscellaneous
  charity: 'Charity',
  sustainability: 'Sustainability',
  other: 'Other'
};

const PERIODS = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'biweekly', label: 'Bi-weekly' },
  { value: 'yearly', label: 'Yearly' }
];

const DAYS_OF_WEEK = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' }
];

const DAYS_OF_MONTH = Array.from({ length: 31 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1}${getDayOfMonthSuffix(i + 1)}`
}));

function getDayOfMonthSuffix(day) {
  if (day >= 11 && day <= 13) return 'th';
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

const DateSelector = ({ period, value, onChange }) => {
  const [calendarAnchor, setCalendarAnchor] = useState(null);
  const handleChange = (newValue) => {
    onChange({
      target: {
        name: 'startDate',
        value: newValue
      }
    });
    setCalendarAnchor(null);
  };

  const handleCalendarOpen = (event) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setCalendarAnchor(null);
  };

  switch (period) {
    case 'weekly':
    case 'biweekly':
      return (
        <FormControl fullWidth>
          <StyledSelect
            value={new Date(value).getDay()}
            onChange={(e) => {
              const now = new Date();
              const currentDay = now.getDay();
              const daysToAdd = (e.target.value - currentDay + 7) % 7;
              const newDate = new Date(now);
              newDate.setDate(now.getDate() + daysToAdd);
              handleChange(newDate.toISOString().split('T')[0]);
            }}
          >
            {DAYS_OF_WEEK.map(day => (
              <MenuItem key={day.value} value={day.value}>
                {day.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      );

    case 'monthly':
    case 'yearly':
      return (
        <>
          <StyledTextField
            fullWidth
            value={formatDate(new Date(value), period === 'monthly' ? 'do MMMM' : 'MMMM do, yyyy')}
            onClick={handleCalendarOpen}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small">
                    <ArrowDownIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Menu
            anchorEl={calendarAnchor}
            open={Boolean(calendarAnchor)}
            onClose={handleCalendarClose}
            PaperProps={{
              sx: {
                mt: 1,
                borderRadius: '12px',
                padding: 2
              }
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                value={new Date(value)}
                onChange={(newValue) => {
                  handleChange(newValue.toISOString().split('T')[0]);
                }}
                views={period === 'monthly' ? ['day'] : ['day', 'month', 'year']}
                minDate={period === 'monthly' ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : undefined}
                maxDate={period === 'monthly' ? new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) : undefined}
              />
            </LocalizationProvider>
          </Menu>
        </>
      );

    default:
      return null;
  }
};

const BudgetForm = ({ open, onClose, onSubmit, initialData = null, onDelete }) => {
  const [formData, setFormData] = useState({
    name: '',
    period: 'monthly',
    totalBudget: '',
    categories: [],
    startDate: new Date().toISOString().split('T')[0]
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [categorySpending, setCategorySpending] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        startDate: new Date(initialData.startDate).toISOString().split('T')[0]
      });
      // Fetch category spending when editing an existing budget
      if (initialData._id) {
        budgetService.getBudgetInsights(initialData._id)
          .then(response => {
            if (response.data && response.data.categorySpent) {
              setCategorySpending(response.data.categorySpent);
            }
          })
          .catch(error => console.error('Error fetching category spending:', error));
      }
    }
  }, [initialData]);

  const [newCategory, setNewCategory] = useState({
    name: '',
    icon: 'other',
    amount: '',
    isPercentage: false,
    color: colors.primary
  });

  const [iconMenuAnchor, setIconMenuAnchor] = useState(null);
  const [symbolMenuAnchor, setSymbolMenuAnchor] = useState(null);

  const handleIconMenuOpen = (event) => {
    setIconMenuAnchor(event.currentTarget);
  };

  const handleIconMenuClose = () => {
    setIconMenuAnchor(null);
  };

  const handleSymbolMenuOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSymbolMenuAnchor(event.currentTarget);
  };

  const handleSymbolMenuClose = () => {
    setSymbolMenuAnchor(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const findMatchingIcon = (name) => {
    const searchTerm = name.toLowerCase();
    
    // Direct matches from CATEGORY_NAMES
    const directMatch = Object.entries(CATEGORY_NAMES).find(([key, value]) => 
      value.toLowerCase() === searchTerm
    );
    if (directMatch) return directMatch[0];

    // Partial matches from CATEGORY_NAMES
    const partialMatch = Object.entries(CATEGORY_NAMES).find(([key, value]) => 
      value.toLowerCase().includes(searchTerm) || searchTerm.includes(value.toLowerCase())
    );
    if (partialMatch) return partialMatch[0];

    // Common keyword matches
    const keywordMap = {
      'rent': 'home',
      'mortgage': 'home',
      'apartment': 'home',
      'car': 'car',
      'auto': 'car',
      'vehicle': 'car',
      'gas': 'car',
      'food': 'food',
      'dining': 'food',
      'restaurant': 'food',
      'grocery': 'grocery',
      'groceries': 'grocery',
      'supermarket': 'grocery',
      'coffee': 'coffee',
      'cafe': 'coffee',
      'health': 'health',
      'medical': 'health',
      'doctor': 'health',
      'medicine': 'medication',
      'prescription': 'medication',
      'pharmacy': 'medication',
      'shopping': 'shopping',
      'store': 'shopping',
      'retail': 'shopping',
      'education': 'education',
      'school': 'education',
      'tuition': 'education',
      'book': 'books',
      'supplies': 'books',
      'stationary': 'books',
      'entertainment': 'entertainment',
      'movies': 'entertainment',
      'theatre': 'entertainment',
      'game': 'gaming',
      'gaming': 'gaming',
      'utility': 'utilities',
      'electric': 'utilities',
      'water': 'utilities',
      'internet': 'internet',
      'wifi': 'internet',
      'broadband': 'internet',
      'phone': 'phone',
      'mobile': 'phone',
      'cellular': 'phone',
      'saving': 'savings',
      'invest': 'investment',
      'stock': 'investment',
      'credit': 'creditCard',
      'card': 'creditCard',
      'insurance': 'insurance',
      'child': 'childcare',
      'daycare': 'childcare',
      'babysit': 'childcare',
      'pet': 'pets',
      'animal': 'pets',
      'vet': 'pets',
      'laundry': 'laundry',
      'wash': 'laundry',
      'gym': 'gym',
      'fitness': 'gym',
      'workout': 'gym',
      'parking': 'parking',
      'commute': 'commute',
      'transit': 'commute',
      'transport': 'commute',
      'gift': 'gifts',
      'present': 'gifts',
      'vacation': 'vacation',
      'travel': 'vacation',
      'holiday': 'vacation',
      'beauty': 'beauty',
      'personal': 'beauty',
      'cosmetic': 'beauty',
      'maintenance': 'maintenance',
      'repair': 'maintenance',
      'fix': 'maintenance',
      'charity': 'charity',
      'donate': 'charity',
      'donation': 'charity',
      'tithe': 'charity'
    };

    const matchingKeyword = Object.entries(keywordMap).find(([keyword]) => 
      searchTerm.includes(keyword)
    );
    if (matchingKeyword) return matchingKeyword[1];

    return 'other'; // Default icon if no match found
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setNewCategory(prev => {
      const updates = {
        ...prev,
        [name]: value
      };
      
      // If the name field is being changed, try to guess the icon
      if (name === 'name' && value) {
        updates.icon = findMatchingIcon(value);
      }
      
      return updates;
    });
  };

  const handleIconSelect = (icon) => {
    setNewCategory(prev => ({
      ...prev,
      icon,
      name: CATEGORY_NAMES[icon]
    }));
    handleIconMenuClose();
  };

  const addCategory = () => {
    if (!newCategory.name || !newCategory.amount) return;

    setFormData(prev => ({
      ...prev,
      categories: [...prev.categories, { ...newCategory, id: Date.now() }]
    }));

    setNewCategory({
      name: '',
      icon: 'other',
      amount: '',
      isPercentage: false,
      color: colors.primary
    });
  };

  const removeCategory = (index) => {
    setFormData(prev => ({
      ...prev,
      categories: prev.categories.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  const totalAllocated = formData.categories.reduce((sum, cat) => {
    if (cat.isPercentage) {
      return sum + (Number(formData.totalBudget) * Number(cat.amount) / 100);
    }
    return sum + Number(cat.amount);
  }, 0);
  const remaining = Number(formData.totalBudget) - totalAllocated;

  const handleAmountToggle = () => {
    setNewCategory(prev => ({
      ...prev,
      isPercentage: !prev.isPercentage,
      amount: '' // Reset amount when switching between types
    }));
  };

  useEffect(() => {
    // Update start date when period changes
    const now = new Date();
    let newStartDate;

    switch (formData.period) {
      case 'weekly':
        const nextMonday = new Date(now);
        nextMonday.setDate(now.getDate() + (1 + 7 - now.getDay()) % 7);
        newStartDate = nextMonday;
        break;
      case 'biweekly':
        const nextBiWeeklyMonday = new Date(now);
        nextBiWeeklyMonday.setDate(now.getDate() + (1 + 7 - now.getDay()) % 7);
        newStartDate = nextBiWeeklyMonday;
        break;
      case 'monthly':
        newStartDate = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
      case 'yearly':
        newStartDate = new Date(now.getFullYear(), 0, 1);
        break;
      default:
        newStartDate = now;
    }

    setFormData(prev => ({
      ...prev,
      startDate: newStartDate.toISOString().split('T')[0]
    }));
  }, [formData.period]);

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '20px',
          backgroundColor: '#fff'
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        {initialData ? 'Edit Budget' : 'Create Budget'}
        <IconButton
          onClick={onClose}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: `${colors.primary}10`
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ pb: 2 }}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: 'text.secondary'
                }}
              >
                Budget Name
              </Typography>
              <StyledTextField
                name="name"
                placeholder="Enter budget name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: 'text.secondary'
                }}
              >
                Budget Period
              </Typography>
              <FormControl fullWidth>
                <StyledSelect
                  name="period"
                  value={formData.period}
                  onChange={handleChange}
                  required
                >
                  {PERIODS.map(period => (
                    <MenuItem key={period.value} value={period.value}>
                      {period.label}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: 'text.secondary'
                }}
              >
                Total Budget
              </Typography>
              <StyledTextField
                name="totalBudget"
                placeholder="Enter total budget"
                type="number"
                value={formData.totalBudget}
                onChange={handleChange}
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: 'text.secondary'
                }}
              >
                Start Date
              </Typography>
              <DateSelector
                period={formData.period}
                value={formData.startDate}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 700,
                  fontSize: '1.25rem',
                  color: 'text.primary'
                }}
              >
                Categories
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Chip
                  label={`Allocated: $${totalAllocated.toLocaleString()}`}
                  sx={{
                    backgroundColor: `${colors.primary}10`,
                    color: colors.primary,
                    fontWeight: 600
                  }}
                />
                <Chip
                  label={`Remaining: $${remaining.toLocaleString()}`}
                  sx={{
                    backgroundColor: remaining < 0 ? `${colors.status.error}10` : `${colors.status.ready}10`,
                    color: remaining < 0 ? colors.status.error : colors.status.ready,
                    fontWeight: 600
                  }}
                />
              </Box>
            </Box>

            {formData.categories.map((category, index) => (
              <CategoryBox key={category.id || index}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ 
                      width: 40, 
                      height: 40, 
                      borderRadius: '8px',
                      backgroundColor: `${colors.primary}10`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      {ICONS[category.icon]}
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {category.name}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Typography variant="body2" color="text.secondary">
                          Budget: {category.isPercentage 
                            ? `${Number(category.amount)}% ($${(Number(formData.totalBudget) * Number(category.amount) / 100).toLocaleString()})`
                            : `$${Number(category.amount).toLocaleString()}`
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <IconButton onClick={() => removeCategory(index)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </CategoryBox>
            ))}

            <CategoryBox>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item>
                  <Tooltip title="Select Category Icon">
                    <IconButton 
                      onClick={handleIconMenuOpen}
                      sx={{ 
                        width: 56,
                        height: 56,
                        backgroundColor: `${colors.primary}05`,
                        borderRadius: '12px',
                        '&:hover': {
                          backgroundColor: `${colors.primary}10`
                        }
                      }}
                    >
                      {ICONS[newCategory.icon]}
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={iconMenuAnchor}
                    open={Boolean(iconMenuAnchor)}
                    onClose={handleIconMenuClose}
                    PaperProps={{
                      sx: {
                        mt: 1,
                        borderRadius: '12px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        maxHeight: '300px'
                      }
                    }}
                  >
                    <IconGrid>
                      {Object.entries(ICONS).map(([key, icon]) => (
                        <Tooltip key={key} title={CATEGORY_NAMES[key]} placement="top">
                          <IconMenuItem
                            onClick={() => {
                              setNewCategory(prev => ({ ...prev, icon: key }));
                              handleIconMenuClose();
                            }}
                            sx={{
                              backgroundColor: newCategory.icon === key ? `${colors.primary}20` : 'transparent'
                            }}
                          >
                            {icon}
                          </IconMenuItem>
                        </Tooltip>
                      ))}
                    </IconGrid>
                  </Menu>
                </Grid>
                <Grid item xs>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 1,
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      color: 'text.secondary'
                    }}
                  >
                    Category Name
                  </Typography>
                  <StyledTextField
                    name="name"
                    placeholder="Enter category name"
                    value={newCategory.name}
                    onChange={handleCategoryChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 1,
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      color: 'text.secondary'
                    }}
                  >
                    Amount
                  </Typography>
                  <StyledTextField
                    name="amount"
                    placeholder="Enter amount"
                    type="number"
                    value={newCategory.amount}
                    onChange={handleCategoryChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box 
                            onClick={handleSymbolMenuOpen}
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              cursor: 'pointer',
                              userSelect: 'none',
                              padding: '4px',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: `${colors.primary}10`
                              }
                            }}
                          >
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              gap: 0.5
                            }}>
                              {newCategory.isPercentage ? '%' : '$'}
                              <ArrowDownIcon fontSize="small" />
                            </Box>
                          </Box>
                        </InputAdornment>
                      ),
                      endAdornment: newCategory.isPercentage && newCategory.amount ? (
                        <InputAdornment position="end">
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ 
                              borderLeft: '1px solid',
                              borderColor: 'divider',
                              pl: 1,
                              ml: 1
                            }}
                          >
                            ${(Number(formData.totalBudget) * Number(newCategory.amount) / 100).toLocaleString()}
                          </Typography>
                        </InputAdornment>
                      ) : null
                    }}
                  />
                </Grid>
              </Grid>
              <Menu
                anchorEl={symbolMenuAnchor}
                open={Boolean(symbolMenuAnchor)}
                onClose={handleSymbolMenuClose}
                PaperProps={{
                  sx: {
                    mt: 1,
                    borderRadius: '12px',
                    minWidth: '100px'
                  }
                }}
              >
                <MenuItem 
                  onClick={() => {
                    setNewCategory(prev => ({ ...prev, isPercentage: false, amount: '' }));
                    handleSymbolMenuClose();
                  }}
                  selected={!newCategory.isPercentage}
                >
                  <Typography>Dollar ($)</Typography>
                </MenuItem>
                <MenuItem 
                  onClick={() => {
                    setNewCategory(prev => ({ ...prev, isPercentage: true, amount: '' }));
                    handleSymbolMenuClose();
                  }}
                  selected={newCategory.isPercentage}
                >
                  <Typography>Percent (%)</Typography>
                </MenuItem>
              </Menu>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  onClick={addCategory}
                  startIcon={<AddIcon />}
                  variant="contained"
                  disabled={!newCategory.name || !newCategory.amount}
                >
                  Add Category
                </Button>
              </Box>
            </CategoryBox>
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3, justifyContent: initialData ? 'space-between' : 'flex-end' }}>
          {initialData && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setShowDeleteConfirm(true)}
              sx={{
                borderRadius: '50px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#FEE2E2'
                }
              }}
            >
              Delete Budget
            </Button>
          )}
          <Box>
            <Button
              onClick={onClose}
              sx={{
                mr: 2,
                color: 'text.secondary',
                borderRadius: '50px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: `${colors.primary}10`
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                borderRadius: '50px',
                textTransform: 'none'
              }}
            >
              {initialData ? 'Save Changes' : 'Create Budget'}
            </Button>
          </Box>
        </DialogActions>
      </form>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            backgroundColor: '#fff',
            maxWidth: '400px'
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          Delete Budget
          <IconButton
            onClick={() => setShowDeleteConfirm(false)}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: `${colors.primary}10`
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <Typography>
            Are you sure you want to delete this budget? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => setShowDeleteConfirm(false)}
            sx={{
              mr: 2,
              color: 'text.secondary',
              borderRadius: '50px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: `${colors.primary}10`
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDelete(initialData._id);
              setShowDeleteConfirm(false);
            }}
            sx={{
              borderRadius: '50px',
              textTransform: 'none'
            }}
          >
            Delete Budget
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default BudgetForm; 
import api from './api';
import logger from '../utils/logger';

/**
 * Fetch paginated alerts with optional filters
 */
export const getAlerts = async (params = {}) => {
  try {
    logger.debug('Building alert request params:', params);

    // Clean up undefined and empty values
    const cleanParams = { ...params };
    Object.keys(cleanParams).forEach(key => {
      if (cleanParams[key] === undefined || cleanParams[key] === '' || 
          (Array.isArray(cleanParams[key]) && cleanParams[key].length === 0)) {
        delete cleanParams[key];
      }
    });

    // Convert arrays to comma-separated strings
    if (Array.isArray(cleanParams.status)) {
      cleanParams.status = cleanParams.status.join(',');
    }

    logger.debug('Cleaned alert params:', cleanParams);

    const response = await api.get('/alerts', { params: cleanParams });
    
    logger.debug('Alert request successful:', {
      status: response.status,
      alertCount: response.data.alerts?.length,
      pagination: response.data.pagination
    });
    return response.data;
  } catch (error) {
    logger.error('Alert request failed:', {
      status: error.response?.status,
      error: error.message,
      response: error.response?.data
    });
    throw error;
  }
};

/**
 * Mark multiple alerts as read
 */
export const markAlertsAsRead = async (alertIds) => {
  try {
    logger.debug('Marking alerts as read:', { alertIds });
    const response = await api.post('/alerts/read', { alertIds });
    return response.data;
  } catch (error) {
    logger.error('Error marking alerts as read:', {
      alertIds,
      error: error.message
    });
    throw error;
  }
};

/**
 * Mark a single alert as resolved
 */
export const markAlertAsResolved = async (alertId) => {
  try {
    logger.debug('Marking alert as resolved:', { alertId });
    const response = await api.post(`/alerts/${alertId}/resolve`);
    return response.data;
  } catch (error) {
    logger.error('Error marking alert as resolved:', {
      alertId,
      error: error.message
    });
    throw error;
  }
};

/**
 * Dismiss multiple alerts
 */
export const dismissAlerts = async (alertIds) => {
  try {
    logger.debug('Dismissing alerts:', { alertIds });
    const response = await api.post('/alerts/dismiss', { alertIds });
    return response.data;
  } catch (error) {
    logger.error('Error dismissing alerts:', {
      alertIds,
      error: error.message
    });
    throw error;
  }
};

/**
 * Get alert counts by status
 */
export const getAlertCounts = async () => {
  try {
    const response = await api.get('/alerts/counts');
    return response.data;
  } catch (error) {
    logger.error('Error getting alert counts:', error.message);
    throw error;
  }
};

export default {
  getAlerts,
  markAlertsAsRead,
  markAlertAsResolved,
  dismissAlerts,
  getAlertCounts
}; 
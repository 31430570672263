import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, Box, Grid, Fade, Container, Link, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Login from './Login';
import { colors, commonStyles } from '../styles';

// Custom X (Twitter) Logo
const XIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </SvgIcon>
);

// Bitcoin Logo
const BitcoinIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M23.638 14.904c-1.602 6.43-8.113 10.34-14.542 8.736C2.67 22.05-1.244 15.525.362 9.105 1.962 2.67 8.475-1.243 14.9.358c6.43 1.605 10.342 8.115 8.738 14.548v-.002zm-6.35-4.613c.24-1.59-.974-2.45-2.64-3.03l.54-2.153-1.315-.33-.525 2.107c-.345-.087-.705-.167-1.064-.25l.526-2.127-1.32-.33-.54 2.165c-.285-.067-.565-.132-.84-.2l-1.815-.45-.35 1.407s.974.225.955.236c.535.136.63.486.615.766l-1.477 5.92c-.075.18-.24.45-.614.35.015.02-.96-.24-.96-.24l-.66 1.51 1.71.426.93.236-.54 2.19 1.32.327.54-2.17c.36.1.705.19 1.05.273l-.51 2.154 1.32.33.545-2.19c2.24.427 3.93.257 4.64-1.774.57-1.637-.03-2.58-1.217-3.196.854-.193 1.5-.76 1.68-1.93h.01zm-3.01 4.22c-.404 1.64-3.157.75-4.05.53l.72-2.9c.896.23 3.757.67 3.33 2.37zm.41-4.24c-.37 1.49-2.662.735-3.405.55l.654-2.64c.744.18 3.137.524 2.75 2.084v.006z" />
  </SvgIcon>
);

const MainButton = styled(Button)(commonStyles.mainButton);
const FeatureSection = styled(Box)(commonStyles.featureSection);
const SlantedSection = styled(Box)(commonStyles.slantedSection);

const InvertedSlantedSection = styled(Box)(({ theme }) => ({
  ...commonStyles.slantedSection,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: `linear-gradient(135deg, transparent 0%, ${colors.primary} 0%)`,
    clipPath: 'polygon(0 10%, 100% 0%, 100% 100%, 0% 100%)',
    zIndex: -1,
  }
}));

const DictionaryEntry = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(4),
  color: colors.secondary,
}));

const Word = styled(Typography)(({ theme }) => ({
  fontSize: '3.5rem',
  fontWeight: 700,
  color: colors.primary,
  marginBottom: theme.spacing(1),
}));

const Pronunciation = styled('span')(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 400,
  color: colors.secondary,
  marginLeft: theme.spacing(2),
}));

const Definition = styled(Box)(({ theme }) => ({
  marginLeft: 0,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(4)
  }
}));

const DefinitionNumber = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const DefinitionText = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  marginBottom: theme.spacing(1),
}));

const Example = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontStyle: 'italic',
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Origin = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${colors.primary}20`,
  paddingTop: theme.spacing(3),
  marginTop: theme.spacing(4),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: `${colors.primary}10`,
  borderRadius: '24px',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '320px',
  margin: '0 auto',
  '&::before': {
    content: '""',
    display: 'block',
    paddingTop: '100%',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(135deg, ${colors.primary}20 0%, transparent 100%)`,
    borderRadius: 'inherit',
  },
  '& .icon-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25%',
  },
  '& svg': {
    width: '100%',
    height: '100%',
    color: colors.primary,
    position: 'relative',
    zIndex: 1,
  }
}));

const FeatureBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0.05,
  overflow: 'hidden',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    borderRadius: '50%',
    background: colors.primary,
  },
  '&::before': {
    width: '40%',
    paddingBottom: '40%',
    top: '-10%',
    right: '-10%',
    opacity: 0.5,
  },
  '&::after': {
    width: '25%',
    paddingBottom: '25%',
    bottom: '-5%',
    left: '-5%',
    opacity: 0.3,
  },
}));

// Add new styled components for the visualization
const SmartBudgetingVisual = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  overflow: 'hidden',
  zIndex: 0,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(90deg, ${colors.primary}10 0%, transparent 50%)`,
  }
}));

const Star = styled('div')(({ size = 3, x = 0, y = 0, delay = 0 }) => ({
  position: 'absolute',
  width: size,
  height: size,
  background: 'transparent',
  left: x,
  top: y,
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: colors.primary,
    clipPath: 'polygon(50% 0, 65% 35%, 100% 50%, 65% 65%, 50% 100%, 35% 65%, 0 50%, 35% 35%)',
  },
  opacity: 0.7,
  boxShadow: `0 0 ${size * 2}px ${size/2}px ${colors.primary}40`,
  animation: `twinkle 6s ease-in-out infinite, float${Math.floor(Math.random() * 3)} 8s ease-in-out infinite`,
  animationDelay: `${delay}s, ${delay * 0.5}s`,
  '@keyframes twinkle': {
    '0%, 20%, 100%': {
      opacity: 0.1,
      boxShadow: `0 0 ${size * 2}px ${size/2}px ${colors.primary}20`,
    },
    '50%, 55%': {
      opacity: 1,
      boxShadow: `0 0 ${size * 8}px ${size}px ${colors.primary}90`,
    }
  },
  '@keyframes float0': {
    '0%, 100%': {
      transform: 'translate(0, 0)',
    },
    '50%': {
      transform: 'translate(20px, -20px)',
    }
  },
  '@keyframes float1': {
    '0%, 100%': {
      transform: 'translate(0, 0)',
    },
    '50%': {
      transform: 'translate(-15px, -25px)',
    }
  },
  '@keyframes float2': {
    '0%, 100%': {
      transform: 'translate(0, 0)',
    },
    '50%': {
      transform: 'translate(25px, -10px)',
    }
  }
}));

const ConnectionLine = styled('div')(({ rotation = 0, opacity = 0.2 }) => ({
  position: 'absolute',
  width: '150px',
  height: '1px',
  background: `linear-gradient(90deg, ${colors.primary}${Math.floor(opacity * 100)}, transparent)`,
  transform: `rotate(${rotation}deg)`,
  animation: 'pulse 4s ease-in-out infinite',
  '@keyframes pulse': {
    '0%, 100%': {
      opacity: opacity,
    },
    '50%': {
      opacity: opacity * 0.5,
    }
  }
}));

const HeroVideo = styled('video')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '100%',
  minHeight: '150vh',
  width: 'auto',
  height: 'auto',
  objectFit: 'cover',
  zIndex: 0,
});

const HeroOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '150vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
});

const Welcome = () => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const learnMoreRef = useRef(null);
  const [showFloatingQR, setShowFloatingQR] = useState(false);
  const [isVisualActive, setIsVisualActive] = useState(false);

  // Keep the scroll handler
  useEffect(() => {
    const handleScroll = () => {
      if (learnMoreRef.current) {
        const scrollPosition = window.scrollY;
        const opacity = Math.max(0, 1 - scrollPosition / 300);
        learnMoreRef.current.style.opacity = opacity.toString();
        learnMoreRef.current.style.transform = `translate(-50%, ${scrollPosition * 0.5}px)`;
      }
      
      const isNearBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 300;
      setShowFloatingQR(window.scrollY > window.innerHeight && !isNearBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const features = [
    {
      title: 'Gain confidence',
      description: (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 3,
          position: 'relative',
          zIndex: 1,
          maxWidth: '600px'
        }}>
          <Typography variant="body1" sx={{
            color: colors.secondary,
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.6,
          }}>
            Easily track your spending by day, week, and month with our intuitive dashboard. See your financial patterns at a glance and understand where your money is going in real-time.
          </Typography>
          <Typography variant="body1" sx={{
            color: colors.secondary,
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.6,
          }}>
            As you begin tracking your individual transactions, Budgit will begin to take over that job for you. Our AI learns your preferences and automatically categorizes new transactions, making financial management effortless and giving you confidence in your spending decisions.
          </Typography>
        </Box>
      ),
      background: (
        <SmartBudgetingVisual>
          {/* Background stars spread across entire section */}
          {Array.from({ length: 20 }).map((_, index) => {
            const size = Math.random() * 8 + 4;
            return (
              <Star 
                key={`bg-${index}`}
                size={size}
                sx={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                delay={Math.random() * 4}
              />
            );
          })}

          {/* Concentrated stars in empty spaces */}
          {Array.from({ length: 30 }).map((_, index) => {
            const size = Math.random() * 12 + 8;
            return (
              <Star 
                key={`main-${index}`}
                size={size}
                sx={{
                  left: {
                    xs: `${Math.random() * 100}%`, // Full width on mobile
                    md: `${50 + Math.random() * 40}%`, // Right side on desktop
                  },
                  top: {
                    xs: `${75 + Math.random() * 20}%`, // Bottom 25% on mobile
                    md: `${Math.random() * 100}%`, // Full height on desktop
                  },
                }}
                delay={Math.random() * 4}
              />
            );
          })}
          
          {/* Connection lines */}
          {Array.from({ length: 15 }).map((_, index) => {
            const rotation = Math.random() * 360;
            const opacity = Math.random() * 0.3 + 0.1;
            return (
              <ConnectionLine 
                key={index}
                sx={{
                  left: {
                    xs: `${Math.random() * 100}%`, // Full width on mobile
                    md: `${65 + Math.random() * 25}%`, // Right side on desktop
                  },
                  top: {
                    xs: `${80 + Math.random() * 15}%`, // Bottom 20% on mobile
                    md: `${20 + Math.random() * 60}%`, // Middle area on desktop
                  },
                }}
                rotation={rotation}
                opacity={opacity}
              />
            );
          })}
        </SmartBudgetingVisual>
      ),
      customLayout: true,
    },
    {
      title: 'Get rewarded',
      description: (
        <Typography variant="body1" sx={{
          color: 'rgba(255, 255, 255, 0.9)',
          fontSize: { xs: '1rem', md: '1.1rem' },
          lineHeight: 1.6,
        }}>
          Join our community of smart savers and earn money for sticking with your budget, making smart financial decisions, and achieving your goals. Monthly rewards are paid out to the top 100 users based on your financial ambition, progress, and health. We will keep track of leading users on our leaderboard.
        </Typography>
      ),
      icon: <EmojiEventsIcon />,
    },
    {
      title: 'Smart budgeting',
      description: (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography variant="body1" sx={{
            color: colors.secondary,
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.6,
          }}>
            Your personal AI model learns from your spending patterns to provide insights and recommendations as you go. Get ahead of your finances with intelligent categorization and predictive analysis that helps you save more and stress less.
          </Typography>
          <Typography variant="body1" sx={{
            color: colors.secondary,
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.6,
          }}>
            Fine-tune your personalized AI model to match your preferences, ensuring your expenses are organized exactly how you want them. No more spending hours each week undoing and correcting mistakes - it happens while you spend! As our community grows, the model becomes more intuitive for everyone while keeping your data safe and anonymous.
          </Typography>
        </Box>
      ),
      icon: <RocketLaunchIcon />,
    },
    {
      title: 'Bitcoin',
      description: (
        <Typography variant="body1" sx={{
          color: 'rgba(255, 255, 255, 0.9)',
          fontSize: { xs: '1rem', md: '1.1rem' },
          lineHeight: 1.6,
        }}>
          As fellow Bitcoiners, we understand the importance of tracking your sats alongside traditional assets. Get a complete view of your stack and watch your Bitcoin savings grow within the same tool you use to optimize your financial future.
        </Typography>
      ),
      icon: <BitcoinIcon />,
    },
  ];

  return (
    <Box sx={{ 
      backgroundColor: colors.background,
      width: '100%',
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      position: 'relative',
    }}>
      {/* Hero Section */}
      <Box sx={{ 
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'visible',
        backgroundColor: colors.background,
      }}>
        <HeroVideo
          autoPlay
          muted
          loop
          playsInline
        >
          <source src="/videos/hero.mp4" type="video/mp4" />
        </HeroVideo>
        <HeroOverlay />
        <Container maxWidth="lg" sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
          zIndex: 2,
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 3,
            mb: 3,
            position: 'relative',
            zIndex: 2,
          }}>
            <img 
              src="/images/budgit-logo-md.png" 
              alt="Budgit Logo" 
              style={{ 
                height: '80px',
                width: 'auto',
              }} 
            />
            <Typography variant="h1" sx={{ 
              fontSize: { xs: '4rem', md: '6rem' },
              fontWeight: 900,
              color: colors.primary,
              letterSpacing: '-0.02em',
              textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}>
              Budgit
            </Typography>
          </Box>
          <Typography variant="h2" sx={{ 
            fontSize: { xs: '1.5rem', md: '2rem' },
            fontWeight: 500,
            color: 'white',
            mb: 6,
            maxWidth: '600px',
            position: 'relative',
            zIndex: 2,
            textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
          }}>
            Do more with your money.
          </Typography>
          <Box sx={{ display: 'flex', gap: 3, mb: 8, position: 'relative', zIndex: 2 }}>
            <MainButton onClick={() => setIsLoginOpen(true)} size="large" sx={{ color: colors.white }}>
              GET STARTED
            </MainButton>
          </Box>

          {/* QR Code */}
          <Box sx={{ mt: 4, position: 'relative', zIndex: 2 }}>
            <img src="/images/budgit-qr-code.png" alt="QR Code" width="120" height="120" />
            <Typography variant="body2" sx={{ mt: 1, color: colors.white, textAlign: 'center' }}>
              Download the app
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Floating QR */}
      <Fade in={showFloatingQR}>
        <Box
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(254, 242, 241, 0.9)',
            padding: 2,
            borderRadius: 2,
            boxShadow: 3,
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <img src="/images/budgit-qr-dark.png" alt="QR Code" width="80" height="80" />
          <Typography variant="body2" sx={{ mt: 1, color: colors.secondary, fontSize: '0.8rem' }}>
            Download the app
          </Typography>
        </Box>
      </Fade>

      {/* Learn More Arrow */}
      <Box
        ref={learnMoreRef}
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
          zIndex: 1,
        }}
      >
        <Typography variant="body2" sx={{ color: colors.primary, mb: 1 }}>
          learn more
        </Typography>
        <KeyboardArrowDownIcon sx={{ color: colors.primary, fontSize: 30 }} />
      </Box>

      {/* Features Section */}
      <Box sx={{ position: 'relative', zIndex: 4 }}>
        <SlantedSection />
      </Box>

      {/* First Feature - Dictionary Entry */}
      <FeatureSection
        component={SlantedSection}
        sx={{ 
          backgroundColor: 'transparent',
          padding: 0,
          width: '100%',
          position: 'relative',
          zIndex: 4,
        }}
      >
        <Box sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: { 
            xs: '12rem 2rem 4rem 2rem', 
            md: '16rem 4rem 6rem 4rem' 
          },
          position: 'relative',
          zIndex: 1,
        }}>
          <DictionaryEntry sx={{ color: colors.white }}>
            <Box sx={{ mb: 6 }}>
              <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 4 }}>
                <Word variant="h1" sx={{ color: colors.white }}>budgit</Word>
                <Pronunciation sx={{ color: colors.white }}>
                  [buh-jit]
                </Pronunciation>
              </Box>

              <Definition>
                <DefinitionNumber sx={{ color: colors.white }}>
                  1. verb (informal)
                </DefinitionNumber>
                <DefinitionText sx={{ color: colors.white }}>
                  To move your finances in the right direction—shifting small obstacles out of the way so you can finally gain control.
                </DefinitionText>
                <Example sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                  "Staring at my massive financial mountain left me paralyzed and then I learned to budgit."
                </Example>

                <DefinitionNumber sx={{ mt: 4, color: colors.white }}>
                  2. noun (proper)
                </DefinitionNumber>
                <DefinitionText sx={{ color: colors.white }}>
                  A personal finance app created to help individuals and small businesses manage their income and expenses—turning what feels impossible into something doable (and maybe even fun).
                </DefinitionText>
              </Definition>

              <Origin sx={{ borderColor: `${colors.white}20` }}>
                <Typography variant="h6" sx={{ color: colors.white, mb: 2, fontWeight: 600 }}>
                  Origin of budgit
                </Typography>
                <Typography sx={{ color: colors.white }}>
                  First recorded in 2025; from Late Modern English "budge" (to move or shift slightly) + "it" (the pronoun referring to "the thing at hand").
                </Typography>
              </Origin>
            </Box>
          </DictionaryEntry>
        </Box>
      </FeatureSection>

      {/* Remaining Features */}
      {features.map((feature, index) => (
        <FeatureSection
          key={index}
          component={feature.title === 'Bitcoin' ? InvertedSlantedSection : undefined}
          sx={{ 
            backgroundColor: feature.title === 'Bitcoin' ? 'transparent' :
                           feature.title === 'Get rewarded' ? colors.secondary : 
                           feature.title === 'Smart budgeting' ? `${colors.primary}05` :
                           colors.white,
            padding: 0,
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <FeatureBackground sx={{
            '&::before, &::after': {
              background: feature.title === 'Get rewarded' ? 'rgba(255, 255, 255, 0.1)' : colors.primary,
            }
          }} />
          {feature.background && feature.background}
          <Box sx={{
            width: '100%',
            maxWidth: '1200px',
            margin: '0 auto',
            padding: { 
              xs: feature.title === 'Bitcoin' ? '12rem 2rem 4rem 2rem' : '4rem 2rem', 
              md: feature.title === 'Bitcoin' ? '16rem 4rem 6rem 4rem' : '6rem 4rem' 
            },
            position: 'relative',
            zIndex: 1,
          }}>
            <Grid container spacing={8} alignItems="center" direction={feature.customLayout ? 'row' : (index % 2 === 0 ? 'row' : 'row-reverse')}>
              <Grid item xs={12} md={feature.customLayout ? 7 : 6} sx={{ 
                textAlign: { xs: 'center', md: 'left' },
                pl: { md: feature.customLayout ? 4 : 0 },
                position: 'relative',
              }}>
                <Typography variant="h3" component="h3" sx={{
                  mb: 3,
                  fontWeight: 800,
                  color: (feature.title === 'Get rewarded' || feature.title === 'Bitcoin') ? 'white' : colors.primary,
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  letterSpacing: '-0.02em',
                }}>
                  {feature.title}
                </Typography>
                {feature.description}
              </Grid>
              {!feature.customLayout && (
                <Grid item xs={12} md={6}>
                  <Box sx={{
                    transform: { xs: 'none', md: 'scale(1.1)' },
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: { xs: 'none', md: 'scale(1.15)' },
                    }
                  }}>
                    <IconWrapper sx={{
                      backgroundColor: (feature.title === 'Get rewarded' || feature.title === 'Bitcoin') ? 'rgba(255, 255, 255, 0.1)' : `${colors.primary}10`,
                      '& svg': {
                        color: (feature.title === 'Get rewarded' || feature.title === 'Bitcoin') ? 'white' : colors.primary,
                      }
                    }}>
                      <Box className="icon-container">
                        {feature.icon}
                      </Box>
                    </IconWrapper>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </FeatureSection>
      ))}

      {/* Footer */}
      <Box sx={{ 
        backgroundColor: colors.background,
        borderTop: `1px solid ${colors.primary}20`,
        pt: 8,
        pb: 4,
        px: { xs: 2, md: 4 }
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Download Section */}
            <Grid item xs={12} sx={{ mb: 6, display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ 
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                gap: 4,
                p: 4,
                backgroundColor: `${colors.primary}05`,
                borderRadius: 4,
                width: '100%',
                maxWidth: 'md',
              }}>
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: { xs: 'center', sm: 'flex-start' },
                  flex: 1,
                }}>
                  <Typography variant="h4" sx={{ 
                    fontWeight: 800,
                    color: colors.primary,
                    mb: 2,
                    textAlign: { xs: 'center', sm: 'left' }
                  }}>
                    Ready to get moving?
                  </Typography>
                  <MainButton size="large" onClick={() => setIsLoginOpen(true)} sx={{ color: colors.white }}>
                    GET STARTED
                  </MainButton>
                </Box>
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <img src="/images/budgit-qr-dark.png" alt="QR Code" width="120" height="120" />
                  <Typography variant="body2" sx={{ mt: 1, color: colors.secondary }}>
                    Scan to download
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Social Media */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ fontWeight: 700, mb: 2, color: colors.primary }}>
                Follow Our Journey
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                {[
                  { 
                    icon: <XIcon />, 
                    name: 'X', 
                    href: 'https://x.com/thebudgitapp'
                  }
                ].map((item) => (
                  <Link
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      textDecoration: 'none',
                    }}
                  >
                    <Box
                      sx={{ 
                        cursor: 'pointer',
                        color: colors.secondary,
                        '&:hover': { 
                          color: colors.primary,
                          transform: 'scale(1.1)',
                        },
                        transition: 'all 0.2s ease-in-out',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 40,
                        height: 40,
                        '& svg': {
                          fontSize: 20
                        }
                      }}
                    >
                      {item.icon}
                    </Box>
                  </Link>
                ))}
              </Box>
              <Typography variant="body2" sx={{ color: colors.secondary }}>
                Follow us on X (Twitter) for product updates, financial tips, and early access to new features.
              </Typography>
            </Grid>

            {/* Contact Us */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ fontWeight: 700, mb: 2, color: colors.primary }}>
                Contact Us
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2" sx={{ color: colors.secondary }}>
                  support@budgit.app
                </Typography>
                <Typography variant="body2" sx={{ color: colors.secondary }}>
                  Mon-Fri: 9am-5pm PST
                </Typography>
              </Box>
            </Grid>

            {/* Disclaimer */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ fontWeight: 700, mb: 2, color: colors.primary }}>
                Legal
              </Typography>
              <Typography variant="body2" sx={{ color: colors.secondary, mb: 2, lineHeight: 1.6, fontSize: '0.8rem', textAlign: 'justify' }}>
                Budgit provides general information to help you manage your finances. This information should not be construed as professional financial advice. Always consult a qualified financial advisor for personalized guidance.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.secondary, mb: 2, lineHeight: 1.6, fontSize: '0.8rem', textAlign: 'justify' }}>
                Our AI insights and automatic categorizations are provided "as-is" and may contain inaccuracies. Please verify your data and consult professionals where necessary. Results may vary, and savings or rewards are not guaranteed.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.secondary, mb: 2, lineHeight: 1.6, fontSize: '0.8rem', textAlign: 'justify' }}>
                Budgit relies on third-party providers (e.g., Plaid) for certain financial data and transactions. We are not liable for errors, delays, or outages caused by these providers. While we use industry-standard security measures, no method of transmission or storage is completely secure.
              </Typography>
              <Typography variant="body2" sx={{ color: colors.secondary, mb: 2, lineHeight: 1.6, fontSize: '0.8rem', textAlign: 'justify' }}>
                Users are fully responsible for their financial decisions. Use of Budgit and reliance on any insights or AI-driven suggestions is solely at your own risk. Budgit is not liable for any direct or indirect damages.
              </Typography>
            </Grid>

            {/* Copyright */}
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="caption" sx={{ color: colors.secondary, opacity: 0.8 }}>
                © {new Date().getFullYear()} Budgit. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {isLoginOpen !== undefined && (
        <Login open={isLoginOpen} onClose={() => setIsLoginOpen(false)} />
      )}
    </Box>
  );
};

export default Welcome;

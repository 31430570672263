import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export const PrivateRoute = ({ children }) => {
  const { user, token } = useAuth();
  const location = useLocation();

  // If we have a token but no user, it means we're still loading
  if (token && !user) {
    return null; // or a loading spinner
  }

  if (!token || !user) {
    // Redirect to login but save the attempted location
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
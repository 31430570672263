import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
  Grid,
  MenuItem
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 500
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.grey[100],
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 0.7
    }
  }
}));

const EditModal = ({ 
  open, 
  onClose, 
  title, 
  fields = [], 
  onSave,
  initialValues = {},
  description,
  layout = 'stack'
}) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    if (field.transform) {
      value = field.transform(value);
    }
    setValues(prev => ({
      ...prev,
      [field.name]: value
    }));
  };

  const handleSave = () => {
    onSave(values);
    onClose();
  };

  const renderField = (field) => {
    if (!field) return null;

    if (field.isContainerField) {
      return (
        <Box sx={{ mb: 2, ...field.containerSx }}>
          {field.fields?.map(subField => (
            <Box 
              key={subField.name} 
              sx={{ 
                flex: subField.sx?.flex || 'none', 
                width: subField.sx?.width,
                minWidth: subField.sx?.width
              }}
            >
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 1,
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  color: 'text.secondary'
                }}
              >
                {subField.label}
              </Typography>
              <StyledTextField
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={values[subField.name] || ''}
                onChange={handleChange(subField)}
                placeholder={subField.type === 'select' ? undefined : `Enter ${subField.label.toLowerCase()}`}
                type={subField.type === 'select' ? 'text' : subField.type || 'text'}
                required={subField.required}
                autoComplete={subField.autoComplete}
                select={subField.type === 'select'}
                error={subField.error}
                helperText={subField.error ? subField.patternError : undefined}
                inputProps={{
                  pattern: subField.pattern,
                  maxLength: subField.maxLength
                }}
                sx={subField.sx}
              >
                {subField.type === 'select' && subField.options?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Box>
          ))}
        </Box>
      );
    }

    return (
      <Box key={field.name} sx={{ mb: 2, width: '100%' }}>
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 1,
            fontSize: '1.1rem',
            fontWeight: 500,
            color: 'text.secondary'
          }}
        >
          {field.label}
        </Typography>
        <StyledTextField
          fullWidth={field.fullWidth !== false}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={values[field.name] || ''}
          onChange={handleChange(field)}
          placeholder={field.type === 'select' ? undefined : `Enter ${field.label.toLowerCase()}`}
          type={field.type === 'select' ? 'text' : field.type || 'text'}
          required={field.required}
          autoComplete={field.autoComplete}
          select={field.type === 'select'}
          error={field.error}
          helperText={field.error ? field.patternError : undefined}
          inputProps={{
            pattern: field.pattern,
            maxLength: field.maxLength
          }}
          sx={field.sx}
        >
          {field.type === 'select' && field.options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
      </Box>
    );
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.75rem', mt: 1 }}>
          {title}
        </Typography>
      </DialogTitle>

      {description && (
        <DialogContent sx={{ p: 0, mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem' }}>
            {description}
          </Typography>
        </DialogContent>
      )}

      <DialogContent sx={{ p: 0 }}>
        {layout === 'grid' ? (
          <Grid container spacing={2} sx={{ width: '100%', m: 0 }}>
            {Array.isArray(fields) && fields.map((field) => (
              <Grid 
                item 
                xs={field.gridWidth || 12} 
                key={field.name}
                sx={{ 
                  p: '0 !important',
                  pr: field.gridWidth !== 12 ? '8px !important' : '0 !important',
                  '&:last-child': {
                    pr: '0 !important'
                  }
                }}
              >
                {renderField(field)}
              </Grid>
            ))}
          </Grid>
        ) : (
          Array.isArray(fields) && fields.map(renderField)
        )}
      </DialogContent>

      <DialogActions sx={{ p: 0, mt: 2 }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSave}
          sx={{
            borderRadius: '9999px',
            py: 1.5,
            textTransform: 'none',
            fontWeight: 1000,
            fontSize: '1.1rem'
          }}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default EditModal; 
import React, { useRef, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
`;

const PinContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasError'
})(({ theme, hasError }) => ({
  display: 'flex',
  gap: '12px',
  justifyContent: 'center',
  animation: hasError ? `${shakeAnimation} 0.5s ease-in-out` : 'none',
}));

const PinTextField = styled(TextField)(({ theme }) => ({
  width: '60px',
  '& .MuiOutlinedInput-root': {
    height: '60px',
    borderRadius: '12px',
    backgroundColor: theme.palette.grey[100],
    caretColor: 'transparent',
    '& input': {
      textAlign: 'center',
      fontSize: '42px',
      fontWeight: 900,
      padding: '0',
      margin: 0,
      marginTop: '-4px',
      height: '60px',
      lineHeight: '60px',
      WebkitTextSecurity: 'disc',
      position: 'relative',
      top: '-2px',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      '&::selection': {
        backgroundColor: 'transparent',
      },
      '&::-moz-selection': {
        backgroundColor: 'transparent',
      },
      '&[type="password"]': {
        fontSize: '42px',
        fontWeight: 900,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      }
    },
    '& fieldset': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      '& fieldset': {
        borderColor: '#FFC4CF !important',
      }
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& fieldset': {
        borderWidth: '2px !important',
        borderStyle: 'solid',
        borderColor: '#FD5875 !important',
      }
    },
  },
}));

const PinInput = ({ 
  value = '', 
  onChange,
  onComplete,
  label,
  error,
  autoFocus,
  validatePin: externalValidatePin,
}) => {
  const [internalError, setInternalError] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const inputRefs = useRef([]);

  // Initialize refs if empty
  if (inputRefs.current.length === 0) {
    inputRefs.current = Array(4).fill(null).map(() => React.createRef());
  }

  const handleChange = async (index, newValue) => {
    // Only allow numeric input
    if (!/^\d*$/.test(newValue)) return;

    // Convert the current value to an array for easier manipulation
    const currentDigits = value.split('') || [];
    currentDigits[index] = newValue;
    const newPin = currentDigits.join('');
    
    // Update parent's state
    onChange(newPin);

    // Move focus to next input if value is entered and not on last input
    if (newValue && index < 3) {
      inputRefs.current[index + 1].current?.focus();
    }
  };

  const handleSubmit = async () => {
    if (isValidating || value.length !== 4 || hasSubmitted) return;
    
    setIsValidating(true);
    setHasSubmitted(true);
    setInternalError('');
    
    try {
      if (typeof externalValidatePin === 'function') {
        const result = await externalValidatePin(value);
        if (!result.success) {
          setInternalError(result.error || 'Invalid PIN');
          // Don't reset hasSubmitted on error to prevent auto-resubmission
          // Only allow resubmission after user changes input
        }
      } else if (typeof onComplete === 'function') {
        onComplete(value);
      }
    } catch (err) {
      console.error('Validation error:', err);
      setInternalError('Failed to validate PIN');
      // Don't reset hasSubmitted on error
    } finally {
      setIsValidating(false);
    }
  };

  // Reset hasSubmitted when value changes
  useEffect(() => {
    if (value.length < 4) {
      setHasSubmitted(false);
    }
  }, [value]);

  // Submit when all digits are entered
  useEffect(() => {
    if (value.length === 4 && !isValidating && !hasSubmitted && !internalError) {
      handleSubmit();
    }
  }, [value, isValidating, hasSubmitted, internalError]);

  useEffect(() => {
    if (autoFocus && inputRefs.current[0]?.current) {
      inputRefs.current[0].current?.focus();
    }
  }, [autoFocus]);

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      const currentDigits = value.split('') || [];
      if (!currentDigits[index] && index > 0) {
        currentDigits[index - 1] = '';
        onChange(currentDigits.join(''));
        inputRefs.current[index - 1].current?.focus();
      } else if (currentDigits[index]) {
        currentDigits[index] = '';
        onChange(currentDigits.join(''));
      }
      setInternalError(''); // Clear error on backspace
      setHasSubmitted(false); // Allow resubmission after backspace
    }
  };

  return (
    <Box>
      <PinContainer hasError={!!(error || internalError)}>
        {Array(4).fill(0).map((_, index) => (
          <PinTextField
            key={index}
            inputRef={inputRefs.current[index]}
            type="password"
            value={value[index] || ''}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            inputProps={{
              maxLength: 1,
              pattern: '[0-9]*',
              inputMode: 'numeric',
            }}
          />
        ))}
      </PinContainer>
      {(error || internalError) && (
        <Box sx={{ color: 'error.main', textAlign: 'center', mt: 2 }}>
          {error || internalError}
        </Box>
      )}
    </Box>
  );
};

export default PinInput; 
import React, { useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Skeleton,
  useTheme,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { assignBudgetCategory } from '../services/transactionService';
import { getBudgets } from '../services/budgetService';
import TransactionDetailModal from './TransactionDetailModal';
import { api } from '../services/api';
import Logger from '../utils/logger';

const RenameAccountDialog = ({ open, onClose, accountId, currentName = '', onRename }) => {
  const [newName, setNewName] = useState(currentName || '');

  // Reset the name when the dialog opens with a new account
  React.useEffect(() => {
    if (open && currentName) {
      setNewName(currentName);
    }
  }, [open, currentName]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newName?.trim()) {
      setError('Account name is required');
      return;
    }
    setError('');
    setLoading(true);

    try {
      await api.patch(`/accounts/${accountId}/display-name`, { displayName: newName });
      onRename(accountId, newName);
      onClose();
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to update account name');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Rename Account</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Account Name"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            error={!!error}
            helperText={error}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>Cancel</Button>
          <Button 
            type="submit" 
            variant="contained" 
            disabled={loading || !newName?.trim()}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const TransactionList = React.forwardRef(({ 
  transactions = [], 
  loading = false, 
  error = null, 
  onTransactionUpdate
}, ref) => {
  const theme = useTheme();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleTransactionClick = (transaction) => {
    Logger.debug('Transaction clicked', { transactionId: transaction.plaidTransactionId });
    setSelectedTransaction(transaction);
    setDetailModalOpen(true);
  };

  const handleDetailModalClose = () => {
    Logger.debug('Transaction detail modal closed');
    setDetailModalOpen(false);
    setSelectedTransaction(null);
  };

  const handleRenameClick = (e, transaction) => {
    e.stopPropagation();
    Logger.debug('Account rename initiated', { 
      accountId: transaction.plaidAccountId,
      currentName: transaction.accountName 
    });
    setSelectedAccount({
      id: transaction.plaidAccountId,
      name: transaction.accountName
    });
    setRenameDialogOpen(true);
  };

  const handleRenameComplete = (accountId, newName) => {
    Logger.debug('Account rename completed', { accountId, newName });
    transactions.forEach(t => {
      if (t.plaidAccountId === accountId) {
        t.accountName = newName;
      }
    });
  };

  const formatAmount = (amount, isoCurrencyCode = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: isoCurrencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Math.abs(amount));
  };

  const formatDate = (date) => {
    const today = new Date();
    const txDate = new Date(date);
    
    if (txDate.toDateString() === today.toDateString()) {
      return 'Today';
    }
    
    // If it's this week, show the day name
    const diffTime = Math.abs(today - txDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays <= 7) {
      return txDate.toLocaleDateString('en-US', { weekday: 'long' });
    }
    
    // If it's Dec 1 or later dates, just show "Dec 1"
    return txDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  if (loading) {
    return (
      <List disablePadding>
        {[1, 2, 3].map((i) => (
          <ListItem key={i} divider>
            <ListItemAvatar>
              <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
              primary={<Skeleton width="60%" />}
              secondary={<Skeleton width="40%" />}
            />
            <Box>
              <Skeleton width={80} />
            </Box>
          </ListItem>
        ))}
      </List>
    );
  }

  if (error) {
    return (
      <Box p={2} textAlign="center">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!transactions.length) {
    return (
      <Box p={2} textAlign="center">
        <Typography color="textSecondary">
          No transactions found
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <List disablePadding>
        {transactions.map((transaction) => (
          <ListItem
            key={transaction.plaidTransactionId}
            onClick={() => handleTransactionClick(transaction)}
            sx={{
              py: 2,
              px: 3,
              '&:hover': {
                bgcolor: 'action.hover',
                cursor: 'pointer'
              },
              display: 'grid',
              gridTemplateColumns: '1fr auto auto',
              gap: 2,
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {transaction.logoUrl ? (
                  <Avatar
                    src={transaction.logoUrl}
                    alt={transaction.merchantName}
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: theme.palette.grey[100],
                      '& img': {
                        objectFit: 'contain',
                        p: 1
                      }
                    }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: theme.palette.grey[100],
                      color: theme.palette.grey[600],
                      fontSize: '1.2rem'
                    }}
                  >
                    {(transaction.merchantName || transaction.description || '?').charAt(0).toUpperCase()}
                  </Avatar>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body1">
                      {transaction.merchantName || transaction.description}
                    </Typography>
                    {transaction.budgetCategory?.name && (
                      <Chip
                        label={transaction.budgetCategory.name}
                        size="small"
                        sx={{
                          bgcolor: `${theme.palette.primary.main}15`,
                          color: 'primary.main',
                          fontWeight: 500,
                          '& .MuiChip-label': {
                            px: 1
                          }
                        }}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {transaction.account && (
                      <Typography variant="caption" color="text.secondary" sx={{ opacity: 0.8 }}>
                        {transaction.account.displayName || transaction.account.name}
                        {transaction.account.mask ? ` •••• ${transaction.account.mask}` : ''}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ width: 100, textAlign: 'right' }}>
              {formatDate(transaction.date)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: 100,
                textAlign: 'right',
                fontWeight: 500,
                color: transaction.amount > 0 ? 'error.main' : 'success.main'
              }}
            >
              {transaction.amount > 0 ? '-' : '+'}{formatAmount(transaction.amount, transaction.isoCurrencyCode)}
            </Typography>
          </ListItem>
        ))}
      </List>

      <TransactionDetailModal
        open={detailModalOpen}
        onClose={handleDetailModalClose}
        transaction={selectedTransaction}
        onTransactionUpdate={onTransactionUpdate}
      />

      <RenameAccountDialog
        open={renameDialogOpen}
        onClose={() => {
          setRenameDialogOpen(false);
          setSelectedAccount(null);
        }}
        accountId={selectedAccount?.id}
        currentName={selectedAccount?.name}
        onRename={handleRenameComplete}
      />
    </>
  );
});

export default TransactionList; 
import React, { useState } from 'react';
import {
  Box,
  Menu,
  Tooltip
} from '@mui/material';
import { StyledIconButton, StyledIconGrid } from './common/StyledInputs';
import {
  Home as HomeIcon,
  DirectionsCar as CarIcon,
  Restaurant as FoodIcon,
  LocalHospital as HealthIcon,
  ShoppingCart as ShoppingCartIcon,
  School as EducationIcon,
  Theaters as EntertainmentIcon,
  Power as UtilitiesIcon,
  Savings as SavingsIcon,
  Category as OtherIcon,
  LocalLaundryService as LaundryIcon,
  Pets as PetsIcon,
  Phone as PhoneIcon,
  CreditCard as CreditCardIcon,
  ChildCare as ChildCareIcon,
  FitnessCenter as GymIcon,
  LocalParking as ParkingIcon,
  Commute as CommuteIcon,
  LocalGroceryStore as GroceryIcon,
  LocalCafe as CoffeeIcon,
  Wifi as InternetIcon,
  Celebration as GiftsIcon,
  BeachAccess as VacationIcon,
  LocalLibrary as BooksIcon,
  Brush as BeautyIcon,
  Construction as MaintenanceIcon,
  Medication as MedicationIcon,
  AccountBalance as InvestmentIcon,
  Policy as InsuranceIcon,
  SportsEsports as GamingIcon,
  VolunteerActivism as CharityIcon
} from '@mui/icons-material';

const ICONS = {
  home: HomeIcon,
  car: CarIcon,
  food: FoodIcon,
  health: HealthIcon,
  shopping: ShoppingCartIcon,
  education: EducationIcon,
  entertainment: EntertainmentIcon,
  utilities: UtilitiesIcon,
  savings: SavingsIcon,
  other: OtherIcon,
  laundry: LaundryIcon,
  pets: PetsIcon,
  phone: PhoneIcon,
  creditCard: CreditCardIcon,
  childCare: ChildCareIcon,
  gym: GymIcon,
  parking: ParkingIcon,
  commute: CommuteIcon,
  grocery: GroceryIcon,
  coffee: CoffeeIcon,
  internet: InternetIcon,
  gifts: GiftsIcon,
  vacation: VacationIcon,
  books: BooksIcon,
  beauty: BeautyIcon,
  maintenance: MaintenanceIcon,
  medication: MedicationIcon,
  investment: InvestmentIcon,
  insurance: InsuranceIcon,
  gaming: GamingIcon,
  charity: CharityIcon
};

const IconSelector = ({ selectedIcon, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const IconComponent = ICONS[selectedIcon] || ICONS.other;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconSelect = (iconKey) => {
    onSelect(iconKey);
    handleClose();
  };

  return (
    <>
      <StyledIconButton onClick={handleClick}>
        <IconComponent />
      </StyledIconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            borderRadius: '12px',
            backgroundColor: 'background.paper',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <StyledIconGrid>
          {Object.entries(ICONS).map(([key, Icon]) => (
            <Tooltip key={key} title={key.charAt(0).toUpperCase() + key.slice(1)}>
              <StyledIconButton
                onClick={() => handleIconSelect(key)}
              >
                <Icon />
              </StyledIconButton>
            </Tooltip>
          ))}
        </StyledIconGrid>
      </Menu>
    </>
  );
};

export default IconSelector; 
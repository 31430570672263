import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 500
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.grey[100],
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 0.7
    }
  }
}));

const BitcoinAddressModal = ({ open, onClose, onSave }) => {
  const [address, setAddress] = useState('');

  const handleSave = () => {
    onSave(address);
    setAddress('');
    onClose();
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.75rem', mt: 1 }}>
          Add Bitcoin Address
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0, mb: 2 }}>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
          Enter a Bitcoin address to track its balance and transactions.
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography 
            variant="body1" 
            sx={{ 
              mb: 1,
              fontSize: '1.1rem',
              fontWeight: 500,
              color: 'text.secondary'
            }}
          >
            Bitcoin Address
          </Typography>
          <StyledTextField
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter Bitcoin address"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ 
        p: 0, 
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSave}
          disabled={!address}
          sx={{
            borderRadius: '9999px',
            py: 1.5,
            textTransform: 'none',
            fontWeight: 1000,
            fontSize: '1.1rem'
          }}
        >
          Add Address
        </Button>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ 
            fontSize: '0.9rem', 
            mt: 2, 
            fontStyle: 'italic',
            textAlign: 'center',
            width: '100%'
          }}
        >
          Powered by Blockchain.com
        </Typography>
      </DialogActions>
    </StyledDialog>
  );
};

export default BitcoinAddressModal; 
import api from './api';
import Logger from '../utils/logger';

export const getTransactions = async (params) => {
  try {
    // Log the incoming params
    Logger.debug('getTransactions params:', {
      accountIds: params.accountIds,
      institutionIds: params.institutionIds
    });

    // Clean up undefined and empty values
    const cleanParams = { ...params };
    Object.keys(cleanParams).forEach(key => {
      if (cleanParams[key] === undefined || cleanParams[key] === '' || 
          (Array.isArray(cleanParams[key]) && cleanParams[key].length === 0)) {
        delete cleanParams[key];
      }
    });

    // Convert arrays to comma-separated strings
    if (Array.isArray(cleanParams.accountIds)) {
      cleanParams.accountIds = cleanParams.accountIds.join(',');
    }
    if (Array.isArray(cleanParams.institutionIds)) {
      cleanParams.institutionIds = cleanParams.institutionIds.join(',');
    }

    // Log the cleaned params
    Logger.debug('Cleaned params:', {
      accountIds: cleanParams.accountIds,
      institutionIds: cleanParams.institutionIds
    });

    const response = await api.get('/transactions', { params: cleanParams });
    return response.data;
  } catch (error) {
    Logger.error('Failed to fetch transactions', error);
    throw error;
  }
};

export const getTransactionsByAccount = async (accountId) => {
  try {
    const response = await api.get(`/transactions/${accountId}`);
    return response.data;
  } catch (error) {
    Logger.error('Failed to fetch account transactions', error);
    throw error;
  }
};

export const syncTransactions = async (accountId, fullSync = false) => {
  try {
    const response = await api.post(`/transactions/${accountId}/sync${fullSync ? '?fullSync=true' : ''}`);
    return response.data;
  } catch (error) {
    Logger.error('Transaction sync failed', error);
    throw error;
  }
};

export const startFullHistoricalSync = async () => {
  try {
    const response = await api.post('/transactions/sync/historical');
    return response.data;
  } catch (error) {
    Logger.error('Historical sync failed to start', error);
    throw error;
  }
};

export const assignBudgetCategory = async (transactionId, budgetId, categoryId) => {
  try {
    const response = await api.post(`/transactions/${transactionId}/category`, {
      budgetId,
      categoryId
    });
    return response;
  } catch (error) {
    Logger.error('Failed to assign budget category', error);
    throw error;
  }
};

export const categorizeSimilarTransactions = async (transactionId, budgetId, categoryId) => {
  try {
    const response = await api.post(`/transactions/${transactionId}/categorize-similar`, {
      budgetId,
      categoryId
    });
    return response.data;
  } catch (error) {
    Logger.error('Failed to categorize similar transactions', error);
    throw error;
  }
};

export const getConnectedAccounts = async () => {
  try {
    const response = await api.get('/plaid/accounts');
    return Array.isArray(response.data) ? response.data : response.data?.accounts || [];
  } catch (error) {
    Logger.error('Failed to fetch connected accounts', error);
    throw error;
  }
}; 
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  LinearProgress
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { colors } from '../styles';
import { format as formatDate } from 'date-fns';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiTableCell-root': {
    borderColor: `${colors.primary}10`,
    padding: theme.spacing(2),
    '&:first-of-type': {
      paddingLeft: theme.spacing(3)
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(3)
    }
  },
  '& .MuiTableCell-head': {
    backgroundColor: '#fff',
    fontWeight: 600,
    color: 'text.secondary',
    fontSize: '0.875rem',
    borderBottom: `2px solid ${colors.primary}20`
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: `${colors.primary}05`,
  }
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: `${colors.primary}20`,
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
    backgroundColor: props => 
      props.value > 100 ? colors.status.error : colors.primary
  }
}));

const BudgetCategoryDetailsModal = ({ 
  open, 
  onClose, 
  category, 
  budget, 
  insights, 
  selectedMonth,
  budgets
}) => {
  if (!category || !insights) return null;

  // Get the correct budget if not provided directly
  const currentBudget = budget || budgets?.find(b => b._id === category.budgetId);
  if (!currentBudget) return null;

  // Get insights for the current budget
  const budgetInsights = insights.budgetInsights?.[currentBudget._id];
  if (!budgetInsights) return null;

  const categorySpent = budgetInsights.categorySpent?.[category._id] || 0;
  const budgetAmount = category.isPercentage 
    ? (Number(category.amount) / 100) * Number(currentBudget.totalBudget)
    : Number(category.amount);
  const spentPercentage = (categorySpent / budgetAmount) * 100;
  const remaining = budgetAmount - categorySpent;
  const transactions = budgetInsights.transactions?.filter(t => 
    t.budgetCategory?.id === category._id
  ) || [];

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '20px',
          backgroundColor: '#fff',
          width: '800px'
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 700, mb: 0.5 }}>
            {category.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {currentBudget.name} • {selectedMonth ? formatDate(new Date(`${selectedMonth}-15`), 'MMMM yyyy') : 'Current Month'}
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: `${colors.primary}10`
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider />
      
      <DialogContent sx={{ pt: 3 }}>
        {/* Budget Progress Section */}
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color="text.secondary">
              ${categorySpent.toLocaleString()} spent of ${budgetAmount.toLocaleString()} budget
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: spentPercentage > 100 ? colors.status.error : colors.primary,
                fontWeight: 500
              }}
            >
              {spentPercentage.toFixed(1)}%
            </Typography>
          </Box>
          <ProgressBar 
            variant="determinate" 
            value={Math.min(spentPercentage, 100)} 
          />
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            mt: 2,
            mb: 3
          }}>
            <Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Remaining
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 600,
                  color: remaining < 0 ? colors.status.error : colors.status.ready
                }}
              >
                ${Math.abs(remaining).toLocaleString()}
                {remaining < 0 && ' over'}
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Daily Average
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                ${(categorySpent / 30).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Transactions Section */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Recent Transactions
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {transactions.length} {transactions.length === 1 ? 'transaction' : 'transactions'}
          </Typography>
        </Box>
        <StyledTableContainer 
          component={Paper} 
          sx={{ 
            maxHeight: 400,
            boxShadow: 'none',
            border: `1px solid ${colors.primary}10`,
            borderRadius: '12px',
            backgroundColor: 'transparent',
            width: '100%',
            overflowX: 'hidden'
          }}
        >
          <Table stickyHeader size="medium" sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell width="100px">Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell width="150px">Merchant</TableCell>
                <TableCell width="100px" align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow 
                  key={transaction._id}
                  sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 },
                    transition: 'background-color 0.2s ease'
                  }}
                >
                  <TableCell>
                    <Typography variant="body2">
                      {formatDate(new Date(transaction.date), 'MMM d')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap title={transaction.description}>
                      {transaction.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap color="text.secondary">
                      {transaction.merchantName}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body2"
                      sx={{
                        color: transaction.amount < 0 ? colors.status.error : colors.status.ready,
                        fontWeight: 600,
                        whiteSpace: 'nowrap'
                      }}
                    >
                      ${Math.abs(transaction.amount).toLocaleString()}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              {transactions.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center" sx={{ py: 8 }}>
                    <Typography variant="body2" color="text.secondary">
                      No transactions for this period
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default BudgetCategoryDetailsModal; 
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import * as subscriptionService from '../services/subscriptionService';
import PricingModal from './PricingModal';

const SubscriptionSection = () => {
  const { subscription, loadSubscription, subscriptionLoading } = useAuth();
  const [error, setError] = useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const navigate = useNavigate();

  const handleUpgrade = async (priceId) => {
    try {
      setProcessing(true);
      const { sessionUrl } = await subscriptionService.createCheckoutSession(priceId);
      window.location.href = sessionUrl;
    } catch (err) {
      setError('Failed to start checkout process');
    } finally {
      setProcessing(false);
    }
  };

  const handleCancel = async () => {
    if (!subscription?.id) {
      console.log('SubscriptionSection: No subscription ID found:', subscription);
      setError('No active subscription to cancel');
      setCancelDialogOpen(false);
      return;
    }

    try {
      setProcessing(true);
      setError(null); // Clear any previous errors
      console.log('SubscriptionSection: Starting cancellation for ID:', subscription.id);
      
      const result = await subscriptionService.cancelSubscription();
      console.log('SubscriptionSection: Cancellation API response:', result);
      
      if (result.success) {
        // Only close the modal after successful cancellation
        setCancelDialogOpen(false);
        
        // Refresh subscription data
        await loadSubscription(true);
      } else {
        throw new Error(result.error || 'Failed to cancel subscription');
      }
    } catch (err) {
      console.error('SubscriptionSection: Cancellation request failed:', err);
      setError(err.message || 'Failed to cancel subscription. Please try again.');
      // Keep the modal open on error
    } finally {
      setProcessing(false);
    }
  };

  if (subscriptionLoading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* Subscription Section */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ 
          p: 3, 
          bgcolor: 'background.paper', 
          borderRadius: 2,
          border: 1,
          borderColor: 'divider'
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            gap: 2
          }}>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {subscription && subscription.status !== 'canceled' ? 'Premium Plan' : 'Free Plan'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {subscription?.status === 'canceled' 
                  ? 'Your subscription will end at the end of the billing period'
                  : subscription 
                    ? `Next billing date: ${subscription.currentPeriodEnd 
                        ? new Date(subscription.currentPeriodEnd instanceof Date 
                            ? subscription.currentPeriodEnd 
                            : subscription.currentPeriodEnd).toLocaleDateString()
                        : 'Not available'}`
                    : 'Upgrade to access premium features'
                }
              </Typography>
            </Box>
            {subscription?.status !== 'canceled' ? (
              <Box sx={{ 
                display: 'flex', 
                gap: 1,
                flexDirection: 'column',
                minWidth: '140px'
              }}>
                <Button
                  variant="outlined"
                  onClick={() => window.location.href = subscription.portalUrl}
                  disabled={processing}
                  size="small"
                  fullWidth
                >
                  Manage
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setCancelDialogOpen(true)}
                  disabled={processing}
                  size="small"
                  fullWidth
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <Box sx={{ minWidth: '140px' }}>
                <Button
                  variant="contained"
                  onClick={() => setShowPricingModal(true)}
                  disabled={processing}
                  size="small"
                  fullWidth
                >
                  View Plans
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Cancel Subscription Dialog */}
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            width: '100%',
            maxWidth: '400px',
            p: 2
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          pt: 0,
          px: 0,
          typography: 'h6',
          fontWeight: 'medium'
        }}>
          Cancel Subscription?
        </DialogTitle>
        <DialogContent sx={{ p: 0, mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Are you sure you want to cancel your subscription? You'll lose access to premium features at the end of your current billing period.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ 
          p: 0,
          gap: 1,
          '& > button': { flex: 1 }
        }}>
          <Button 
            onClick={handleCancel} 
            variant="outlined"
            disabled={processing}
            fullWidth
          >
            Confirm
          </Button>
          <Button 
            onClick={() => setCancelDialogOpen(false)} 
            variant="contained"
            disabled={processing}
            fullWidth
          >
            Keep
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pricing Modal */}
      <PricingModal
        open={showPricingModal}
        onClose={() => setShowPricingModal(false)}
        onUpgrade={handleUpgrade}
      />
    </Box>
  );
};

export default SubscriptionSection; 
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Divider,
  CircularProgress,
  IconButton,
  InputBase,
  Chip,
  Avatar,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  Search as SearchIcon,
  BugReport as BugIcon,
  Lightbulb as FeatureIcon,
  TuneRounded as FilterIcon,
  Close as CloseIcon,
  Send as SendIcon
} from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { githubService, issueTemplates, capturePageContext } from '../services/githubService';
import Layout from '../components/Layout';
import { useError } from '../contexts/ErrorContext';
import { colors } from '../styles';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '12px',
  backgroundColor: alpha(theme.palette.common.black, 0.04),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.06),
  },
  width: '100%',
  marginBottom: theme.spacing(3),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: alpha(theme.palette.common.black, 0.4),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(2),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
  },
}));

const StyledSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '20px',
  backgroundColor: '#ffffff',
  marginBottom: theme.spacing(3),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
}));

const FloatingMenu = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(4),
  backgroundColor: '#ffffff',
  width: `calc(100% - ${theme.spacing(4)})`,
  marginRight: theme.spacing(4),
  alignSelf: 'flex-start',
  boxShadow: 'none',
  borderRadius: '20px',
  padding: theme.spacing(2)
}));

const MenuLink = styled(ListItem)(({ theme, active }) => ({
  borderRadius: '0.5rem',
  marginBottom: theme.spacing(0.5),
  cursor: 'pointer',
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}20`,
  },
  ...(active && {
    backgroundColor: `${theme.palette.primary.main}20`,
  }),
  '& .MuiTypography-root': {
    fontWeight: 900,
    fontSize: '0.95rem'
  }
}));

const IssueCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: theme.spacing(2),
  alignItems: 'start',
  cursor: 'pointer',
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const StatusChip = styled(Chip)(({ status, theme }) => ({
  backgroundColor: status === 'open' ? `${colors.success}15` : `${colors.secondary}15`,
  color: status === 'open' ? colors.success : colors.secondary,
  borderRadius: '6px',
  height: '24px',
  '& .MuiChip-label': {
    padding: '0 8px',
  },
}));

const formatDate = (date) => {
  const today = new Date();
  const issueDate = new Date(date);
  
  if (issueDate.toDateString() === today.toDateString()) {
    return 'Today';
  }
  
  // If it's this week, show the day name
  const diffTime = Math.abs(today - issueDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  if (diffDays <= 7) {
    return issueDate.toLocaleDateString('en-US', { weekday: 'long' });
  }
  
  // If it's Dec 1 or later dates, just show "Dec 1"
  return issueDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 800,
    maxHeight: 'calc(100vh - 128px)',
    margin: theme.spacing(4, 2),
    display: 'flex',
    flexDirection: 'column'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.grey[100],
    transition: theme.transitions.create(['background-color', 'border-color', 'border-width']),
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    },
    '&:hover': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary,
        borderWidth: 1
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent',
      transition: theme.transitions.create(['border-color', 'border-width'])
    }
  },
  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 0.7
    }
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '9999px',
  textTransform: 'none',
  padding: '12px 24px',
  fontWeight: 600,
  fontSize: '1.1rem'
}));

const CommentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  backgroundColor: theme.palette.grey[100],
  marginBottom: theme.spacing(2)
}));

const Support = () => {
  const [activeSection, setActiveSection] = useState('all');
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [issueType, setIssueType] = useState(null);
  const [newIssue, setNewIssue] = useState({
    title: '',
    description: '',
    stepsToReproduce: '',
    expectedBehavior: '',
    actualBehavior: '',
    problemToSolve: '',
    proposedSolution: '',
    additionalContext: '',
    images: []
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showError, showSuccess } = useError();
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [loadingComments, setLoadingComments] = useState(false);

  const menuItems = [
    { id: 'all', label: 'All Issues' },
    { id: 'bugs', label: 'Bugs' },
    { id: 'features', label: 'Features' }
  ];

  useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = async () => {
    try {
      const fetchedIssues = await githubService.getIssues();
      setIssues(fetchedIssues);
    } catch (error) {
      showError('Failed to fetch issues');
    } finally {
      setLoading(false);
    }
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convert to JPEG with 0.8 quality
          const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.8);
          resolve(compressedDataUrl);
        };
      };
    });
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    if (!files.length) return;

    try {
      // Compress and convert each image to base64
      const compressedImages = await Promise.all(
        files.map(async (file) => ({
          name: file.name,
          data: await compressImage(file)
        }))
      );

      setNewIssue(prev => ({
        ...prev,
        images: compressedImages
      }));
    } catch (error) {
      console.error('Error processing images:', error);
      showError('Failed to process images');
    }
  };

  const handleCreateIssue = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      
      // Get cached context
      const pageContext = JSON.parse(sessionStorage.getItem('pageContext') || '{}');
      
      // Prepare issue body based on type
      const template = issueType === 'bug' ? issueTemplates.bug : issueTemplates.feature;
      
      // Replace placeholders in template with actual values
      let body = template
        .replace('[browser]', pageContext.userAgent || 'Not available')
        .replace('[os]', navigator.platform || 'Not available')
        .replace('[version]', process.env.REACT_APP_VERSION || 'Not available')
        .replace('[url]', pageContext.url || 'Not available');

      // Add all fields to appropriate sections
      if (issueType === 'bug') {
        body = body
          .replace('[A clear and concise description of the bug]', newIssue.description || '')
          .replace('[First Step]', newIssue.stepsToReproduce || '')
          .replace('[What you expected to happen]', newIssue.expectedBehavior || '')
          .replace('[What actually happened]', newIssue.actualBehavior || '')
          .replace('[Any other context about the problem]', newIssue.additionalContext || '');
      } else {
        body = body
          .replace('[A clear and concise description of the feature you\'d like]', newIssue.description || '')
          .replace('[What problem would this feature solve?]', newIssue.problemToSolve || '')
          .replace('[Describe how you envision this feature working]', newIssue.proposedSolution || '')
          .replace('[Add any other context about the feature request here]', newIssue.additionalContext || '');
      }

      await githubService.createIssue({
        title: newIssue.title,
        body,
        labels: [issueType],
        images: newIssue.images
      });

      showSuccess('Issue created successfully');
      
      // Clear form and cached context
      setNewIssue({
        title: '',
        description: '',
        stepsToReproduce: '',
        expectedBehavior: '',
        actualBehavior: '',
        problemToSolve: '',
        proposedSolution: '',
        additionalContext: '',
        images: []
      });
      sessionStorage.removeItem('pageContext');
      
      // Close modal and refresh issues
      setIsModalOpen(false);
      fetchIssues();
      
    } catch (error) {
      showError('Failed to create issue');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (type) => {
    setIssueType(type);
    setNewIssue({
      title: '',
      description: '',
      stepsToReproduce: '',
      expectedBehavior: '',
      actualBehavior: '',
      problemToSolve: '',
      proposedSolution: '',
      additionalContext: '',
      images: []
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewIssue({
      title: '',
      description: '',
      stepsToReproduce: '',
      expectedBehavior: '',
      actualBehavior: '',
      problemToSolve: '',
      proposedSolution: '',
      additionalContext: '',
      images: []
    });
  };

  const handleStepsKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      const currentSteps = newIssue.stepsToReproduce;
      const lines = currentSteps.split('\n').filter(line => line.trim());
      const nextNumber = lines.length + 1;
      
      // If we're on an empty numbered line, don't add another
      const lastLine = lines[lines.length - 1] || '';
      if (lastLine.trim() === `${lines.length}.`) {
        return;
      }

      // Add new numbered line
      const newSteps = currentSteps + (currentSteps && !currentSteps.endsWith('\n') ? '\n' : '') + `${nextNumber}. `;
      setNewIssue({ ...newIssue, stepsToReproduce: newSteps });
    }
  };

  const handleStepsFocus = (e) => {
    if (!newIssue.stepsToReproduce) {
      setNewIssue({ ...newIssue, stepsToReproduce: '1. ' });
    }
  };

  const handleViewIssue = async (issue) => {
    try {
      setLoadingComments(true);
      const issueDetails = await githubService.getIssueDetails(issue.number);
      setSelectedIssue(issueDetails);
      setIsViewModalOpen(true);
    } catch (error) {
      showError('Failed to load issue details');
    } finally {
      setLoadingComments(false);
    }
  };

  const handleCloseViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedIssue(null);
    setComment('');
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    try {
      setLoadingComments(true);
      await githubService.addComment(selectedIssue.number, comment);
      const updatedIssue = await githubService.getIssueDetails(selectedIssue.number);
      setSelectedIssue(updatedIssue);
      setComment('');
    } catch (error) {
      showError('Failed to add comment');
    } finally {
      setLoadingComments(false);
    }
  };

  const filteredIssues = issues.filter(issue => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch = issue.title.toLowerCase().includes(searchLower) ||
      issue.body.toLowerCase().includes(searchLower);
    
    // Filter based on active section
    if (activeSection === 'bugs') {
      return matchesSearch && issue.labels.includes('bug');
    } else if (activeSection === 'features') {
      return matchesSearch && issue.labels.includes('feature');
    } else if (activeSection === 'all') {
      return matchesSearch;
    }
    return matchesSearch;
  }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ px: { xs: 2, md: 4 } }}>
        <Box py={4}>
          <Grid container spacing={4}>
            {/* Main Content */}
            <Grid item xs={12} md={9}>
              {activeSection === 'bugs' || activeSection === 'features' || activeSection === 'all' ? (
                <StyledSection>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {activeSection === 'bugs' ? 'Bugs' : 
                       activeSection === 'features' ? 'Features' : 
                       'All Issues'}
                    </Typography>
                    <Box sx={{ flex: 1 }} />
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      {(activeSection === 'all' || activeSection === 'bugs') && (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<BugIcon />}
                          onClick={() => handleOpenModal('bug')}
                          sx={{
                            borderRadius: '50px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            borderColor: colors.primary,
                            color: colors.primary,
                            '&:hover': {
                              borderColor: colors.primary,
                              backgroundColor: colors.primary + '10'
                            }
                          }}
                        >
                          Report Bug
                        </Button>
                      )}
                      {(activeSection === 'all' || activeSection === 'features') && (
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<FeatureIcon />}
                          onClick={() => handleOpenModal('feature')}
                          sx={{
                            borderRadius: '50px',
                            textTransform: 'none',
                            px: 3,
                            py: 1,
                            backgroundColor: colors.primary,
                            color: 'white',
                            '&:hover': {
                              backgroundColor: colors.primary,
                              opacity: 0.9
                            }
                          }}
                        >
                          Request Feature
                        </Button>
                      )}
                    </Box>
                  </Box>

                  <Paper
                    elevation={0}
                    sx={{
                      p: '6px 16px',
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: 'action.hover',
                      borderRadius: '9999px',
                      boxShadow: 'none',
                      mb: 3,
                      '& .MuiInputBase-root': {
                        '&.Mui-focused': {
                          '& > input': {
                            caretColor: 'primary.main'
                          }
                        }
                      },
                      '&:focus-within': {
                        outline: '2px solid',
                        outlineColor: 'primary.main',
                        bgcolor: 'background.paper'
                      }
                    }}
                  >
                    <IconButton sx={{ p: '10px' }}>
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ 
                        flex: 1,
                        '& .MuiInputBase-input': {
                          '&::placeholder': {
                            color: 'text.secondary',
                            opacity: 1
                          }
                        }
                      }}
                      placeholder="Search issues..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Paper>

                  {loading ? (
                    <List disablePadding>
                      {[1, 2, 3].map((i) => (
                        <ListItem key={i} divider>
                          <ListItemAvatar>
                            <CircularProgress size={40} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={<Box sx={{ width: '60%', height: 20, bgcolor: 'grey.200' }} />}
                            secondary={<Box sx={{ width: '40%', height: 16, bgcolor: 'grey.100' }} />}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <List disablePadding>
                      {filteredIssues.map(issue => (
                        <ListItem
                          key={issue.id}
                          onClick={() => handleViewIssue(issue)}
                          sx={{
                            py: 2,
                            px: 3,
                            '&:hover': {
                              bgcolor: 'action.hover',
                              cursor: 'pointer'
                            },
                            display: 'grid',
                            gridTemplateColumns: '1fr auto',
                            gap: 2,
                            alignItems: 'center',
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar sx={{ 
                              bgcolor: issue.labels.includes('bug') ? `${colors.error}15` : `${colors.primary}15`,
                              color: issue.labels.includes('bug') ? colors.error : colors.primary 
                            }}>
                              {issue.labels.includes('bug') ? <BugIcon /> : <FeatureIcon />}
                            </Avatar>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                  {issue.title}
                                </Typography>
                                <Chip
                                  label={issue.state.charAt(0).toUpperCase() + issue.state.slice(1)}
                                  size="small"
                                  sx={(theme) => ({
                                    bgcolor: `${theme.palette.primary.main}15`,
                                    color: theme.palette.primary.main,
                                    fontWeight: 500,
                                    '& .MuiChip-label': {
                                      px: 1
                                    }
                                  })}
                                />
                              </Box>
                              <Typography variant="body2" color="text.secondary">
                                {issue.body.slice(0, 100)}...
                              </Typography>
                            </Box>
                          </Box>
                          <Typography variant="body2" color="text.secondary" sx={{ alignSelf: 'center', width: 100, textAlign: 'right' }}>
                            {formatDate(issue.created_at)}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </StyledSection>
              ) : (
                <StyledSection>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 3 }}>
                    Report New Issue
                  </Typography>

                  <form onSubmit={handleCreateIssue}>
                    <FormControl component="fieldset" sx={{ mb: 3 }}>
                      <FormLabel component="legend">Issue Type</FormLabel>
                      <RadioGroup
                        row
                        value={issueType}
                        onChange={(e) => setIssueType(e.target.value)}
                      >
                        <FormControlLabel value="bug" control={<Radio />} label="Bug Report" />
                        <FormControlLabel value="feature" control={<Radio />} label="Feature Request" />
                      </RadioGroup>
                    </FormControl>

                    <TextField
                      fullWidth
                      label="Title"
                      required
                      value={newIssue.title}
                      onChange={(e) => setNewIssue({ ...newIssue, title: e.target.value })}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      label="Description"
                      required
                      multiline
                      rows={6}
                      value={newIssue.description}
                      onChange={(e) => setNewIssue({ ...newIssue, description: e.target.value })}
                      sx={{ mb: 3 }}
                      placeholder={issueType === 'bug' ? 
                        "Please describe what happened and how to reproduce it" :
                        "Please describe the feature you'd like to see"
                      }
                    />

                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Submit Issue'}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setActiveSection('bugs')}
                        disabled={loading}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </StyledSection>
              )}
            </Grid>

            {/* Right Side Menu */}
            <Grid item xs={12} md={3}>
              <FloatingMenu>
                <List>
                  {menuItems.map((item) => (
                    <MenuLink
                      key={item.id}
                      active={activeSection === item.id}
                      onClick={() => setActiveSection(item.id)}
                      disableRipple
                    >
                      <ListItemText primary={item.label} />
                    </MenuLink>
                  ))}
                </List>
              </FloatingMenu>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Issue Creation Modal */}
      <StyledDialog 
        open={isModalOpen} 
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ p: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleCloseModal} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.75rem', mt: 1 }}>
            {issueType === 'bug' ? 'Report Bug' : 'Request Feature'}
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateIssue} style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
          <DialogContent sx={{ p: 0, pt: 2, pb: 4, overflow: 'auto' }}>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
              {issueType === 'bug' 
                ? "Help us improve by reporting any bugs you encounter. Please provide as much detail as possible."
                : "Share your ideas for new features. We'd love to hear how we can make the app better for you."
              }
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mb: 1,
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    color: 'text.secondary'
                  }}
                >
                  Title
                </Typography>
                <StyledTextField
                  fullWidth
                  required
                  value={newIssue.title}
                  onChange={(e) => setNewIssue({ ...newIssue, title: e.target.value })}
                  placeholder="Enter a descriptive title"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mb: 1,
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    color: 'text.secondary'
                  }}
                >
                  Description
                </Typography>
                <StyledTextField
                  fullWidth
                  required
                  multiline
                  rows={4}
                  value={newIssue.description}
                  onChange={(e) => setNewIssue({ ...newIssue, description: e.target.value })}
                  placeholder={issueType === 'bug' ? 
                    "Please provide a clear and concise description of the bug" :
                    "Please provide a clear and concise description of the feature"
                  }
                />
              </Grid>

              {issueType === 'bug' ? (
                <>
                  <Grid item xs={12}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 1,
                        fontSize: '1.1rem',
                        fontWeight: 500,
                        color: 'text.secondary'
                      }}
                    >
                      Steps to Reproduce
                    </Typography>
                    <StyledTextField
                      fullWidth
                      required
                      multiline
                      rows={3}
                      value={newIssue.stepsToReproduce}
                      onChange={(e) => setNewIssue({ ...newIssue, stepsToReproduce: e.target.value })}
                      onKeyDown={handleStepsKeyDown}
                      onFocus={handleStepsFocus}
                      placeholder="1. First step&#10;2. Second step&#10;3. And so on..."
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 1,
                        fontSize: '1.1rem',
                        fontWeight: 500,
                        color: 'text.secondary'
                      }}
                    >
                      Expected Behavior
                    </Typography>
                    <StyledTextField
                      fullWidth
                      required
                      multiline
                      rows={2}
                      value={newIssue.expectedBehavior}
                      onChange={(e) => setNewIssue({ ...newIssue, expectedBehavior: e.target.value })}
                      placeholder="What did you expect to happen?"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 1,
                        fontSize: '1.1rem',
                        fontWeight: 500,
                        color: 'text.secondary'
                      }}
                    >
                      Actual Behavior
                    </Typography>
                    <StyledTextField
                      fullWidth
                      required
                      multiline
                      rows={2}
                      value={newIssue.actualBehavior}
                      onChange={(e) => setNewIssue({ ...newIssue, actualBehavior: e.target.value })}
                      placeholder="What actually happened?"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 1,
                        fontSize: '1.1rem',
                        fontWeight: 500,
                        color: 'text.secondary'
                      }}
                    >
                      Problem to Solve
                    </Typography>
                    <StyledTextField
                      fullWidth
                      required
                      multiline
                      rows={3}
                      value={newIssue.problemToSolve}
                      onChange={(e) => setNewIssue({ ...newIssue, problemToSolve: e.target.value })}
                      placeholder="What problem would this feature solve?"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 1,
                        fontSize: '1.1rem',
                        fontWeight: 500,
                        color: 'text.secondary'
                      }}
                    >
                      Proposed Solution
                    </Typography>
                    <StyledTextField
                      fullWidth
                      required
                      multiline
                      rows={3}
                      value={newIssue.proposedSolution}
                      onChange={(e) => setNewIssue({ ...newIssue, proposedSolution: e.target.value })}
                      placeholder="How would this feature work from a user's perspective?"
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mb: 1,
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    color: 'text.secondary'
                  }}
                >
                  Additional Context
                </Typography>
                <StyledTextField
                  fullWidth
                  multiline
                  rows={2}
                  value={newIssue.additionalContext}
                  onChange={(e) => setNewIssue({ ...newIssue, additionalContext: e.target.value })}
                  placeholder="Any other context about the issue"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mb: 1,
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    color: 'text.secondary'
                  }}
                >
                  Attachments
                </Typography>
                <Box
                  sx={{
                    border: 'none',
                    borderRadius: '12px',
                    p: 3,
                    textAlign: 'center',
                    cursor: 'pointer',
                    '& input': {
                      outline: 'none'
                    }
                  }}
                  component="label"
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    hidden
                    style={{ outline: 'none' }}
                    onChange={handleImageUpload}
                  />
                  <Box
                    sx={{
                      border: '1px dotted',
                      borderColor: 'grey.400',
                      borderRadius: '8px',
                      p: 3,
                      px: 4,
                      display: 'inline-block',
                      transition: theme => theme.transitions.create(['border-color', 'background-color']),
                      '&:hover': {
                        borderColor: colors.primary,
                        bgcolor: theme => `${colors.primary}08`
                      }
                    }}
                  >
                    <Typography color="text.secondary" sx={{ mb: 1 }}>
                      Drag and drop images here or click to browse
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Supported formats: PNG, JPG, GIF (max 5MB each)
                    </Typography>
                  </Box>
                </Box>
                {newIssue.images?.length > 0 && (
                  <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {Array.from(newIssue.images).map((file, index) => (
                      <Chip
                        key={index}
                        label={file.name}
                        onDelete={() => {
                          const newImages = [...newIssue.images];
                          newImages.splice(index, 1);
                          setNewIssue({ ...newIssue, images: newImages });
                        }}
                        sx={{
                          borderRadius: '8px',
                          bgcolor: `${colors.primary}15`,
                          color: colors.primary,
                          '& .MuiChip-deleteIcon': {
                            color: colors.primary
                          }
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ 
            p: 0, 
            pt: 2,
            pb: 2,
            mt: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 2
          }}>
            <StyledButton
              variant="outlined"
              onClick={handleCloseModal}
              disabled={loading}
              sx={{
                minWidth: 120
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                minWidth: 120
              }}
            >
              {loading ? <CircularProgress size={20} /> : 'Submit'}
            </StyledButton>
          </DialogActions>
        </form>
      </StyledDialog>

      {/* Issue Viewer Modal */}
      <StyledDialog
        open={isViewModalOpen}
        onClose={handleCloseViewModal}
        maxWidth="md"
        fullWidth
      >
        {selectedIssue && (
          <>
            <DialogTitle sx={{ p: 0 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={handleCloseViewModal} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar sx={{ 
                  bgcolor: selectedIssue.labels.includes('bug') ? `${colors.error}15` : `${colors.primary}15`,
                  color: selectedIssue.labels.includes('bug') ? colors.error : colors.primary 
                }}>
                  {selectedIssue.labels.includes('bug') ? <BugIcon /> : <FeatureIcon />}
                </Avatar>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    {selectedIssue.title}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Chip
                      label={selectedIssue.state.charAt(0).toUpperCase() + selectedIssue.state.slice(1)}
                      size="small"
                      sx={(theme) => ({
                        bgcolor: `${theme.palette.primary.main}15`,
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                        '& .MuiChip-label': {
                          px: 1
                        }
                      })}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(selectedIssue.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </DialogTitle>

            <DialogContent sx={{ p: 0, mt: 3 }}>
              {/* Issue Description */}
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', mb: 4 }}>
                {selectedIssue.body}
              </Typography>

              <Divider sx={{ my: 3 }} />

              {/* Comments Section */}
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Comments
              </Typography>

              {selectedIssue.comments.map(comment => (
                <CommentBox key={comment.id}>
                  <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                    <Avatar src={comment.user.avatar_url} alt={comment.user.login} />
                    <Box>
                      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                        {comment.user.login}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {formatDate(comment.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                    {comment.body}
                  </Typography>
                </CommentBox>
              ))}

              {/* Add Comment Form */}
              <form onSubmit={handleAddComment}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={2}
                    placeholder="Add a comment..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={loadingComments}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!comment.trim() || loadingComments}
                    sx={{
                      minWidth: 'auto',
                      width: '56px',
                      height: '56px',
                      borderRadius: '12px'
                    }}
                  >
                    {loadingComments ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SendIcon />
                    )}
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        )}
      </StyledDialog>
    </Layout>
  );
};

export default Support; 
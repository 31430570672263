import { createTheme } from '@mui/material/styles';

export const colors = {
  primary: '#FF5D82',
  secondary: '#14143B',
  background: '#FEF2F1',
  white: '#FFFFFF',
  status: {
    ready: '#4CAF50',      // success green
    syncing: '#FF9800',    // warning orange
    error: '#FD5875',      // our pink
    unknown: '#9E9E9E'     // default gray
  }
};

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: colors.background,
    },
    text: {
      primary: colors.secondary,
    },
  },
  typography: {
    fontFamily: '"Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif',
    allVariants: {
      letterSpacing: 0,
    },
    h1: {
      fontWeight: 700,
      color: colors.primary,
    },
    h4: {
      color: colors.secondary,
      fontWeight: 600,
    },
    button: {
      fontWeight: 600,
      fontSize: '0.95rem',
    },
    body1: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    body2: {
      fontWeight: 500,
      fontSize: '0.95rem',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          fontWeight: 600,
          textTransform: 'none',
          fontSize: '0.95rem',
        },
        containedPrimary: {
          backgroundColor: colors.primary,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.primary,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          fontWeight: 500,
        }
      },
      variants: [
        {
          props: { variant: 'status-ready' },
          style: {
            backgroundColor: colors.status.ready,
            color: colors.white,
          },
        },
        {
          props: { variant: 'status-syncing' },
          style: {
            backgroundColor: colors.status.syncing,
            color: colors.white,
          },
        },
        {
          props: { variant: 'status-error' },
          style: {
            backgroundColor: colors.status.error,
            color: colors.white,
          },
        },
        {
          props: { variant: 'status-unknown' },
          style: {
            backgroundColor: colors.status.unknown,
            color: colors.white,
          },
        },
      ],
    },
  },
});

export const commonStyles = {
  mainButton: {
    backgroundColor: colors.primary,
    color: colors.secondary,
    padding: '15px 40px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
  featureSection: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    zIndex: '1',
  },
  slantedSection: {
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: colors.primary,
      transform: 'skewY(8deg)',
      transformOrigin: 'top left',
      transition: 'transform 0.3s ease-out',
    },
  },
};
import api from './api';

export const createCheckoutSession = async (priceId) => {
  if (!priceId) {
    throw new Error('Price ID is required to create a checkout session');
  }

  try {
    const response = await api.post('/subscriptions/create-checkout', { priceId });
    return response.data;
  } catch (error) {
    console.error('Failed to create checkout session:', {
      error: error.message,
      response: error.response?.data
    });
    throw error;
  }
};

export const getCurrentSubscription = async () => {
  try {
    const response = await api.get('/subscriptions/current');
    return response.data;
  } catch (error) {
    console.error('Failed to get current subscription:', {
      error: error.message,
      response: error.response?.data
    });
    if (error.response?.status === 403 && error.response?.data?.requireVerification) {
      throw error;
    }
    return { subscription: null };
  }
};

export const cancelSubscription = async () => {
  try {
    const response = await api.post('/subscriptions/cancel');
    return response.data;
  } catch (error) {
    console.error('Failed to cancel subscription:', {
      error: error.message,
      response: error.response?.data
    });
    throw error;
  }
};

export const reactivateSubscription = async () => {
  try {
    const response = await api.post('/subscriptions/reactivate');
    return response.data;
  } catch (error) {
    console.error('Failed to reactivate subscription:', {
      error: error.message,
      response: error.response?.data
    });
    throw error;
  }
};

export const verifySession = async (sessionId) => {
  try {
    const response = await api.post('/subscriptions/verify-session', { sessionId });
    return response.data;
  } catch (error) {
    console.error('Failed to verify session:', {
      error: error.message,
      response: error.response?.data
    });
    throw error;
  }
}; 
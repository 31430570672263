import React, { useEffect } from 'react';
import { Dialog, DialogContent, Box, Typography, CircularProgress, Button } from '@mui/material';
import { verifySession } from '../services/subscriptionService';

const SubscriptionVerificationModal = ({ 
  open, 
  onClose, 
  sessionId,
  onVerificationComplete 
}) => {
  useEffect(() => {
    const checkSession = async () => {
      if (!sessionId) return;

      // Add initial delay to allow Stripe to process
      await new Promise(resolve => setTimeout(resolve, 2000));

      const maxRetries = 5;
      const baseDelay = 2000;

      for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
          const result = await verifySession(sessionId);
          
          if (result.success) {
            onVerificationComplete(result);
            onClose();
            return;
          }

          if (result.status === 'processing') {
            // If processing, always wait and retry
            const delay = baseDelay * Math.pow(2, attempt);
            await new Promise(resolve => setTimeout(resolve, delay));
            continue;
          }

          // If we get here, it's a non-processing failure
          throw new Error(result.error || 'Verification failed');
          
        } catch (err) {
          if (attempt === maxRetries - 1) {
            onClose();
            return;
          }

          // Exponential backoff for other errors
          const delay = baseDelay * Math.pow(2, attempt);
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
    };

    if (open) {
      checkSession();
    }
  }, [open, sessionId, onClose, onVerificationComplete]);

  return (
    <Dialog 
      open={open} 
      maxWidth="xs" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          p: 2
        }
      }}
    >
      <DialogContent>
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          py={3}
          gap={2}
        >
          <CircularProgress />
          <Typography align="center">
            Verifying your subscription...
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            This may take a few moments
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionVerificationModal; 
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import * as subscriptionService from '../services/subscriptionService';
import Logger from '../utils/logger';

export const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [skipNextValidation, setSkipNextValidation] = useState(false);
  const navigate = useNavigate();

  // Log auth state changes
  useEffect(() => {
    Logger.debug('Auth state changed:', {
      hasToken: !!token,
      hasUser: !!user,
      isAuthenticated: !!token && !!user,
      loading
    });
  }, [token, user, loading]);

  const hasFeature = (featureName) => {
    // Free tier features are always available
    const freeFeatures = ['basic_budgeting', 'transaction_tracking'];
    if (freeFeatures.includes(featureName)) {
      return true;
    }

    // Premium features require an active subscription
    const premiumFeatures = ['ai_insights', 'advanced_analytics', 'unlimited_accounts'];
    return premiumFeatures.includes(featureName) && subscription?.status === 'active';
  };

  const validateToken = async (token) => {
    try {
      const response = await api.get('/auth/verify');
      setUser(response.data.user);
      return true;
    } catch (error) {
      if (error.response?.status === 401) {
        // Token is invalid or expired, try to refresh
        try {
          const refreshResponse = await api.post('/auth/refresh-token');
          const newToken = refreshResponse.data.token;
          localStorage.setItem('token', newToken);
          setToken(newToken);
          api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          return true;
        } catch (refreshError) {
          // If refresh fails, log out
          await logout();
          return false;
        }
      }
      return false;
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await api.get('/auth/verify');
      setUser(response.data.user);
      await loadSubscription();
      return response.data.user;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  };

  const loadSubscription = async (force = false) => {
    // Skip if already loading or if not forced and subscription exists
    if (subscriptionLoading || (!force && subscription)) {
      return subscription;
    }

    try {
      setSubscriptionLoading(true);
      const { subscription: newSubscription } = await subscriptionService.getCurrentSubscription();
      
      // Only update if subscription has changed
      if (JSON.stringify(newSubscription) !== JSON.stringify(subscription)) {
        setSubscription(newSubscription);
      }
      
      return newSubscription;
    } catch (error) {
      if (error.response?.status === 403 && error.response?.data?.requireVerification) {
        // PIN verification required - let the error propagate
        throw error;
      }
      console.error('Error loading subscription:', error);
      // For other errors, set subscription to null but don't throw
      setSubscription(null);
      return null;
    } finally {
      setSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      setLoading(true);
      try {
        if (token && !skipNextValidation) {
          const isValid = await validateToken(token);
          if (isValid) {
            await loadSubscription();
          }
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
      } finally {
        setLoading(false);
        setSkipNextValidation(false);
      }
    };

    initializeAuth();
  }, [token]);

  const login = async (token, email = null, skipValidation = false) => {
    localStorage.setItem('token', token);
    setSkipNextValidation(skipValidation);
    setToken(token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    if (!skipValidation) {
      const isValid = await validateToken(token);
      if (!isValid) {
        throw new Error('Failed to validate token');
      }
      await loadSubscription(true);
    }
  };

  const logout = async () => {
    try {
      await api.post('/auth/logout');
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      localStorage.removeItem('token');
      setToken(null);
      setUser(null);
      setSubscription(null);
      navigate('/', { replace: true });
    }
  };

  const updateUser = async (updates) => {
    try {
      const response = await api.patch('/users/me', updates);
      setUser(response.data.user);
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  };

  const value = {
    user,
    token,
    loading,
    subscription,
    subscriptionLoading,
    hasFeature,
    login,
    logout,
    updateUser,
    fetchUserProfile,
    validateToken,
    loadSubscription,
    isAuthenticated: !!token && !!user
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

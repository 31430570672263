// Stripe configuration using only environment variables
const stripeConfig = {
  PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  PRICES: {
    PREMIUM_MONTHLY: process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_ID,
    PREMIUM_YEARLY: process.env.REACT_APP_STRIPE_PREMIUM_YEARLY_PRICE_ID,
  },
};

const config = {
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:4001/api/v1',
  
  // Add Plaid configuration
  PLAID: {
    ENV: process.env.REACT_APP_PLAID_ENV || 'sandbox',
    CLIENT_NAME: process.env.REACT_APP_PLAID_CLIENT_NAME || 'budgit',
    PRODUCTS: (process.env.REACT_APP_PLAID_PRODUCTS || 'transactions').split(','),
    COUNTRY_CODES: (process.env.REACT_APP_PLAID_COUNTRY_CODES || 'US').split(','),
    LANGUAGE: process.env.REACT_APP_PLAID_LANGUAGE || 'en',
  },

  // Add Stripe configuration
  STRIPE: stripeConfig,
};

export default config;

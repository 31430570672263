import React, { useState } from 'react';
import {
  DialogContent,
  Typography,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { colors } from '../styles';
import * as subscriptionService from '../services/subscriptionService';
import config from '../config';
import { StyledDialog } from './common/StyledInputs';

const PricingModal = ({ open, onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState('Free');
  const [processing, setProcessing] = useState(false);
  const { user } = useAuth();

  const handleUpgrade = async (priceId) => {
    if (!priceId) {
      return;
    }
    try {
      setProcessing(true);
      const { sessionUrl } = await subscriptionService.createCheckoutSession(priceId);
      window.location.href = sessionUrl;
    } catch (err) {
      setProcessing(false);
    }
  };

  const plans = [
    {
      title: 'Free',
      price: 0,
      features: [
        { name: 'Connect up to 2 bank accounts', included: true },
        { name: 'Single budget management', included: true },
        { name: 'Basic transaction tracking', included: true },
        { name: 'Manual categorization', included: true }
      ],
    },
    {
      title: 'Monthly',
      price: 9.99,
      period: '/month',
      features: [
        { name: 'Unlimited bank accounts', included: true },
        { name: 'Multiple budgets', included: true },
        { name: 'Smart AI categorization', included: true },
        { name: 'Pattern-based insights', included: true }
      ],
      priceId: config.STRIPE.PRICES.PREMIUM_MONTHLY
    },
    {
      title: 'Yearly',
      price: 99.99,
      period: '/year',
      features: [
        { name: 'Unlimited bank accounts', included: true },
        { name: 'Multiple budgets', included: true },
        { name: 'Smart AI categorization', included: true },
        { name: 'Pattern-based insights', included: true }
      ],
      savings: 'Save 17%',
      priceId: config.STRIPE.PRICES.PREMIUM_YEARLY
    }
  ];

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: '900px !important'
        }
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom fontWeight="bold" textAlign="center">
          Choose Your Plan
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center" mb={2}>
          Start with our free plan or upgrade to Premium for advanced features
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item key={plan.title} xs={12} md={4}>
            <Card 
              onClick={() => setSelectedPlan(plan.title)}
              sx={{
                cursor: 'pointer',
                height: '100%',
                borderRadius: '12px',
                border: '1px solid',
                borderColor: selectedPlan === plan.title ? 'primary.main' : 'grey.200',
                bgcolor: 'background.paper',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  borderColor: 'primary.main',
                  transform: 'translateY(-2px)',
                  boxShadow: 1
                }
              }}
            >
              <CardContent sx={{ p: 3 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    {plan.title}
                  </Typography>
                  {plan.savings && (
                    <Chip 
                      label={plan.savings} 
                      size="small"
                      sx={{ 
                        bgcolor: 'grey.100',
                        color: 'text.primary',
                        fontWeight: 500
                      }}
                    />
                  )}
                </Box>
                <Typography variant="h4" component="p" fontWeight="bold" gutterBottom>
                  ${plan.price}
                  {plan.period && (
                    <Typography variant="body2" component="span" color="text.secondary">
                      {plan.period}
                    </Typography>
                  )}
                </Typography>
                <List>
                  {plan.features.map((feature, index) => (
                    <ListItem key={index} sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        {feature.included ? (
                          <CheckIcon color="primary" fontSize="small" />
                        ) : (
                          <CloseIcon color="disabled" fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText 
                        primary={feature.name}
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: feature.included ? 'text.primary' : 'text.secondary',
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={processing || (user && selectedPlan === 'Free')}
          onClick={() => {
            if (!user) {
              onClose();
            } else if (selectedPlan !== 'Free') {
              const plan = plans.find(p => p.title === selectedPlan);
              if (plan && plan.priceId) {
                handleUpgrade(plan.priceId);
              } else {
                console.error('No price ID found for selected plan:', selectedPlan);
              }
            }
          }}
          sx={{
            minWidth: 200,
            borderRadius: '100px',
            textTransform: 'none',
            py: 1.5,
            px: 4,
            fontSize: '1rem',
            fontWeight: 500,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
              bgcolor: 'primary.dark'
            }
          }}
        >
          {!user ? 'Sign Up' : selectedPlan === 'Free' ? 'Current Plan' : 'Upgrade Now'}
        </Button>
      </Box>
    </StyledDialog>
  );
};

export default PricingModal; 
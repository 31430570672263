import { api } from './api';

export const githubService = {
  // Fetch issues filtered by label (user-reported)
  async getIssues() {
    try {
      const response = await api.get('/github/issues');
      return response.data;
    } catch (error) {
      console.error('Error fetching issues:', error);
      throw error;
    }
  },

  // Create a new issue
  async createIssue(issueData) {
    try {
      const response = await api.post('/github/issues', issueData);
      return response.data;
    } catch (error) {
      console.error('Error creating issue:', error);
      throw error;
    }
  },

  // Get issue details with comments
  async getIssueDetails(issueNumber) {
    try {
      const response = await api.get(`/github/issues/${issueNumber}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching issue details:', error);
      throw error;
    }
  },

  // Add a comment to an issue
  async addComment(issueNumber, body) {
    try {
      const response = await api.post(`/github/issues/${issueNumber}/comments`, { body });
      return response.data;
    } catch (error) {
      console.error('Error adding comment:', error);
      throw error;
    }
  }
};

// Templates for different issue types
export const issueTemplates = {
  bug: `
### Description
[A clear and concise description of the bug]

### Steps to Reproduce
1. [First Step]
2. [Second Step]
3. [And so on...]

### Expected Behavior
[What you expected to happen]

### Actual Behavior
[What actually happened]

### Additional Context
[Any other context about the problem]

### System Information
- Browser: [browser]
- OS: [os]
- App Version: [version]
- URL: [url]
- Route: [route]
- User ID: [userId]
- Timestamp: [timestamp]
`,

  feature: `
### Feature Description
[A clear and concise description of the feature you'd like]

### Problem to Solve
[What problem would this feature solve?]

### Proposed Solution
[How would this feature work from a user's perspective?]

### Additional Context
[Any other context or screenshots about the feature request]

### System Information
- Browser: [browser]
- OS: [os]
- App Version: [version]
- URL: [url]
- Route: [route]
- User ID: [userId]
- Timestamp: [timestamp]
`
};

// Function to capture page context
export const capturePageContext = () => {
  return {
    url: window.location.href,
    route: window.location.pathname,
    userAgent: navigator.userAgent,
    browser: getBrowser(),
    os: getOS(),
    timestamp: new Date().toISOString(),
    screenSize: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    // Add any console errors if they exist
    consoleErrors: window.console.errors || [],
  };
};

// Helper function to get browser name
function getBrowser() {
  const ua = navigator.userAgent;
  let browser = "Unknown";
  
  if (ua.includes("Firefox/")) {
    browser = "Firefox";
  } else if (ua.includes("Chrome/")) {
    browser = "Chrome";
  } else if (ua.includes("Safari/")) {
    browser = "Safari";
  } else if (ua.includes("Edge/")) {
    browser = "Edge";
  } else if (ua.includes("Opera/")) {
    browser = "Opera";
  }
  
  return browser;
}

// Helper function to get OS name
function getOS() {
  const ua = navigator.userAgent;
  let os = "Unknown";
  
  if (ua.includes("Win")) {
    os = "Windows";
  } else if (ua.includes("Mac")) {
    os = "MacOS";
  } else if (ua.includes("Linux")) {
    os = "Linux";
  } else if (ua.includes("Android")) {
    os = "Android";
  } else if (ua.includes("iOS")) {
    os = "iOS";
  }
  
  return os;
} 
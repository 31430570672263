import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4001/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Handle 401 Unauthorized
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.dispatchEvent(new CustomEvent('auth:logout'));
        return Promise.reject(error);
      }
      
      // Handle 403 with requireVerification flag
      if (error.response.status === 403 && error.response.data.requireVerification) {
        // Store current path for redirect after verification
        localStorage.setItem('returnUrl', window.location.pathname);
        
        // Get email from local storage
        const email = localStorage.getItem('userEmail');
        if (email) {
          // Dispatch event for PIN verification
          window.dispatchEvent(new CustomEvent('auth:requirePin', {
            detail: { email }
          }));
        }
        
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

// Auth functions
export const generateOTP = async (email) => {
  const response = await api.post('/auth/generate-otp', { email });
  return response.data;
};

export const verifyOTP = async (email, otp) => {
  try {
    console.log('verifyOTP: Starting verification', { email, otp });
    const response = await api.post('/auth/verify-otp', { email, otp });
    console.log('verifyOTP response:', {
      hasAccessToken: !!response.data.accessToken,
      hasRefreshToken: !!response.data.refreshToken,
      needsPinVerification: response.data.needsPinVerification,
      userIsPinSetup: response.data.user?.isPinSetup
    });

    if (response.data.accessToken) {
      console.log('verifyOTP: Setting auth state', {
        email,
        tokenLength: response.data.accessToken.length
      });
      localStorage.setItem('userEmail', email);
      localStorage.setItem('token', response.data.accessToken);
      if (response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
      }
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
      console.log('verifyOTP: Auth header set', {
        header: api.defaults.headers.common['Authorization']?.substring(0, 20) + '...'
      });
    }
    return {
      ...response.data,
      token: response.data.accessToken // For backward compatibility
    };
  } catch (error) {
    console.error('verifyOTP error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    if (error.response?.data?.needsPinVerification) {
      return error.response.data;
    }
    throw error;
  }
};

const getFriendlyDeviceName = () => {
  const platform = navigator.platform || 'Unknown OS';
  const userAgent = navigator.userAgent;
  
  // Get OS name
  let os = 'Unknown OS';
  if (userAgent.includes('Win')) os = 'Windows';
  else if (userAgent.includes('Mac')) os = 'macOS';
  else if (userAgent.includes('Linux')) os = 'Linux';
  else if (userAgent.includes('iPhone')) os = 'iOS';
  else if (userAgent.includes('Android')) os = 'Android';

  // Get browser name
  let browser = 'Unknown Browser';
  if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) browser = 'Chrome';
  else if (userAgent.includes('Firefox')) browser = 'Firefox';
  else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) browser = 'Safari';
  else if (userAgent.includes('Edg')) browser = 'Edge';

  // Get browser version
  const version = userAgent.match(/(Chrome|Firefox|Safari|Edge)\/(\d+)/)?.[2] || '';
  
  return `${os} (${browser} ${version})`;
};

export const verifyPIN = async (email, pin, isChangingPin = false) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found. Please log in again.');
  }

  try {
    console.log('verifyPIN: Starting verification', {
      email,
      isChangingPin,
      hasInitialToken: !!token
    });

    const response = await api.post('/auth/verify-pin', { 
      email, 
      pin,
      deviceName: getFriendlyDeviceName(),
      isChangingPin
    });

    console.log('verifyPIN response:', {
      success: response.data.success,
      hasAccessToken: !!response.data.accessToken,
      hasRefreshToken: !!response.data.refreshToken
    });

    if (isChangingPin) {
      return { success: true };
    }

    // Use existing token if no new one provided
    const newToken = response.data.accessToken || token;
    
    console.log('verifyPIN: Setting auth state', {
      email,
      usingExistingToken: !response.data.accessToken,
      tokenLength: newToken.length
    });

    // Clear any existing auth state
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    api.defaults.headers.common['Authorization'] = '';

    // Set new auth state
    localStorage.setItem('userEmail', email);
    localStorage.setItem('token', newToken);
    if (response.data.refreshToken) {
      localStorage.setItem('refreshToken', response.data.refreshToken);
    }

    // Update Authorization header
    api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

    // Dispatch auth event to trigger context update
    window.dispatchEvent(new CustomEvent('auth:login', {
      detail: { token: newToken, email }
    }));

    console.log('verifyPIN: Auth header set', {
      header: api.defaults.headers.common['Authorization']?.substring(0, 20) + '...'
    });
    
    return {
      ...response.data,
      success: true,
      token: newToken,
      email
    };
  } catch (error) {
    console.error('Error in verifyPIN:', error);

    // Handle specific error cases
    if (error.response?.status === 403) {
      // Incorrect PIN
      throw new Error('Incorrect PIN. Please try again.');
    } else if (error.response?.status === 401) {
      // Token expired or invalid during verification
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userEmail');
      api.defaults.headers.common['Authorization'] = '';
      window.dispatchEvent(new CustomEvent('auth:logout'));
      throw new Error('Your session has expired. Please log in again.');
    } else if (error.response?.status === 429) {
      // Rate limiting
      throw new Error('Too many attempts. Please wait a moment before trying again.');
    } else if (error.response?.status >= 500) {
      // Server errors
      throw new Error('Server error. Please try again later.');
    } else if (!navigator.onLine) {
      // Network connectivity
      throw new Error('No internet connection. Please check your network.');
    }

    // Generic error case
    throw new Error('Failed to verify PIN. Please try again.');
  }
};

export const setupPIN = async (email, pin, isChangingPin = false) => {
  try {
    const response = await api.post('/auth/setup-pin', { 
      email, 
      pin,
      isChangingPin 
    });

    // For PIN changes, we don't need to update the token
    if (isChangingPin) {
      return { success: true };
    }

    // For initial setup, we need the tokens
    if (!response.data.accessToken) {
      throw new Error('No access token received from server');
    }

    // Update tokens and headers for initial setup
    localStorage.setItem('token', response.data.accessToken);
    if (response.data.refreshToken) {
      localStorage.setItem('refreshToken', response.data.refreshToken);
    }
    api.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
    
    return {
      ...response.data,
      token: response.data.accessToken // For backward compatibility
    };
  } catch (error) {
    console.error('Error in setupPIN:', error);
    throw error;
  }
};

export const checkPINSetup = async () => {
  const response = await api.get('/auth/check-pin-setup');
  return response.data;
};

// Account functions
export const getConnectedAccounts = async () => {
  const response = await api.get('/plaid/accounts');
  return response.data;
};

export const getTransactionsByAccount = async (accountId) => {
  const response = await api.get(`/transactions/${accountId}`);
  return response.data;
};

export const getTransactions = async (page = 1, limit = 30, filters = {}) => {
  const response = await api.get('/transactions', {
    params: {
      page: parseInt(page),
      limit: parseInt(limit),
      search: filters.search,
      showTransfers: String(filters.showTransfers || false),
      showIncome: String(filters.showIncome || false),
      showUncategorized: String(filters.showUncategorized || false),
      transferType: filters.transferType,
      startDate: filters.startDate,
      endDate: filters.endDate,
      minAmount: filters.minAmount,
      maxAmount: filters.maxAmount
    }
  });
  return response.data;
};

// Plaid functions
export const createLinkToken = async () => {
  const response = await api.post('/plaid/create_link_token');
  return response.data;
};

export const exchangePublicToken = async (public_token) => {
  const response = await api.post('/plaid/exchange_public_token', { public_token });
  return response.data;
};

export const removeAccount = async (accountId) => {
  const response = await api.delete(`/plaid/accounts/${accountId}`);
  return response.data;
};

// Device management functions
export const getTrustedDevices = async () => {
  const response = await api.get('/auth/devices');
  return response.data;
};

export const removeTrustedDevice = async (fingerprint) => {
  const response = await api.delete(`/auth/devices/${fingerprint}`);
  return response.data;
};

// User management functions
export const updateUser = async (updates) => {
  const response = await api.patch('/users/me', updates);
  return response.data;
};

export const updateTransactionCategory = async (transactionId, category) => {
  const response = await api.patch(`/transactions/${transactionId}/category`, { category });
  return response.data;
};

// User settings functions
export const getUserSettings = async (userId) => {
  const response = await api.get(`/users/${userId}/settings`);
  return response.data;
};

export const updateUserSettings = async (userId, settings) => {
  const response = await api.patch(`/users/${userId}/settings`, settings);
  return response.data;
};

export { api };
export default api;
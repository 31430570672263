import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DevicesIcon from '@mui/icons-material/Devices';
import { format as formatDate } from 'date-fns';
import { api } from '../services/api';

const DeviceManager = ({ devices, onDeviceRemoved }) => {
  const handleRemoveDevice = async (fingerprint) => {
    try {
      await api.delete(`/auth/devices/${fingerprint}`);
      onDeviceRemoved(fingerprint);
    } catch (error) {
      console.error('Error removing device:', error);
    }
  };

  const formatDeviceName = (device) => {
    if (device.name) return device.name;
    
    const ua = device.userAgent || '';
    let deviceType = 'Unknown Device';
    
    if (ua.includes('iPhone')) deviceType = 'iPhone';
    else if (ua.includes('iPad')) deviceType = 'iPad';
    else if (ua.includes('Android')) deviceType = 'Android Device';
    else if (ua.includes('Windows')) deviceType = 'Windows PC';
    else if (ua.includes('Mac')) deviceType = 'Mac';
    else if (ua.includes('Linux')) deviceType = 'Linux PC';
    
    return deviceType;
  };

  return (
    <Box>
      <List>
        {devices.map((device) => (
          <ListItem key={device.fingerprint} divider>
            <ListItemText
              primary={formatDeviceName(device)}
              secondary={
                <>
                  Last used: {formatDate(new Date(device.lastUsed), 'PPp')}
                  <br />
                  IP: {device.ip}
                </>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title="Remove device">
                <IconButton
                  edge="end"
                  aria-label="remove device"
                  onClick={() => handleRemoveDevice(device.fingerprint)}
                  sx={{
                    color: '#FD5875',
                    '&:hover': {
                      backgroundColor: 'rgba(253, 88, 117, 0.1)',
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {devices.length === 0 && (
          <ListItem>
            <ListItemText
              primary="No trusted devices"
              secondary="Devices will appear here after successful PIN verification"
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default DeviceManager; 
import React from 'react';
import { IconButton, Box } from '@mui/material';
import { colors } from '../styles';

const Star = ({ size, delay = 0, sx = {} }) => (
  <Box
    sx={{
      position: 'absolute',
      width: size,
      height: size,
      background: 'transparent',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'currentColor',
        clipPath: 'polygon(50% 0, 65% 35%, 100% 50%, 65% 65%, 50% 100%, 35% 65%, 0 50%, 35% 35%)',
      },
      ...sx
    }}
  />
);

const SparkleButton = ({ loading, onClick, disabled, color }) => {
  return (
    <IconButton
      size="small"
      onClick={onClick}
      disabled={disabled}
      title="Categorize Uncategorized"
      sx={{ color, position: 'relative', width: 40, height: 40 }}
    >
      {/* Center star */}
      <Star 
        size={20}
        sx={{
          left: '30%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          animation: loading ? 'sparkle-main 1.2s ease-in-out infinite' : 'none',
          '@keyframes sparkle-main': {
            '0%': { opacity: 1 },
            '35%': { opacity: 0.3 },
            '75%': { opacity: 1 },
            '100%': { opacity: 1 }
          }
        }}
      />
      {/* Top right star */}
      <Star 
        size={6}
        sx={{
          left: '65%',
          top: '25%',
          transform: 'translate(-50%, -50%)',
          animation: loading ? 'sparkle-top 0.8s ease-in-out infinite 0.3s' : 'none',
          '@keyframes sparkle-top': {
            '0%': { opacity: 0.7 },
            '25%': { opacity: 0 },
            '65%': { opacity: 0.7 },
            '100%': { opacity: 0.7 }
          }
        }}
      />
      {/* Bottom left star */}
      <Star 
        size={10}
        sx={{
          left: '15%',
          top: '75%',
          transform: 'translate(-50%, -50%)',
          animation: loading ? 'sparkle-bottom 1s ease-in-out infinite 0.1s' : 'none',
          '@keyframes sparkle-bottom': {
            '0%': { opacity: 0.7 },
            '45%': { opacity: 0 },
            '85%': { opacity: 0.7 },
            '100%': { opacity: 0.7 }
          }
        }}
      />
    </IconButton>
  );
};

export default SparkleButton; 
import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import api, { setupPIN, verifyPIN, checkPINSetup } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import PinInput from './PinInput';
import { Close as CloseIcon } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 500
  }
}));

const AccountSetup = ({ open, onClose, isChangingPin: initialIsChangingPin }) => {
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [oldPin, setOldPin] = useState('');
  const [isPinSetup, setIsPinSetup] = useState(false);
  const [isChangingPin, setIsChangingPin] = useState(initialIsChangingPin);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user, updateUser, login, setToken } = useAuth();

  const resetState = useCallback(() => {
    setPin('');
    setConfirmPin('');
    setOldPin('');
    setError('');
    setIsSubmitting(false);
    setStep(isChangingPin ? 1 : 2);
  }, [isChangingPin]);

  const checkPINStatus = useCallback(async () => {
    try {
      const result = await checkPINSetup();
      setIsPinSetup(result.isPinSetup);
    } catch (err) {
      console.error('Error checking PIN status:', err);
      setError('Failed to check PIN status. Please try again.');
    }
  }, []);

  useEffect(() => {
    if (user) {
      checkPINStatus();
    }
  }, [user, checkPINStatus]);

  useEffect(() => {
    if (open) {
      setIsChangingPin(initialIsChangingPin);
      setPin('');
      setConfirmPin('');
      setOldPin('');
      setError('');
      setIsSubmitting(false);
      setStep(isChangingPin ? 1 : 2);
    }
  }, [open, initialIsChangingPin]);

  useEffect(() => {
    if (step === 2) {
      setPin('');
    }
  }, [step]);

  const validatePin = (pinValue) => {
    if (!pinValue || pinValue.length !== 4 || !/^\d{4}$/.test(pinValue)) {
      return 'Please enter a valid 4-digit PIN';
    }
    return null;
  };

  const handlePinValidation = async (handler) => {
    if (isSubmitting) return { success: false, error: 'Request in progress' };
    setIsSubmitting(true);
    try {
      const result = await handler();
      return result;
    } catch (err) {
      return { 
        success: false, 
        error: err.message || 'An error occurred' 
      };
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNewPin = async (pinValue) => {
    return handlePinValidation(async () => {
      const validationError = validatePin(pinValue);
      if (validationError) return { success: false, error: validationError };

      setPin(pinValue);
      setStep(3);
      return { success: true };
    });
  };

  const handleVerifyCurrentPin = async (pinValue) => {
    return handlePinValidation(async () => {
      const validationError = validatePin(pinValue);
      if (validationError) return { success: false, error: validationError };

      try {
        // Verify current PIN without affecting session
        const response = await verifyPIN(user.email, pinValue, true);
        if (!response.success) {
          setPin('');
          return { success: false, error: 'PIN verification failed' };
        }

        // Move to next step - no token/session changes needed
        setOldPin(pinValue);
        setPin('');
        setConfirmPin('');
        setStep(2);
        return { success: true };
      } catch (err) {
        console.error('Error verifying current PIN:', err);
        setPin('');
        return { 
          success: false, 
          error: err.response?.data?.error || 'Incorrect PIN' 
        };
      }
    });
  };

  const handleConfirmPin = async (pinValue) => {
    return handlePinValidation(async () => {
      const validationError = validatePin(pinValue);
      if (validationError) return { success: false, error: validationError };

      if (pinValue !== pin) {
        setConfirmPin('');
        return { success: false, error: 'PINs do not match' };
      }

      try {
        // For changing PIN, we don't need to affect the session
        const response = await setupPIN(user.email, pinValue, isChangingPin);
        
        if (isChangingPin) {
          // Just close the dialog on successful PIN change
          if (response.success) {
            setIsChangingPin(false);
            onClose();
            return { success: true };
          }
          return { success: false, error: 'Failed to change PIN' };
        }

        // For initial PIN setup
        if (!response.token) {
          return { success: false, error: 'Failed to set PIN' };
        }

        // Only for initial setup: establish session
        await login(response.token, user.email);
        await updateUser({ isPinSetup: true });
        setIsChangingPin(false);
        onClose();
        return { success: true };
      } catch (err) {
        console.error('Error during PIN setup:', err);
        return { 
          success: false, 
          error: err.response?.data?.error || 'Failed to set up PIN' 
        };
      }
    });
  };

  const handleModalClose = () => {
    resetState();
    onClose();
  };

  const renderStepContent = () => {
    if (isChangingPin && step === 1) {
      return (
        <PinInput
          key={`pin-input-step-${step}`}
          value={oldPin}
          onChange={setOldPin}
          label="Enter your current PIN"
          error={error}
          autoFocus
          validatePin={handleVerifyCurrentPin}
        />
      );
    }

    if (step === 2) {
      return (
        <PinInput
          key={`pin-input-step-${step}`}
          value={pin}
          onChange={setPin}
          label="Enter your new PIN"
          error={error}
          autoFocus
          validatePin={handleNewPin}
        />
      );
    }

    if (step === 3) {
      return (
        <PinInput
          key={`pin-input-step-${step}`}
          value={confirmPin}
          onChange={setConfirmPin}
          label="Confirm your new PIN"
          error={error}
          autoFocus
          validatePin={handleConfirmPin}
        />
      );
    }

    return null;
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleModalClose}
      fullWidth
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleModalClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography 
          variant="h4" 
          component="div" 
          sx={{ fontWeight: 'bold', fontSize: '1.75rem', mt: 1 }}
        >
          {!isPinSetup ? 'Set Your PIN' : 
            isChangingPin ? 
              (step === 1 ? 'Verify Current PIN' : 
               step === 2 ? 'Enter New PIN' : 
               'Confirm New PIN') : 
            'Enter Your PIN'}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {isChangingPin && step === 1 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            Please enter your current PIN to verify your identity before making changes.
          </Typography>
        )}
        {step === 2 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            Choose a new 4-digit PIN for your account. Make sure to pick a PIN you'll remember.
          </Typography>
        )}
        {step === 3 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            Please re-enter your new PIN to confirm. This helps prevent any typos in your PIN setup.
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          {renderStepContent()}
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default AccountSetup;

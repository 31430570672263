import React, { useState, useEffect } from 'react';
import { generateOTP, verifyOTP, verifyPIN, setupPIN } from '../services/api';
import api from '../services/api';
import { Typography, TextField, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import PinInput from '../components/PinInput';
import { Close as CloseIcon } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 500
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.grey[100],
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 0.7
    }
  }
}));

const Login = ({ open = true, onClose = () => {} }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { login, updateUser, loadSubscription, validateToken, fetchUserProfile } = useAuth();

  useEffect(() => {
    // Check URL parameters for PIN verification requirement
    const params = new URLSearchParams(window.location.search);
    const storedEmail = params.get('email');
    const requirePin = params.get('requirePin');
    
    if (storedEmail && requirePin === 'true') {
      setEmail(storedEmail);
      setStep(3); // Go directly to PIN verification
    }
    
    // Clear URL parameters
    if (requirePin) {
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    setEmail('');
    setOtp('');
    setPin('');
    setConfirmPin('');
    setError('');
    setIsSubmitting(false);
  }, [open]);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    setError('');

    try {
      if (step === 1) {
        await generateOTP(email);
        setStep(2);
      } else if (step === 2) {
        // Ensure email is preserved from step 1
        if (!email) {
          setError('Email is missing. Please start over.');
          setStep(1);
          return;
        }
        const response = await verifyOTP(email, otp);
        if (response.token) {
          // Store token immediately after OTP verification
          localStorage.setItem('token', response.token);
          
          if (response.needsPinVerification) {
            // Existing user needs to verify PIN on new device
            setStep(3);
            setError(''); // Clear any existing errors
          } else if (!response.user.isPinSetup) {
            // New user needs to set up PIN
            setStep(4);
          } else {
            // User is verified and device is trusted
            await login(response.token, email);
            onClose();
            navigate('/activity');
          }
        } else {
          setError('Invalid verification code. Please try again.');
        }
      } else if (step === 3) {
        // PIN verification for new device
        if (!/^\d{4}$/.test(pin)) {
          setError('Please enter a valid 4-digit PIN.');
          return;
        }
        
        try {
          const response = await verifyPIN(email, pin);
          if (!response.token) {
            setError('PIN verification failed. Please try again.');
            return;
          }

          // First store the token
          localStorage.setItem('token', response.token);
          api.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
          
          try {
            // Establish session with the new token
            await login(response.token, email);
            
            // Fetch user profile and subscription data
            await fetchUserProfile();
            await loadSubscription(true);
            
            // Only navigate after auth state is fully established
            onClose();
            navigate('/activity');
          } catch (loginErr) {
            console.error('Error during login after PIN verification:', loginErr);
            throw new Error('Failed to establish session. Please try again.');
          }
        } catch (err) {
          console.error('Error during PIN verification:', err);
          setError(err.message || 'Failed to verify PIN. Please try again.');
          localStorage.removeItem('token');
        }
      } else if (step === 4) {
        // New PIN setup
        if (!/^\d{4}$/.test(pin)) {
          setError('Please enter a valid 4-digit PIN.');
          return;
        }
        setStep(5);
      } else if (step === 5) {
        // Confirm new PIN
        if (!/^\d{4}$/.test(confirmPin)) {
          setError('Please enter a valid 4-digit PIN.');
          return;
        }
        if (pin !== confirmPin) {
          setError('PINs do not match. Please try again.');
          setConfirmPin('');
          return;
        }

        // Get current token from localStorage
        const currentToken = localStorage.getItem('token');
        if (!currentToken) {
          throw new Error('Session expired. Please verify your email again.');
        }

        const response = await setupPIN(email, pin);
        if (response.token) {
          // Update token after successful PIN setup
          localStorage.setItem('token', response.token);
          await login(response.token);
          await updateUser({ isPinSetup: true });
          onClose();
          navigate('/activity');
        }
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.message === 'Session expired. Please verify your email again.' || 
          err.message === 'No authentication token found. Please log in again.') {
        setStep(1);
        setError('Session expired. Please verify your email again.');
        localStorage.removeItem('token');
      } else {
        // Handle API error responses
        const errorMessage = err.response?.data?.error || err.message || 'An error occurred. Please try again.';
        setError(errorMessage);
        
        // If the code has expired, allow requesting a new one
        if (err.response?.data?.shouldRequestNewCode) {
          setOtp('');
        }
        
        // If we've exceeded rate limits, disable the form temporarily
        if (err.response?.status === 429) {
          setIsSubmitting(true);
          const retryAfter = err.response?.data?.retryAfter || 900; // Default to 15 minutes
          setTimeout(() => {
            setIsSubmitting(false);
            setError('');
          }, retryAfter * 1000);
        }
      }
    } finally {
      if (!isSubmitting) {
        setIsSubmitting(false);
      }
    }
  };

  const handlePinComplete = () => {
    handleSubmit();
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography 
          component="div" 
          sx={{ 
            fontWeight: 'bold', 
            fontSize: '1.75rem', 
            mt: 1 
          }}
        >
          {step === 1 ? 'Welcome' : 
           step === 2 ? 'Enter code' : 
           step === 3 ? 'Enter PIN' :
           step === 4 ? 'Set Your PIN' :
           'Confirm Your PIN'}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {step === 1 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            Enter your email to get started. If you don't have an account yet, we'll create one for you automatically.
          </Typography>
        )}
        {step === 2 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            We've sent a verification code to your email. Please check your inbox and spam/junk folder.
          </Typography>
        )}
        {step === 3 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            Since you're signing in from a new device, please enter your PIN to verify your identity. After verification, this device will be trusted.
          </Typography>
        )}
        {step === 4 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            Create a 4-digit PIN to secure your account. You'll need this PIN when accessing sensitive information.
          </Typography>
        )}
        {step === 5 && (
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.1rem', mb: 3 }}>
            Please re-enter your PIN to confirm. Make sure to remember it - you'll need it to access your account.
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <Box>
            {step === 1 && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 1,
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      color: 'text.secondary'
                    }}
                  >
                    Email Address
                  </Typography>
                  <StyledTextField
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    fullWidth
                    autoFocus
                  />
                </Box>
                {error && (
                  <Typography color="error" align="center" sx={{ mt: 2 }}>
                    {error}
                  </Typography>
                )}
              </>
            )}
            {step === 2 && (
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 1,
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      color: 'text.secondary'
                    }}
                  >
                    Verification Code
                  </Typography>
                  <StyledTextField
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter verification code"
                    required
                    fullWidth
                    autoFocus
                  />
                </Box>
                {error && (
                  <Typography color="error" align="center" sx={{ mt: 2 }}>
                    {error}
                  </Typography>
                )}
              </>
            )}
            {step === 3 && (
              <PinInput
                value={pin}
                onChange={setPin}
                label="Enter your PIN"
                error={error}
                autoFocus
                onComplete={handlePinComplete}
              />
            )}
            {step === 4 && (
              <PinInput
                value={pin}
                onChange={setPin}
                label="Enter a new PIN"
                error={error}
                autoFocus
                onComplete={handlePinComplete}
              />
            )}
            {step === 5 && (
              <PinInput
                value={confirmPin}
                onChange={setConfirmPin}
                label="Confirm your PIN"
                error={error}
                autoFocus
                onComplete={handlePinComplete}
              />
            )}
          </Box>
        </form>
      </DialogContent>

      {(step === 1 || step === 2) && (
        <DialogActions sx={{ p: 0, mt: 2 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={isSubmitting}
            sx={{
              borderRadius: '9999px',
              py: 1.5,
              textTransform: 'none',
              fontWeight: 1000,
              fontSize: '1.1rem'
            }}
          >
            {isSubmitting ? 'Please wait...' : step === 1 ? 'Continue' : 'Verify Code'}
          </Button>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

export default Login;

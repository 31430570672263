import api from './api';

/**
 * Completes an onboarding step and updates the user's onboarding progress
 * @param {string} step - The step to complete (e.g., 'accounts', 'categorization', 'budget')
 */
export const completeOnboardingStep = async (step) => {
  try {
    const response = await api.post('/onboarding/complete', { step });
    return response.data;
  } catch (error) {
    console.error('Error completing onboarding step:', error);
    throw error;
  }
};

/**
 * Gets the current onboarding progress for the user
 */
export const getOnboardingProgress = async () => {
  try {
    const response = await api.get('/onboarding/progress');
    return response.data;
  } catch (error) {
    console.error('Error getting onboarding progress:', error);
    throw error;
  }
};

/**
 * Updates the user's onboarding alerts based on their current state
 */
export const updateOnboardingAlerts = async () => {
  try {
    const response = await api.post('/onboarding/alerts/update');
    return response.data;
  } catch (error) {
    console.error('Error updating onboarding alerts:', error);
    throw error;
  }
};

export default {
  completeOnboardingStep,
  getOnboardingProgress,
  updateOnboardingAlerts
}; 
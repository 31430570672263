import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Typography,
  Box,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { StyledDialog, StyledTextField, StyledButton } from './common/StyledInputs';
import IconSelector from './IconSelector';
import styled from '@emotion/styled';
import { colors } from '../styles';

const TypeToggleButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  padding: '2px 8px',
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: `${colors.primary}05`,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.primary
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.primary
  }
}));

const BudgetCategoryEditModal = ({ open, onClose, category, onSave, budgets }) => {
  const [formData, setFormData] = useState({
    name: '',
    amount: '',
    isPercentage: false,
    icon: ''
  });
  const [selectedBudgetId, setSelectedBudgetId] = useState('');

  useEffect(() => {
    if (category) {
      setFormData({
        name: category.name || '',
        amount: category.amount || '',
        isPercentage: category.isPercentage || false,
        icon: category.icon || ''
      });
      // If this is a combined category (budgetId is 'all'), don't set a default budget
      if (category.budgetId !== 'all') {
        setSelectedBudgetId(category.budgetId);
      }
    }
  }, [category]);

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleTypeChange = (event) => {
    setFormData({ 
      ...formData, 
      isPercentage: event.target.value === 'percentage',
      amount: '' // Clear amount when changing type
    });
  };

  const handleIconSelect = (icon) => {
    setFormData({ ...formData, icon });
  };

  const handleSubmit = () => {
    // For combined categories, require budget selection
    if (category.budgetId === 'all' && !selectedBudgetId) {
      return; // Don't submit if no budget is selected
    }
    
    onSave({ 
      ...formData,
      _id: category._id,
      budgetId: selectedBudgetId || category.budgetId
    });
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ p: 0, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            Edit Category
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: 'text.secondary',
              '&:hover': { color: 'text.primary' }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Grid container spacing={3}>
          {category?.budgetId === 'all' && (
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Select Budget
              </Typography>
              <FormControl fullWidth error={!selectedBudgetId}>
                <StyledSelect
                  value={selectedBudgetId}
                  onChange={(e) => setSelectedBudgetId(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select a budget
                  </MenuItem>
                  {category.budgetIds.map((budgetId) => {
                    const budget = budgets?.find(b => b._id === budgetId);
                    if (!budget) return null;
                    return (
                      <MenuItem key={budgetId} value={budgetId}>
                        {budget.name}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
                {!selectedBudgetId && (
                  <FormHelperText>Please select a budget</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Category Name
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <IconSelector selectedIcon={formData.icon} onSelect={handleIconSelect} />
                <StyledTextField
                  fullWidth
                  value={formData.name}
                  onChange={handleChange('name')}
                  placeholder="Enter category name"
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Amount
              </Typography>
              <StyledTextField
                fullWidth
                value={formData.amount}
                onChange={handleChange('amount')}
                placeholder="Enter amount"
                type="number"
                InputProps={{
                  startAdornment: (
                    <TypeToggleButton
                      onClick={() => handleTypeChange({ target: { value: formData.isPercentage ? 'fixed' : 'percentage' } })}
                      disableRipple
                    >
                      {formData.isPercentage ? '%' : '$'}
                    </TypeToggleButton>
                  ),
                  endAdornment: formData.isPercentage ? (
                    <InputAdornment position="end">%</InputAdornment>
                  ) : null
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 0, mt: 4 }}>
        <StyledButton variant="outlined" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton 
          variant="contained" 
          onClick={handleSubmit}
          disabled={category?.budgetId === 'all' && !selectedBudgetId}
        >
          Save Changes
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default BudgetCategoryEditModal; 
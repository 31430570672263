import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  Avatar,
  IconButton,
  Switch,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Edit as EditIcon,
  PhotoCamera as PhotoCameraIcon
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useError } from '../contexts/ErrorContext';
import Layout from '../components/Layout';
import AccountSetup from '../components/AccountSetup';
import { getTrustedDevices, checkPINSetup, updateUser } from '../services/api';
import DeviceManager from '../components/DeviceManager';
import EditModal from '../components/EditModal';
import { toast } from 'react-hot-toast';
import SubscriptionSection from '../components/SubscriptionSection';
import SubscriptionVerificationModal from '../components/SubscriptionVerificationModal';
import { getCurrentSubscription } from '../services/subscriptionService';

const US_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

const StyledSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '20px',
  backgroundColor: '#ffffff',
  marginBottom: theme.spacing(3),
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
  position: 'relative'
}));

const FloatingMenu = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(4),
  backgroundColor: '#ffffff',
  width: `calc(100% - ${theme.spacing(4)})`,
  marginRight: theme.spacing(4),
  alignSelf: 'flex-start',
  boxShadow: 'none',
  borderRadius: '20px',
  padding: theme.spacing(2)
}));

const MenuLink = styled(ListItem)(({ theme, active }) => ({
  borderRadius: '0.5rem',
  marginBottom: theme.spacing(0.5),
  cursor: 'pointer',
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  transition: 'transform 80ms cubic-bezier(0, 0.3, 1, 0.5), background 80ms cubic-bezier(0, 0.3, 1, 0.5)',
  '&:hover': {
    backgroundColor: active ? `${theme.palette.primary.main}20` : 'rgba(0, 0, 0, 0.04)',
    borderRadius: '0.5rem'
  },
  '&:active': {
    transform: 'scale(0.95)',
  },
  ...(active && {
    backgroundColor: `${theme.palette.primary.main}20`,
  }),
  '& .MuiTypography-root': {
    fontWeight: active ? 900 : 600,
    fontSize: '1.1rem'
  }
}));

const AnchorPoint = styled('div')({
  position: 'absolute',
  top: '-80px',
  visibility: 'hidden',
  pointerEvents: 'none',
  width: '100%',
  height: '1px'
});

const Section = ({ id, title, children, onVisible }) => {
  const sectionRef = useRef(null);
  const [shouldObserve, setShouldObserve] = useState(false);

  // Add a slight delay before starting observations
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldObserve(true);
    }, 100); // Small delay to ensure initial render is complete

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!shouldObserve) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio >= 0.5) {
          onVisible(id);
        }
      },
      {
        threshold: 0.5,
        rootMargin: '-80px 0px' // Account for header
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [id, onVisible, shouldObserve]);

  return (
    <StyledSection ref={sectionRef}>
      <div id={id} style={{ position: 'absolute', top: 0 }} />
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 3 }}>
        {title}
      </Typography>
      {children}
    </StyledSection>
  );
};

const InfoField = ({ label, value, fieldType, canEdit = true, onEdit }) => (
  <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Box>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1">
        {value}
      </Typography>
    </Box>
    {canEdit && (
      <IconButton 
        size="small"
        onClick={() => onEdit(fieldType)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    )}
  </Box>
);

const Account = () => {
  const { 
    user, 
    subscription, 
    loadSubscription,
    fetchUserProfile,
    updateUser: updateAuthUser 
  } = useAuth();
  const navigate = useNavigate();
  const { showError, showSuccess } = useError();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editField, setEditField] = useState(null);
  const [devices, setDevices] = useState([]);
  const [pinRequired, setPinRequired] = useState(false);
  const [openPinModal, setOpenPinModal] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [activeSection, setActiveSection] = useState('basic-info');
  const [editModalConfig, setEditModalConfig] = useState({
    title: '',
    fields: [],
    open: false,
    onSave: () => {},
    initialValues: {},
    description: ''
  });
  const [verifyingSubscription, setVerifyingSubscription] = useState(false);
  const location = useLocation();
  const [isManualScroll, setIsManualScroll] = useState(false);
  const scrollTimeout = useRef(null);

  const memoizedFetchUserProfile = useMemo(() => fetchUserProfile, []);

  const [userData, setUserData] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
    address: user?.address || '',
    preferences: {
      appearance: {
        theme: user?.preferences?.appearance?.theme || 'auto'
      },
      notifications: {
        email: {
          enabled: user?.preferences?.notifications?.email?.enabled ?? true
        },
        phone: {
          enabled: user?.preferences?.notifications?.phone?.enabled ?? true
        }
      },
      ai: {
        confidenceThreshold: user?.preferences?.ai?.confidenceThreshold || 70
      }
    }
  });

  const handlePinModalClose = () => {
    setOpenPinModal(false);
  };

  const handlePinChange = () => {
    setOpenPinModal(true);
  };

  const handleEditField = (fieldType) => {
    const configs = {
      name: {
        title: 'Enter your first and last name',
        description: 'This name will be used across your budgeting experience and financial reports.',
        fields: [
          {
            name: 'firstName',
            label: 'First name',
            required: true,
            autoComplete: 'given-name'
          },
          {
            name: 'lastName',
            label: 'Last name',
            required: true,
            autoComplete: 'family-name'
          }
        ],
        initialValues: {
          firstName: user?.firstName || '',
          lastName: user?.lastName || ''
        },
        onSave: async (values) => {
          try {
            const response = await updateUser({
              firstName: values.firstName,
              lastName: values.lastName
            });
            // Update both local state and auth context with the returned user data
            const updatedUser = response.user;
            setUserData(prev => ({
              ...prev,
              ...updatedUser
            }));
            updateAuthUser(updatedUser);
            showSuccess('Name updated successfully');
          } catch (error) {
            console.error('Error updating name:', error);
            showError('Failed to update name. Please try again.');
          }
        }
      },
      phone: {
        title: 'Enter your phone number',
        fields: [
          {
            name: 'phone',
            label: 'Phone number',
            type: 'tel',
            required: true,
            autoComplete: 'tel'
          }
        ],
        initialValues: {
          phone: userData.phone
        },
        onSave: async (values) => {
          try {
            const response = await updateUser({
              phone: values.phone
            });
            // Update both local state and auth context with the returned user data
            const updatedUser = response.user;
            setUserData(prev => ({
              ...prev,
              ...updatedUser
            }));
            updateAuthUser(updatedUser);
            showSuccess('Phone number updated successfully');
          } catch (error) {
            console.error('Error updating phone:', error);
            showError('Failed to update phone number. Please try again.');
          }
        }
      },
      address: {
        title: 'Enter your address',
        description: 'Please enter your complete US mailing address.',
        fields: [
          {
            name: 'street1',
            label: 'Street Address',
            required: true,
            autoComplete: 'street-address'
          },
          {
            name: 'street2',
            label: 'Apt, Suite, etc. (optional)',
            autoComplete: 'address-line2'
          },
          {
            name: 'city',
            label: 'City',
            required: true,
            autoComplete: 'address-level2'
          },
          {
            isContainerField: true,
            containerSx: { 
              display: 'flex', 
              gap: 2,
              alignItems: 'flex-start',
              flexWrap: 'nowrap',
              width: '100%'
            },
            fields: [
              {
                name: 'state',
                label: 'State',
                type: 'select',
                required: true,
                autoComplete: 'address-level1',
                options: US_STATES,
                sx: { flex: 1 }
              },
              {
                name: 'zipCode',
                label: 'ZIP Code',
                required: true,
                autoComplete: 'postal-code',
                pattern: '^\\d{5}(-\\d{4})?$',
                patternError: 'Please enter a valid ZIP code',
                maxLength: 10,
                sx: { flex: 1 }
              }
            ]
          }
        ],
        initialValues: {
          street1: userData.address?.street1 || '',
          street2: userData.address?.street2 || '',
          city: userData.address?.city || '',
          state: userData.address?.state || '',
          zipCode: userData.address?.zipCode || ''
        },
        onSave: async (values) => {
          try {
            const response = await updateUser({
              address: {
                street1: values.street1,
                street2: values.street2,
                city: values.city,
                state: values.state,
                zipCode: values.zipCode
              }
            });
            // Update both local state and auth context with the returned user data
            const updatedUser = response.user;
            setUserData(prev => ({
              ...prev,
              ...updatedUser
            }));
            updateAuthUser(updatedUser);
            showSuccess('Address updated successfully');
          } catch (error) {
            console.error('Error updating address:', error);
            showError('Failed to update address. Please try again.');
          }
        }
      },
      confidenceThreshold: {
        title: 'Set Confidence Threshold',
        description: 'Set the confidence level required for automatic transaction categorization. Transactions with confidence below this threshold will require manual review.',
        fields: [
          {
            name: 'confidenceThreshold',
            label: 'Confidence Threshold (%)',
            type: 'number',
            required: true,
            inputProps: {
              min: 0,
              max: 100,
              step: 1
            }
          }
        ],
        initialValues: {
          confidenceThreshold: userData.preferences?.ai?.confidenceThreshold || 70
        },
        onSave: async (values) => {
          try {
            const response = await updateUser({
              preferences: {
                ...userData.preferences,
                ai: {
                  ...userData.preferences?.ai,
                  confidenceThreshold: parseInt(values.confidenceThreshold)
                }
              }
            });
            const updatedUser = response.user;
            setUserData(prev => ({
              ...prev,
              preferences: updatedUser.preferences
            }));
            updateAuthUser(updatedUser);
            showSuccess('Confidence threshold updated successfully');
          } catch (error) {
            console.error('Error updating confidence threshold:', error);
            showError('Failed to update confidence threshold. Please try again.');
          }
        }
      }
    };

    const config = configs[fieldType];
    if (config) {
      setEditModalConfig({
        open: true,
        ...config
      });
    }
  };

  const handleCloseEditModal = () => {
    setEditModalConfig(prev => ({
      ...prev,
      open: false
    }));
  };

  useEffect(() => {
    const initializeAccount = async () => {
      try {
        await memoizedFetchUserProfile();
        const pinStatus = await checkPINSetup();
        setPinRequired(pinStatus.required);
        const deviceList = await getTrustedDevices();
        setDevices(deviceList);
      } catch (error) {
        console.error('Error initializing account:', error);
        showError('Failed to load account data');
      }
    };

    if (user?.email) {
      initializeAccount();
    }
  }, [memoizedFetchUserProfile, showError, user?.email]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    let scrollEndTimer = null;

    const handleScroll = () => {
      if (scrollEndTimer) clearTimeout(scrollEndTimer);
      
      scrollEndTimer = setTimeout(() => {
        setIsManualScroll(false);
      }, 800); // Increased from 150ms to 800ms to allow smooth scroll to complete
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollEndTimer) clearTimeout(scrollEndTimer);
    };
  }, []);

  const handleSectionVisible = useCallback((sectionId) => {
    if (!isManualScroll) {
      setActiveSection(sectionId);
    }
  }, [isManualScroll]);

  const scrollToSection = useCallback((sectionId) => {
    const element = document.getElementById(sectionId);
    if (!element) return;

    // Clear any existing timeout
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    // Set manual scroll flag
    setIsManualScroll(true);
    
    // Set active section immediately when clicked
    setActiveSection(sectionId);
    
    // Scroll the element into view with offset for header
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
    
    // Additional offset for header
    window.scrollBy({
      top: -80,
      behavior: 'smooth'
    });

    // Reset manual scroll after animation completes
    scrollTimeout.current = setTimeout(() => {
      setIsManualScroll(false);
    }, 1000);
  }, []);

  const sections = useMemo(() => [
    { 
      id: 'basic-info',
      label: 'Basic info',
      render: () => (
        <Section 
          id="basic-info" 
          title="Basic Info" 
          onVisible={handleSectionVisible}
        >
          <InfoField 
            label="Full Name" 
            value={userData.firstName && userData.lastName ? `${userData.firstName} ${userData.lastName}` : 'Not set'} 
            fieldType="name"
            onEdit={handleEditField}
          />
          <InfoField 
            label="Email" 
            value={userData.email} 
            canEdit={false}
            onEdit={handleEditField}
          />
          <InfoField 
            label="Phone" 
            value={userData.phone || 'Not set'} 
            fieldType="phone"
            onEdit={handleEditField}
          />
          <InfoField 
            label="Address" 
            value={userData.address?.street1 ? [
              userData.address.street1,
              userData.address.street2,
              userData.address.city && userData.address.state ? 
                `${userData.address.city}, ${userData.address.state} ${userData.address.zipCode}` : ''
            ].filter(Boolean).join('\n') : 'Not set'} 
            fieldType="address"
            onEdit={handleEditField}
          />
        </Section>
      )
    },
    {
      id: 'subscription',
      label: 'Subscription',
      render: () => (
        <Section 
          id="subscription" 
          title="Subscription" 
          onVisible={handleSectionVisible}
        >
          <SubscriptionSection 
            subscription={subscription}
            onSubscriptionChange={() => loadSubscription(true)}
          />
        </Section>
      )
    },
    {
      id: 'notifications',
      label: 'Notifications',
      render: () => (
        <Section 
          id="notifications" 
          title="Notifications" 
          onVisible={handleSectionVisible}
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Phone and email
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Choose how you'd like to receive notifications
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Typography>{userData.email}</Typography>
                <Switch 
                  checked={userData.preferences.notifications.email.enabled}
                  onChange={(e) => {
                    setUserData(prev => ({
                      ...prev,
                      preferences: {
                        ...prev.preferences,
                        notifications: {
                          ...prev.preferences.notifications,
                          email: { enabled: e.target.checked }
                        }
                      }
                    }));
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>{userData.phone}</Typography>
                <Switch 
                  checked={userData.preferences.notifications.phone.enabled}
                  onChange={(e) => {
                    setUserData(prev => ({
                      ...prev,
                      preferences: {
                        ...prev.preferences,
                        notifications: {
                          ...prev.preferences.notifications,
                          phone: { enabled: e.target.checked }
                        }
                      }
                    }));
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Transaction Categorization
            </Typography>
            <InfoField 
              label="Confidence Threshold" 
              value={`${userData.preferences?.ai?.confidenceThreshold || 70}%`}
              fieldType="confidenceThreshold"
              onEdit={handleEditField}
            />
          </Box>
        </Section>
      )
    },
    {
      id: 'appearance',
      label: 'Appearance',
      render: () => (
        <Section 
          id="appearance" 
          title="Appearance" 
          onVisible={handleSectionVisible}
        >
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  mr: 1
                }}
              />
              <Typography>Auto</Typography>
              <Switch 
                checked={userData.preferences.appearance.theme === 'auto'}
                onChange={(e) => {
                  setUserData(prev => ({
                    ...prev,
                    preferences: {
                      ...prev.preferences,
                      appearance: {
                        theme: e.target.checked ? 'auto' : 'light'
                      }
                    }
                  }));
                }}
              />
            </Box>
          </Box>
        </Section>
      )
    },
    {
      id: 'security',
      label: 'Security and privacy',
      render: () => (
        <Section 
          id="security" 
          title="Security and Privacy" 
          onVisible={handleSectionVisible}
        >
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              PIN Management
            </Typography>
            <Button
              variant="outlined"
              onClick={handlePinChange}
              sx={{ mb: 2 }}
            >
              Change Your PIN
            </Button>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
              Trusted Devices
            </Typography>
            <DeviceManager 
              devices={devices} 
              onDeviceRemoved={(fingerprint) => {
                setDevices(devices.filter(device => device.fingerprint !== fingerprint));
              }} 
            />
          </Box>
        </Section>
      )
    }
  ], [userData, subscription, devices, handlePinChange, loadSubscription, handleEditField, handleSectionVisible]);

  const SideMenu = () => (
    <FloatingMenu>
      <List>
        {sections.map((section) => (
          <MenuLink
            key={section.id}
            active={activeSection === section.id}
            onClick={() => scrollToSection(section.id)}
            disableRipple
          >
            <ListItemText 
              primary={section.label}
              primaryTypographyProps={{
                fontWeight: activeSection === section.id ? 900 : 600,
                fontSize: '0.95rem',
                color: 'inherit'
              }}
            />
          </MenuLink>
        ))}
      </List>
    </FloatingMenu>
  );

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 400;
          const MAX_HEIGHT = 400;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convert to JPEG with 0.8 quality
          const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.8);
          resolve(compressedDataUrl);
        };
      };
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check if file is an image
    if (!file.type.startsWith('image/')) {
      toast.error('Please select an image file.');
      return;
    }

    try {
      const compressedImage = await compressImage(file);
      const { user: updatedUser } = await updateUser({ profileImage: compressedImage });
      
      setUserData(prev => ({
        ...prev,
        profileImage: updatedUser.profileImage
      }));
      
      updateAuthUser(updatedUser);
      toast.success('Profile image updated successfully');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to update profile image');
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    
    if (sessionId) {
      setVerifyingSubscription(true);
    }
  }, [location.search]);

  const handleVerificationComplete = async () => {
    await loadSubscription(true); // Force reload subscription after verification
    window.history.replaceState({}, '', '/account');
    setVerifyingSubscription(false);
  };

  return (
    <Layout>
      <Container 
        maxWidth="lg" 
        sx={{
          px: { xs: 2, md: 4 }
        }}
      >
        <Box py={4}>
          <Box sx={{ 
            bgcolor: 'background.paper',
            borderRadius: '20px',
            p: 3,
            boxShadow: 'none',
            mb: 3
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
              <Box sx={{ position: 'relative' }}>
                <Avatar
                  src={profileImage || user?.profileImage}
                  sx={{
                    width: 100,
                    height: 100,
                    mb: 2,
                    border: '2px solid',
                    borderColor: 'primary.main'
                  }}
                />
                <input
                  accept="image/*"
                  type="file"
                  id="profile-image-upload"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="profile-image-upload">
                  <IconButton
                    component="span"
                    sx={{
                      position: 'absolute',
                      bottom: 16,
                      right: -8,
                      backgroundColor: 'primary.main',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    }}
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
              </Box>
              {(!userData.firstName || !userData.lastName) ? (
                <Typography 
                  variant="body1" 
                  color="text.secondary"
                  sx={{ 
                    textAlign: 'center',
                    maxWidth: 300,
                    mb: 2
                  }}
                >
                  Hey there! We'd love to get to know you better. Share a bit about yourself below to make this space truly yours.
                </Typography>
              ) : (
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {`${userData.firstName} ${userData.lastName}`}
                </Typography>
              )}
            </Box>
          </Box>

          <Grid container spacing={0}>
            <Grid 
              item 
              xs={12} 
              md={3}
              sx={{
                display: { xs: 'block', md: 'none' },
                mb: 3
              }}
            >
              <SideMenu />
            </Grid>

            <Grid 
              item 
              xs={12} 
              md={9}
              sx={{
                pr: { xs: 0, md: 4 }
              }}
            >
              {sections.map(section => (
                <Box key={section.id}>
                  {section.render()}
                </Box>
              ))}
            </Grid>

            <Grid 
              item 
              xs={12} 
              md={3}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              <SideMenu />
            </Grid>
          </Grid>
        </Box>

        {openPinModal && (
          <AccountSetup
            open={openPinModal}
            onClose={handlePinModalClose}
            isChangingPin={true}
          />
        )}

        <EditModal
          open={editModalConfig.open}
          onClose={handleCloseEditModal}
          title={editModalConfig.title}
          fields={editModalConfig.fields}
          onSave={editModalConfig.onSave}
          initialValues={editModalConfig.initialValues}
          description={editModalConfig.description}
        />

        <SubscriptionVerificationModal
          open={verifyingSubscription}
          onClose={() => {
            setVerifyingSubscription(false);
            window.history.replaceState({}, '', '/account');
          }}
          sessionId={new URLSearchParams(location.search).get('session_id')}
          onVerificationComplete={handleVerificationComplete}
        />
      </Container>
    </Layout>
  );
};

export default Account;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Container,
  Grid,
  IconButton,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Alert,
  Tabs,
  Tab
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  TrendingUp as TrendingUpIcon,
  AccountBalance as AccountBalanceIcon,
  Savings as SavingsIcon,
  Home as HomeIcon,
  DirectionsCar as CarIcon,
  Restaurant as FoodIcon,
  LocalHospital as HealthIcon,
  ShoppingCart as ShoppingCartIcon,
  School as EducationIcon,
  Theaters as EntertainmentIcon,
  Power as UtilitiesIcon,
  Category as OtherIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Layout from '../components/Layout';
import BudgetForm from '../components/BudgetForm';
import { colors } from '../styles';
import * as budgetService from '../services/budgetService';
import BudgetCategoryEditModal from '../components/BudgetCategoryEditModal';
import BudgetSelectorModal from '../components/BudgetSelectorModal';
import MonthSelector from '../components/MonthSelector';
import BudgetCategoryDetailsModal from '../components/BudgetCategoryDetailsModal';
import CountUp from 'react-countup';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: '20px',
  backgroundColor: colors.white,
  boxShadow: 'none',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: 'none'
  }
}));

const ProgressBar = styled(Box)(({ theme, value }) => ({
  height: 8,
  width: '100%',
  borderRadius: 4,
  backgroundColor: `${colors.primary}20`,
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: `${Math.min(100, value)}%`,
    backgroundColor: value > 100 ? colors.status.error : colors.primary,
    borderRadius: 4,
    transition: 'width 0.5s ease-in-out'
  }
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${colors.primary}10`,
  marginBottom: theme.spacing(2),
  '& svg': {
    color: colors.primary,
    fontSize: '24px'
  }
}));

const StatCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '24px',
  backgroundColor: colors.white,
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  position: 'relative',
  overflow: 'hidden',
  border: `2px solid ${colors.primary}10`,
  transition: 'all 0.3s ease-in-out',
  minHeight: '160px',
  '&:hover': {
    transform: 'translateY(-4px)',
    border: `2px solid ${colors.primary}20`,
    '& .MuiSvgIcon-root': {
      transform: 'scale(1.1)'
    }
  }
}));

const StatIcon = styled(Box)(({ theme }) => ({
  width: 56,
  height: 56,
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${colors.primary}10`,
  transition: 'all 0.3s ease-in-out',
  '& .MuiSvgIcon-root': {
    fontSize: '28px',
    color: colors.primary,
    transition: 'all 0.3s ease-in-out'
  }
}));

const BudgetCard = styled(Card)(({ theme, active }) => ({
  borderRadius: '12px',
  padding: theme.spacing(2),
  cursor: 'pointer',
  backgroundColor: active ? `${colors.primary}10` : colors.white,
  border: active ? `2px solid ${colors.primary}` : '2px solid transparent',
  transition: 'all 0.2s ease-in-out',
  boxShadow: 'none',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: 'none'
  }
}));

const ICONS = {
  home: <HomeIcon />,
  car: <CarIcon />,
  food: <FoodIcon />,
  health: <HealthIcon />,
  shopping: <ShoppingCartIcon />,
  education: <EducationIcon />,
  entertainment: <EntertainmentIcon />,
  utilities: <UtilitiesIcon />,
  other: <OtherIcon />
};

const Budgets = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [activeBudget, setActiveBudget] = useState(null);
  const [insights, setInsights] = useState(null);
  const [showBudgetForm, setShowBudgetForm] = useState(false);
  const [editingBudget, setEditingBudget] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCategoryEditModalOpen, setIsCategoryEditModalOpen] = useState(false);
  const [showBudgetSelector, setShowBudgetSelector] = useState(false);
  const [selectedCategoryForBudgetChoice, setSelectedCategoryForBudgetChoice] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [availableMonths, setAvailableMonths] = useState([]);
  const [selectedCategoryForDetails, setSelectedCategoryForDetails] = useState(null);

  const fetchBudgets = async () => {
    try {
      setLoading(true);
      const response = await budgetService.getBudgets();
      setBudgets(response);
    } catch (err) {
      console.error('Error fetching budgets:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  const getAvailableMonths = () => {
    // Return months from October 2023 to current month
    const months = [];
    const startDate = new Date(2023, 9); // October 2023 (0-based months)
    const endDate = new Date();
    
    for (let d = startDate; d <= endDate; d.setMonth(d.getMonth() + 1)) {
      const monthStr = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}`;
      months.push(monthStr);
    }
    
    return months.sort().reverse(); // Most recent first
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        const response = await budgetService.getBudgets();
        setBudgets(response);

        // Get available months
        const months = getAvailableMonths();
        setAvailableMonths(months);
        
        // Set the most recent month as selected if we have months
        if (months.length > 0) {
          const selectedMonth = months[0];
          setSelectedMonth(selectedMonth);

          // Fetch insights for all budgets with the selected month
          const allInsights = { budgetInsights: {} };
          await Promise.all(response.map(async (budget) => {
            const insightsResponse = await budgetService.getBudgetInsights(budget._id, { month: selectedMonth });
            allInsights.budgetInsights[budget._id] = insightsResponse.data;
          }));
          setInsights(allInsights);

          // If there's only one budget, set it as active and set its insights
          if (response.length === 1) {
            setActiveBudget(response[0]);
            const singleBudgetInsights = await budgetService.getBudgetInsights(response[0]._id, { month: selectedMonth });
            setInsights({ budgetInsights: { [response[0]._id]: singleBudgetInsights.data } });
          }
        }
      } catch (err) {
        console.error('Error loading initial data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  const handleCreateBudget = async (budgetData) => {
    try {
      const response = await budgetService.createBudget(budgetData);
      setShowBudgetForm(false);
      await fetchBudgets();
      // Set as active budget and fetch insights
      setActiveBudget(response.data);
      const insightsResponse = await budgetService.getBudgetInsights(response.data._id, { month: selectedMonth });
      setInsights({ budgetInsights: { [response.data._id]: insightsResponse.data } });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleUpdateBudget = async (budgetData) => {
    try {
      await budgetService.updateBudget(editingBudget._id, budgetData);
      setEditingBudget(null);
      await fetchBudgets();
      // Refresh insights if this is the active budget
      if (activeBudget?._id === editingBudget._id) {
        const insightsResponse = await budgetService.getBudgetInsights(editingBudget._id, { month: selectedMonth });
        setInsights({ budgetInsights: { [editingBudget._id]: insightsResponse.data } });
      } else {
        // If no active budget, refresh all insights
        const allInsights = { budgetInsights: {} };
        await Promise.all(budgets.map(async (b) => {
          const insightsResponse = await budgetService.getBudgetInsights(b._id, { month: selectedMonth });
          allInsights.budgetInsights[b._id] = insightsResponse.data;
        }));
        setInsights(allInsights);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEditClick = (budget) => {
    setEditingBudget(budget);
  };

  const handleDeleteBudget = async (budgetId) => {
    try {
      await budgetService.deleteBudget(budgetId);
      setEditingBudget(null);
      // Clear insights if this was the active budget
      if (activeBudget?._id === budgetId) {
        setActiveBudget(null);
        setInsights(null);
      }
      await fetchBudgets();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCategoryEditClick = (budget, category) => {
    // If a specific budget is provided, use it directly
    if (budget) {
      setSelectedCategory({ ...category, budgetId: budget._id });
      setIsCategoryEditModalOpen(true);
      return;
    }

    // For combined categories, check how many budgets have this category
    const budgetsWithCategory = budgets.filter(b => 
      b.categories?.some(c => c.name === category.name)
    );

    // If category exists in only one budget, edit it directly
    if (budgetsWithCategory.length === 1) {
      const budgetCategory = budgetsWithCategory[0].categories.find(c => c.name === category.name);
      setSelectedCategory({ ...budgetCategory, budgetId: budgetsWithCategory[0]._id });
      setIsCategoryEditModalOpen(true);
      return;
    }

    // Otherwise, show budget selector
    setSelectedCategoryForBudgetChoice(category);
    setShowBudgetSelector(true);
  };

  const handleBudgetSelect = (budget, budgetCategory) => {
    setShowBudgetSelector(false);
    setSelectedCategory({ ...budgetCategory, budgetId: budget._id });
    setIsCategoryEditModalOpen(true);
  };

  const handleCategoryUpdate = async (updatedCategory) => {
    try {
      if (selectedCategory.budgetId === 'all') {
        // Update the category in all relevant budgets
        await Promise.all(selectedCategory.budgetIds.map(budgetId =>
          budgetService.updateBudgetCategory(
            budgetId,
            selectedCategory._id,
            updatedCategory
          )
        ));
      } else {
        // Update in single budget
        await budgetService.updateBudgetCategory(
          selectedCategory.budgetId,
          selectedCategory._id,
          updatedCategory
        );
      }
      
      setIsCategoryEditModalOpen(false);
      // Refresh budgets
      await fetchBudgets();
      
      // Refresh insights with current month
      const allInsights = { budgetInsights: {} };
      await Promise.all(budgets.map(async (budget) => {
        const insightsResponse = await budgetService.getBudgetInsights(budget._id, { month: selectedMonth });
        allInsights.budgetInsights[budget._id] = insightsResponse.data;
      }));
      setInsights(allInsights);
      
    } catch (error) {
      console.error('Failed to update category:', error);
      setError(error.message);
    }
  };

  const getCategoryIcon = (categoryName) => {
    const name = categoryName.toLowerCase();
    if (name.includes('home') || name.includes('rent') || name.includes('mortgage')) return <HomeIcon />;
    if (name.includes('car') || name.includes('transport')) return <CarIcon />;
    if (name.includes('food') || name.includes('grocery') || name.includes('restaurant')) return <FoodIcon />;
    if (name.includes('health') || name.includes('medical')) return <HealthIcon />;
    if (name.includes('shopping')) return <ShoppingCartIcon />;
    if (name.includes('education') || name.includes('school')) return <EducationIcon />;
    if (name.includes('entertainment') || name.includes('fun')) return <EntertainmentIcon />;
    if (name.includes('utilities') || name.includes('bills')) return <UtilitiesIcon />;
    if (name.includes('savings') || name.includes('investment')) return <SavingsIcon />;
    return <OtherIcon />;
  };

  const handleCategoryClick = async (budget, category) => {
    try {
      if (!budget && activeBudget) {
        // If no budget provided but we have an active budget, use that
        setSelectedCategoryForDetails({
          ...category,
          budgetId: activeBudget._id
        });
        // Fetch fresh insights for the selected month
        const insightsResponse = await budgetService.getBudgetInsights(activeBudget._id, { month: selectedMonth });
        setInsights({ budgetInsights: { [activeBudget._id]: insightsResponse.data } });
      } else if (budget) {
        // If budget is provided, use it
        setSelectedCategoryForDetails({
          ...category,
          budgetId: budget._id
        });
        // Fetch fresh insights for the selected month
        const insightsResponse = await budgetService.getBudgetInsights(budget._id, { month: selectedMonth });
        setInsights({ budgetInsights: { [budget._id]: insightsResponse.data } });
      }
    } catch (err) {
      console.error('Error fetching category insights:', err);
      setError(err.message);
    }
  };

  const renderCategoryCard = (budget, category) => {
    const filteredInsights = getFilteredInsights();
    const categorySpent = filteredInsights?.budgetInsights?.[budget._id]?.categorySpent?.[category._id] || 0;
    const categoryAmount = category.isPercentage 
      ? ((Number(category.amount || 0) / 100) * Number(budget.totalBudget || 0))
      : Number(category.amount || 0);
    const spentPercentage = categoryAmount > 0 ? (categorySpent / categoryAmount) * 100 : 0;

    return (
      <Grid item xs={12} sm={6} md={4} key={category._id}>
        <StyledCard 
          onClick={() => handleCategoryClick(budget, category)}
          sx={{ cursor: 'pointer' }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <CategoryIcon>
                  {getCategoryIcon(category.name)}
                </CategoryIcon>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {category.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {category.isPercentage 
                    ? `${Number(category.amount)}% ($${((Number(category.amount) / 100) * Number(budget?.totalBudget || 0)).toLocaleString()})`
                    : `$${Number(category.amount).toLocaleString()}`
                  }
                </Typography>
              </Box>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCategoryEditClick(budget, category);
                }}
                sx={{
                  color: colors.primary,
                  backgroundColor: `${colors.primary}10`,
                  '&:hover': {
                    backgroundColor: `${colors.primary}20`
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Spent: ${categorySpent.toLocaleString()}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {categoryAmount > 0 ? `${spentPercentage.toFixed(1)}%` : '0%'}
                </Typography>
              </Box>
              <ProgressBar value={spentPercentage} />
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>
    );
  };

  const getFilteredInsights = () => {
    // Since insights are now fetched with the month parameter,
    // we can return them directly as they're already filtered
    return insights;
  };

  const getAllCategories = () => {
    if (!budgets || !Array.isArray(budgets)) {
      return [];
    }
    
    const allCategories = [];
    const seenNames = new Set();
    const filteredInsights = getFilteredInsights();
    
    budgets.forEach(budget => {
      if (!budget.categories) return;
      budget.categories.forEach(category => {
        if (!seenNames.has(category.name)) {
          seenNames.add(category.name);
          
          const categoryData = budgets.reduce((acc, b) => {
            const matchingCategory = b.categories?.find(c => c.name === category.name);
            if (matchingCategory) {
              const amount = matchingCategory.isPercentage 
                ? ((Number(matchingCategory.amount) / 100) * Number(b.totalBudget))
                : Number(matchingCategory.amount);
              acc.totalAmount += amount;
              
              const budgetInsights = filteredInsights?.budgetInsights?.[b._id];
              const spent = budgetInsights?.categorySpent?.[matchingCategory._id] || 0;
              acc.totalSpent += spent;
            }
            return acc;
          }, { totalAmount: 0, totalSpent: 0 });

          allCategories.push({
            ...category,
            totalAmount: categoryData.totalAmount,
            totalSpent: categoryData.totalSpent,
            budgetCount: budgets.filter(b => 
              b.categories?.some(c => c.name === category.name)
            ).length
          });
        }
      });
    });

    return allCategories.sort((a, b) => b.totalAmount - a.totalAmount);
  };

  const getTotalStats = () => {
    if (!budgets || !selectedMonth) return {
      totalBudget: 0,
      totalAllocated: 0,
      remaining: 0,
      percentageUsed: 0,
      avgTransaction: 0,
      transactionCount: 0
    };

    const totalBudget = budgets.reduce((sum, budget) => sum + Number(budget.totalBudget || 0), 0);
    const totalAllocated = budgets.reduce((sum, budget) => {
      return sum + (budget.categories || []).reduce((catSum, cat) => {
        const amount = cat.isPercentage
          ? (Number(cat.amount || 0) / 100) * Number(budget.totalBudget || 0)
          : Number(cat.amount || 0);
        return catSum + amount;
      }, 0);
    }, 0);

    let totalSpent = 0;
    let allTransactions = 0;

    const filteredInsights = getFilteredInsights();
    
    if (activeBudget && filteredInsights?.budgetInsights?.[activeBudget._id]) {
      // Single budget insights
      const budgetInsight = filteredInsights.budgetInsights[activeBudget._id];
      totalSpent = Object.values(budgetInsight.categorySpent || {}).reduce((sum, spent) => sum + spent, 0);
      allTransactions = budgetInsight.totalTransactions || 0;
    } else if (filteredInsights?.budgetInsights) {
      // All budgets insights
      Object.values(filteredInsights.budgetInsights).forEach(budgetInsight => {
        totalSpent += Object.values(budgetInsight.categorySpent || {}).reduce((sum, spent) => sum + spent, 0);
        allTransactions += budgetInsight.totalTransactions || 0;
      });
    }

    const remaining = totalAllocated - totalSpent;
    const avgTransaction = allTransactions > 0 ? totalSpent / allTransactions : 0;

    return {
      totalBudget,
      totalAllocated,
      remaining,
      percentageUsed: totalAllocated > 0 ? (totalSpent / totalAllocated) * 100 : 0,
      avgTransaction,
      transactionCount: allTransactions
    };
  };

  const handleBudgetClick = async (budget) => {
    try {
      if (activeBudget?._id === budget._id) {
        // If clicking the same budget, deselect it and show all budgets' insights
        setActiveBudget(null);
        const allInsights = { budgetInsights: {} };
        await Promise.all(budgets.map(async (b) => {
          const insightsResponse = await budgetService.getBudgetInsights(b._id, { month: selectedMonth });
          allInsights.budgetInsights[b._id] = insightsResponse.data;
        }));
        setInsights(allInsights);
      } else {
        // If selecting a different budget, update active budget and get its insights
        setActiveBudget(budget);
        const insightsResponse = await budgetService.getBudgetInsights(budget._id, { month: selectedMonth });
        setInsights({ budgetInsights: { [budget._id]: insightsResponse.data } });
      }
    } catch (err) {
      console.error('Error updating budget insights:', err);
      setError(err.message);
    }
  };

  // Update the useEffect for month changes
  useEffect(() => {
    const refreshInsights = async () => {
      if (!selectedMonth || !budgets.length) return;

      try {
        if (activeBudget) {
          // If we have an active budget, only fetch insights for that budget
          const insightsResponse = await budgetService.getBudgetInsights(activeBudget._id, { month: selectedMonth });
          setInsights({ budgetInsights: { [activeBudget._id]: insightsResponse.data } });
        } else {
          // Otherwise fetch insights for all budgets
          const allInsights = { budgetInsights: {} };
          await Promise.all(budgets.map(async (budget) => {
            const insightsResponse = await budgetService.getBudgetInsights(budget._id, { month: selectedMonth });
            allInsights.budgetInsights[budget._id] = insightsResponse.data;
          }));
          setInsights(allInsights);
        }
      } catch (err) {
        console.error('Error refreshing insights:', err);
        setError(err.message);
      }
    };

    refreshInsights();
  }, [selectedMonth, budgets, activeBudget]);

  if (loading) {
    return (
      <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxWidth="lg">
        <Box sx={{ py: 4 }}>
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {/* Stats Cards */}
          <Grid container spacing={3} sx={{ mb: 5 }}>
            <Grid item xs={12} md={4}>
              <StatCard>
                <StatIcon>
                  <AccountBalanceIcon />
                </StatIcon>
                <Box sx={{ flex: 1 }}>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 1,
                      fontSize: '0.875rem',
                      letterSpacing: '0.1px'
                    }}
                  >
                    Total Budget
                  </Typography>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      fontWeight: 700,
                      color: colors.primary,
                      letterSpacing: '-0.5px',
                      mb: 0.5
                    }}
                  >
                    $<CountUp
                      end={getTotalStats().totalBudget}
                      decimals={2}
                      duration={1}
                      separator=","
                      preserveValue={true}
                    />
                  </Typography>
                </Box>
              </StatCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard>
                <StatIcon>
                  <TrendingUpIcon />
                </StatIcon>
                <Box sx={{ flex: 1 }}>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 1,
                      fontSize: '0.875rem',
                      letterSpacing: '0.1px'
                    }}
                  >
                    Total Spent
                  </Typography>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      fontWeight: 700,
                      color: colors.primary,
                      letterSpacing: '-0.5px',
                      mb: 0.5
                    }}
                  >
                    $<CountUp
                      end={getTotalStats().totalAllocated - getTotalStats().remaining}
                      decimals={2}
                      duration={1}
                      separator=","
                      preserveValue={true}
                    />
                  </Typography>
                </Box>
              </StatCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard>
                <StatIcon>
                  <SavingsIcon />
                </StatIcon>
                <Box sx={{ flex: 1 }}>
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 1,
                      fontSize: '0.875rem',
                      letterSpacing: '0.1px'
                    }}
                  >
                    Remaining
                  </Typography>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      fontWeight: 700,
                      color: colors.primary,
                      letterSpacing: '-0.5px',
                      mb: 0.5
                    }}
                  >
                    $<CountUp
                      end={getTotalStats().remaining}
                      decimals={2}
                      duration={1}
                      separator=","
                      preserveValue={true}
                    />
                  </Typography>
                </Box>
              </StatCard>
            </Grid>
          </Grid>

          {/* Budget Selection */}
          {Array.isArray(budgets) && budgets.length > 0 && (
            <Box sx={{ mb: 4 }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 2,
                gap: 2
              }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  Your Budgets
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {availableMonths.length > 0 && (
                    <MonthSelector
                      selectedMonth={selectedMonth}
                      availableMonths={availableMonths}
                      onMonthChange={setSelectedMonth}
                    />
                  )}
                  {activeBudget && (
                    <Button
                      size="small"
                      startIcon={<CloseIcon />}
                      onClick={async () => {
                        try {
                          setActiveBudget(null);
                          const allInsights = { budgetInsights: {} };
                          await Promise.all(budgets.map(async (b) => {
                            const insightsResponse = await budgetService.getBudgetInsights(b._id, { month: selectedMonth });
                            allInsights.budgetInsights[b._id] = insightsResponse.data;
                          }));
                          setInsights(allInsights);
                        } catch (err) {
                          console.error('Error refreshing all budget insights:', err);
                          setError(err.message);
                        }
                      }}
                      sx={{
                        color: 'text.secondary',
                        '&:hover': {
                          backgroundColor: `${colors.primary}10`
                        }
                      }}
                    >
                      Show All
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setShowBudgetForm(true)}
                  >
                    Create Budget
                  </Button>
                </Box>
              </Box>
              <Grid container spacing={2}>
                {budgets.map((budget) => (
                  <Grid item xs={12} sm={6} md={4} key={budget._id}>
                    <BudgetCard 
                      active={activeBudget?._id === budget._id}
                      onClick={() => handleBudgetClick(budget)}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Box>
                          <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            {budget.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            {budget.period.charAt(0).toUpperCase() + budget.period.slice(1)} Budget
                          </Typography>
                          <Typography variant="h6" sx={{ mt: 1, color: colors.primary }}>
                            ${Number(budget.totalBudget).toLocaleString()}
                          </Typography>
                        </Box>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(budget);
                          }}
                          sx={{
                            color: colors.primary,
                            backgroundColor: `${colors.primary}10`,
                            '&:hover': {
                              backgroundColor: `${colors.primary}20`
                            }
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </BudgetCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {/* Active Budget View or All Categories */}
          {activeBudget ? (
            <>
              <Typography variant="h6" sx={{ mb: 3, fontWeight: 700 }}>
                Budget Categories
              </Typography>
              <Grid container spacing={3}>
                {(activeBudget.categories || []).map((category) => {
                  return renderCategoryCard(activeBudget, category);
                })}
              </Grid>
            </>
          ) : Array.isArray(budgets) && budgets.length > 0 ? (
            <>
              <Typography variant="h6" sx={{ mb: 3, fontWeight: 700 }}>
                All Categories
              </Typography>
              <Grid container spacing={3}>
                {getAllCategories().map((category) => {
                  const spentPercentage = (category.totalSpent / category.totalAmount) * 100;
                  
                  return (
                    <Grid item xs={12} sm={6} md={4} key={category._id}>
                      <StyledCard>
                        <CardContent>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <Box>
                              <CategoryIcon>
                                {ICONS[category.icon] || ICONS.other}
                              </CategoryIcon>
                              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                {category.name}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                ${category.totalAmount.toLocaleString()} total
                              </Typography>
                            </Box>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCategoryEditClick(null, category);
                              }}
                              sx={{
                                color: colors.primary,
                                backgroundColor: `${colors.primary}10`,
                                '&:hover': {
                                  backgroundColor: `${colors.primary}20`
                                }
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                              <Typography variant="body2" color="text.secondary">
                                Spent: ${category.totalSpent.toLocaleString()}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {spentPercentage.toFixed(1)}%
                              </Typography>
                            </Box>
                            <ProgressBar value={spentPercentage} />
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                              {budgets
                                .filter(b => b.categories?.some(c => c.name === category.name))
                                .map(b => (
                                  <Chip
                                    key={b._id}
                                    label={b.name}
                                    size="small"
                                    onClick={async () => {
                                      try {
                                        setActiveBudget(b);
                                        const insightsResponse = await budgetService.getBudgetInsights(b._id, { month: selectedMonth });
                                        setInsights({ budgetInsights: { [b._id]: insightsResponse.data } });
                                      } catch (err) {
                                        console.error('Error updating insights:', err);
                                        setError(err.message);
                                      }
                                    }}
                                    sx={{
                                      backgroundColor: `${colors.primary}10`,
                                      color: colors.primary,
                                      '&:hover': {
                                        backgroundColor: `${colors.primary}20`
                                      }
                                    }}
                                  />
                              ))}
                            </Box>
                          </Box>
                        </CardContent>
                      </StyledCard>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <Box sx={{ 
              textAlign: 'center', 
              py: 8,
              backgroundColor: `${colors.primary}05`,
              borderRadius: '20px'
            }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                No budgets yet
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Create your first budget to start tracking your expenses
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setEditingBudget(null);
                  setShowBudgetForm(true);
                }}
              >
                Create Budget
              </Button>
            </Box>
          )}
        </Box>
      </Container>

      <BudgetForm
        open={showBudgetForm || !!editingBudget}
        onClose={() => {
          setShowBudgetForm(false);
          setEditingBudget(null);
        }}
        onSubmit={editingBudget ? handleUpdateBudget : handleCreateBudget}
        onDelete={handleDeleteBudget}
        initialData={editingBudget}
      />

      <BudgetSelectorModal
        open={showBudgetSelector}
        onClose={() => {
          setShowBudgetSelector(false);
          setSelectedCategoryForBudgetChoice(null);
        }}
        budgets={budgets}
        category={selectedCategoryForBudgetChoice}
        onBudgetSelect={handleBudgetSelect}
      />

      {selectedCategory && (
        <BudgetCategoryEditModal
          open={isCategoryEditModalOpen}
          onClose={() => setIsCategoryEditModalOpen(false)}
          category={selectedCategory}
          onSave={handleCategoryUpdate}
        />
      )}

      {selectedCategoryForDetails && (
        <BudgetCategoryDetailsModal
          open={!!selectedCategoryForDetails}
          onClose={() => setSelectedCategoryForDetails(null)}
          category={selectedCategoryForDetails}
          budget={activeBudget}
          insights={insights}
          selectedMonth={selectedMonth}
          budgets={budgets}
        />
      )}
    </Layout>
  );
};

export default Budgets;

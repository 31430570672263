import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { api } from '../services/api';
import { colors } from '../styles';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  padding: '8px 24px',
  backgroundColor: colors.primary,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.primary,
    opacity: 0.9
  },
  '&:disabled': {
    backgroundColor: `${colors.primary}50`,
    color: colors.white
  }
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: colors.status.error,
  marginTop: theme.spacing(1),
  textAlign: 'center',
}));

const PlaidLink = ({ onSuccess, onExit }) => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getToken = async () => {
      try {
        setError(null);
        const response = await api.post('/plaid/create_link_token');
        if (response.data.link_token) {
          setToken(response.data.link_token);
        } else {
          throw new Error('No link token in response');
        }
      } catch (err) {
        console.error('Error creating link token:', err);
        setError('Failed to initialize Plaid. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    getToken();
  }, []);

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: (public_token, metadata) => {
      setLoading(true);
      setError(null);
      api.post('/plaid/exchange_public_token', { 
        public_token,
        institution: metadata.institution,
        accounts: metadata.accounts
      })
        .then(() => {
          onSuccess();
        })
        .catch((err) => {
          console.error('Error exchanging token:', err);
          setError('Failed to connect account. Please try again.');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    onExit: (err, metadata) => {
      if (err) {
        console.error('Plaid Link error:', err);
        setError('Connection failed. Please try again.');
      }
      onExit?.(err);
    },
  });

  return (
    <Box>
      <StyledButton
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => {
          setError(null);
          open();
        }}
        disabled={!ready || loading}
      >
        Add Account
      </StyledButton>
      {loading && !token && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Box>
  );
};

export default PlaidLink;
import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { format } from 'date-fns';
import { colors } from '../styles';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';

const MonthSelector = ({ selectedMonth, availableMonths, onMonthChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMonthSelect = (month) => {
    onMonthChange(month);
    handleClose();
  };

  return (
    <>
      <Button
        size="small"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        sx={{
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: `${colors.primary}10`
          }
        }}
      >
        {format(new Date(`${selectedMonth}-15`), 'MMMM yyyy')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            boxShadow: 'none',
            border: `1px solid ${colors.primary}10`,
            borderRadius: '12px',
            backgroundColor: colors.white
          }
        }}
      >
        {availableMonths.map((month) => (
          <MenuItem
            key={month}
            onClick={() => handleMonthSelect(month)}
            selected={month === selectedMonth}
            sx={{
              fontSize: '0.875rem',
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: `${colors.primary}10`,
                color: colors.primary
              },
              '&.Mui-selected': {
                backgroundColor: `${colors.primary}10`,
                color: colors.primary,
                '&:hover': {
                  backgroundColor: `${colors.primary}20`
                }
              }
            }}
          >
            {format(new Date(`${month}-15`), 'MMMM yyyy')}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MonthSelector; 
import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import { Close as CloseIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { StyledDialog, StyledButton } from './common/StyledInputs';
import { colors } from '../styles';

const BudgetSelectorModal = ({ open, onClose, budgets, category, onBudgetSelect }) => {
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ p: 0, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            Select Budget to Edit
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: 'text.secondary',
              '&:hover': { color: 'text.primary' }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Choose which budget's "{category?.name}" category to edit:
        </Typography>
        <List sx={{ mt: 2 }}>
          {budgets
            .filter(budget => budget.categories?.some(c => c.name === category?.name))
            .map((budget) => {
              const budgetCategory = budget.categories.find(c => c.name === category?.name);
              return (
                <ListItem
                  key={budget._id}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '12px',
                    mb: 1,
                    '&:hover': {
                      backgroundColor: `${colors.primary}10`
                    }
                  }}
                  onClick={() => onBudgetSelect(budget, budgetCategory)}
                >
                  <ListItemText
                    primary={budget.name}
                    secondary={`${budgetCategory?.isPercentage ? 
                      `${budgetCategory.amount}% ($${((Number(budgetCategory.amount) / 100) * Number(budget.totalBudget)).toLocaleString()})` : 
                      `$${Number(budgetCategory.amount).toLocaleString()}`}`}
                  />
                  <ListItemSecondaryAction>
                    <ChevronRightIcon sx={{ color: colors.primary }} />
                  </ListItemSecondaryAction>
                </ListItem>
              );
          })}
        </List>
      </DialogContent>

      <DialogActions sx={{ p: 0, mt: 4 }}>
        <StyledButton variant="outlined" onClick={onClose}>
          Cancel
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default BudgetSelectorModal; 
import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Box, 
  Typography, 
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
  Paper,
  Divider,
  Avatar,
  Backdrop,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, Place as PlaceIcon } from '@mui/icons-material';
import { getBudgets } from '../services/budgetService';
import { assignBudgetCategory, categorizeSimilarTransactions } from '../services/transactionService';
import { useAuth } from '../contexts/AuthContext';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 500
  }
}));

const StyledSelect = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.grey[100],
    '&.Mui-focused': {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

const AmountDisplay = styled(Typography)(({ theme, negative }) => ({
  fontSize: '2rem',
  fontWeight: 700,
  color: negative === 'true' ? theme.palette.error.main : theme.palette.success.main,
  textAlign: 'center',
  marginBottom: theme.spacing(1)
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none'
  }
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: '0.875rem'
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  textAlign: 'right'
}));

const MapContainer = styled(Paper)(({ theme }) => ({
  height: 200,
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
  overflow: 'hidden',
  position: 'relative'
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1)
}));

const LoadingOverlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 99999 // Very high z-index
}));

const TransactionDetailModal = ({ open, onClose, transaction, onTransactionUpdate }) => {
  const { hasFeature } = useAuth();
  const [budgets, setBudgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    if (open) {
      loadBudgets();
    }
  }, [open]);

  useEffect(() => {
    if (transaction?.budgetCategory?.id) {
      setSelectedCategory(`${transaction.budget}_${transaction.budgetCategory.id}`);
    } else {
      setSelectedCategory('');
    }
  }, [transaction]);

  useEffect(() => {
    if (!open) {
      setSnackbar({ open: false, message: '', severity: 'success' });
    }
  }, [open]);

  useEffect(() => {
    if (transaction) {
      setSnackbar({ open: false, message: '', severity: 'success' });
    }
  }, [transaction?._id]);

  const loadBudgets = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getBudgets();
      setBudgets(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error loading budgets:', error);
      setError('Failed to load budgets');
      setBudgets([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = () => {
    setCategoryDialogOpen(true);
  };

  const handleCategorySelect = async (budgetId, categoryId, categoryName) => {
    try {
      setLoading(true);
      
      // If budgetId and categoryId are null, we're uncategorizing
      if (budgetId === null && categoryId === null) {
        // Update local state
        setSelectedCategory('');
        if (onTransactionUpdate) {
          // Create an updated transaction without budget category
          const updatedTransaction = {
            ...transaction,
            budget: null,
            budgetCategory: null
          };
          await onTransactionUpdate(updatedTransaction);
        }
        setCategoryDialogOpen(false);
        return;
      }

      // Otherwise, proceed with normal category assignment
      const response = await assignBudgetCategory(transaction._id, budgetId, categoryId);
      setSelectedCategory(`${budgetId}_${categoryId}`);
      if (onTransactionUpdate) {
        await onTransactionUpdate(response.data);
      }
      setCategoryDialogOpen(false);
    } catch (error) {
      console.error('Error assigning category:', error);
      setError('Failed to assign category');
    } finally {
      setLoading(false);
    }
  };

  const handleCategorizeSimilar = async () => {
    if (!hasFeature('ai_categorization')) {
      setSnackbar({
        open: true,
        message: 'Upgrade to Premium to use AI-powered categorization',
        severity: 'warning'
      });
      return;
    }

    if (!selectedCategory) {
      setSnackbar({
        open: true,
        message: 'Please categorize this transaction first',
        severity: 'warning'
      });
      return;
    }

    try {
      setLoading(true);
      const [budgetId, categoryId] = selectedCategory.split('_');
      
      const result = await categorizeSimilarTransactions(
        transaction._id,
        budgetId,
        categoryId
      );
      
      const budget = budgets.find(b => b._id === budgetId);
      const category = budget?.categories.find(c => c._id === categoryId);
      
      const categoryInfo = {
        budget: budgetId,
        budgetCategory: {
          id: categoryId,
          name: category?.name,
          icon: category?.icon,
          assignedAt: new Date(),
          assignedBy: 'user'
        }
      };

      const updatedTransaction = {
        ...transaction,
        ...categoryInfo,
        bulkUpdate: true,
        similarCriteria: {
          merchantName: transaction.merchantName,
          description: transaction.description,
          merchantEntityId: transaction.merchantEntityId,
          categoryInfo
        },
        updatedCount: result.results.success
      };
      
      if (onTransactionUpdate) {
        await onTransactionUpdate(updatedTransaction);
      }
      
      setSnackbar({
        open: true,
        message: `Successfully categorized ${result.results.success} similar transactions`,
        severity: 'success'
      });
    } catch (err) {
      console.error('Error categorizing similar transactions:', err);
      setSnackbar({
        open: true,
        message: 'Failed to categorize similar transactions',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const formatAmount = (amount, isoCurrencyCode = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: isoCurrencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Math.abs(amount));
  };

  const formatDate = (date) => {
    if (!date) return null;
    
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    };

    try {
      return new Date(date).toLocaleString('en-US', options);
    } catch (e) {
      return date;
    }
  };

  const renderMap = () => {
    if (!transaction?.location?.lat || !transaction?.location?.lon || !process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
      return null;
    }

    const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${transaction.location.lat},${transaction.location.lon}&zoom=15`;

    return (
      <Box>
        <SectionTitle variant="subtitle2">Location</SectionTitle>
        <MapContainer>
          <iframe
            title="Transaction Location"
            width="100%"
            height="100%"
            frameBorder="0"
            src={mapUrl}
            allowFullScreen
          />
        </MapContainer>
        <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PlaceIcon fontSize="small" />
          {[
            transaction.location.address,
            transaction.location.city,
            transaction.location.region,
            transaction.location.postalCode,
            transaction.location.country
          ].filter(Boolean).join(', ')}
        </Typography>
      </Box>
    );
  };

  const renderDetailSection = (title, details) => {
    const filteredDetails = Object.entries(details).filter(([_, value]) => value != null);
    if (filteredDetails.length === 0) return null;

    return (
      <Box>
        <SectionTitle variant="subtitle2">{title}</SectionTitle>
        {filteredDetails.map(([key, value]) => (
          <DetailRow key={key}>
            <DetailLabel>{key.replace(/([A-Z])/g, ' $1').trim()}</DetailLabel>
            <DetailValue>{
              typeof value === 'boolean' 
                ? value.toString() 
                : value instanceof Date 
                  ? formatDate(value)
                  : value
            }</DetailValue>
          </DetailRow>
        ))}
      </Box>
    );
  };

  if (!transaction) return null;

  return (
    <>
      {loading && (
        <LoadingOverlay>
          <CircularProgress />
        </LoadingOverlay>
      )}
      
      <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ p: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box />
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          {/* Header Section */}
          <Box sx={{ 
            mb: 3, 
            pb: 3, 
            borderBottom: 1, 
            borderColor: 'divider',
            textAlign: 'center' 
          }}>
            <AmountDisplay negative={(transaction.amount > 0).toString()}>
              {transaction.amount > 0 ? '-' : '+'}{formatAmount(transaction.amount, transaction.isoCurrencyCode)}
            </AmountDisplay>
            <Typography variant="h6" gutterBottom>
              {transaction.merchantName || transaction.description}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {formatDate(transaction.date)}
              </Typography>
              {transaction.pending && (
                <Chip
                  label="Pending"
                  size="small"
                  color="warning"
                  variant="outlined"
                />
              )}
            </Box>
            {(transaction?.account?.name || transaction?.account?.mask) && (
              <Typography variant="body2" color="text.secondary">
                {transaction.account.displayName || transaction.account.name}{transaction.account.mask ? ` •••• ${transaction.account.mask}` : ''}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              {error ? (
                <Typography color="error" variant="body2">{error}</Typography>
              ) : (
                <Chip
                  label={selectedCategory ? budgets
                    .find(b => selectedCategory.startsWith(b._id))
                    ?.categories.find(c => selectedCategory.endsWith(c._id))?.name || 'Uncategorized'
                    : 'Uncategorized'
                  }
                  onClick={handleCategoryClick}
                  sx={{
                    borderRadius: '16px',
                    bgcolor: selectedCategory ? 'primary.main' : 'grey.200',
                    color: selectedCategory ? 'white' : 'text.secondary',
                    '&:hover': {
                      bgcolor: selectedCategory ? 'primary.dark' : 'grey.300',
                    },
                    fontWeight: 500,
                    px: 2
                  }}
                />
              )}
            </Box>
          </Box>

          {/* Location Map */}
          {renderMap()}

          {/* Transaction Details */}
          {transaction.counterparties?.length > 0 && (
            <Box>
              <SectionTitle variant="subtitle2">Counterparties</SectionTitle>
              {transaction.counterparties.map((cp, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                    {cp.logoUrl && (
                      <Avatar
                        src={cp.logoUrl}
                        alt={cp.name}
                        sx={{ width: 24, height: 24 }}
                      />
                    )}
                    <Typography variant="subtitle2">{cp.name}</Typography>
                    <Chip 
                      label={cp.type} 
                      size="small"
                      sx={{ 
                        textTransform: 'capitalize',
                        bgcolor: 'grey.100'
                      }}
                    />
                  </Box>
                  {cp.website && (
                    <Typography 
                      variant="body2" 
                      component="a"
                      href={cp.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ 
                        color: 'primary.main',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {cp.website}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}

          {renderDetailSection('Payment Details', {
            'Payment Channel': transaction.paymentChannel,
            'Transaction Type': transaction.transactionType,
            'By Order Of': transaction.paymentMeta?.byOrderOf,
            'Payee': transaction.paymentMeta?.payee,
            'Payer': transaction.paymentMeta?.payer,
            'Payment Method': transaction.paymentMeta?.paymentMethod,
            'Payment Processor': transaction.paymentMeta?.paymentProcessor,
            'PPD ID': transaction.paymentMeta?.ppdId,
            'Reason': transaction.paymentMeta?.reason,
            'Reference Number': transaction.paymentMeta?.referenceNumber
          })}

          {renderDetailSection('Timing', {
            'Transaction Date': formatDate(transaction.date),
            'Authorized Date': formatDate(transaction.authorizedDate),
            'Authorized Time': formatDate(transaction.authorizedDatetime),
            'Posted Time': formatDate(transaction.datetime)
          })}

          {/* AI Categorization Section - Only show if premium */}
          {transaction.personalFinanceCategory && hasFeature('ai_categorization') && (
            <Box>
              <SectionTitle variant="subtitle2">AI Categorization</SectionTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                {transaction.personalFinanceCategory.iconUrl && (
                  <Avatar
                    src={transaction.personalFinanceCategory.iconUrl}
                    alt={transaction.personalFinanceCategory.primary}
                    sx={{ width: 24, height: 24 }}
                  />
                )}
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {transaction.personalFinanceCategory.primary}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {transaction.personalFinanceCategory.detailed}
                  </Typography>
                </Box>
                <Chip
                  label={`${(transaction.personalFinanceCategory.confidence * 100).toFixed(0)}%`}
                  size="small"
                  color={
                    transaction.personalFinanceCategory.confidence > 0.8 ? 'success' :
                    transaction.personalFinanceCategory.confidence > 0.6 ? 'info' :
                    transaction.personalFinanceCategory.confidence > 0.4 ? 'warning' : 'error'
                  }
                />
              </Box>
            </Box>
          )}

          {/* Original Description */}
          {transaction.originalDescription && (
            <Box>
              <SectionTitle variant="subtitle2">Original Description</SectionTitle>
              <Typography variant="body2" color="text.secondary" sx={{ wordBreak: 'break-all' }}>
                {transaction.originalDescription}
              </Typography>
            </Box>
          )}

          {/* Category Selection Dialog */}
          <Dialog 
            open={categoryDialogOpen} 
            onClose={() => setCategoryDialogOpen(false)}
            PaperProps={{
              sx: {
                borderRadius: '20px',
                width: '100%',
                maxWidth: 400,
                p: 2
              }
            }}
          >
            <DialogTitle>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Select Category</Typography>
                <IconButton onClick={() => setCategoryDialogOpen(false)} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ pt: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Chip
                  label="Uncategorized"
                  onClick={() => handleCategorySelect(null, null)}
                  sx={{
                    borderRadius: '16px',
                    bgcolor: !selectedCategory ? 'primary.main' : 'grey.200',
                    color: !selectedCategory ? 'white' : 'text.secondary',
                    '&:hover': {
                      bgcolor: !selectedCategory ? 'primary.dark' : 'grey.300',
                    }
                  }}
                />
                {budgets.map(budget => (
                  <Box key={budget._id}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      {budget.name}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {budget.categories.map(category => (
                        <Chip
                          key={category._id}
                          label={category.name}
                          onClick={() => handleCategorySelect(budget._id, category._id, category.name)}
                          sx={{
                            borderRadius: '16px',
                            bgcolor: selectedCategory === `${budget._id}_${category._id}` ? 'primary.main' : 'grey.200',
                            color: selectedCategory === `${budget._id}_${category._id}` ? 'white' : 'text.secondary',
                            '&:hover': {
                              bgcolor: selectedCategory === `${budget._id}_${category._id}` ? 'primary.dark' : 'grey.300',
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </DialogContent>
          </Dialog>

          {selectedCategory && (
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCategorizeSimilar}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                {loading ? 'Categorizing...' : 'Auto-Categorize Similar'}
              </Button>
            </Box>
          )}
        </DialogContent>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </StyledDialog>
    </>
  );
};

export default TransactionDetailModal; 
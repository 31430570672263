const LOG_LEVELS = {
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info',
  DEBUG: 'debug'
};

const isDevelopment = process.env.NODE_ENV === 'development';
console.log('Logger initialized:', { isDevelopment, NODE_ENV: process.env.NODE_ENV });

class Logger {
  static error(message, error = null, context = {}) {
    console.error(message, { error, context, timestamp: new Date().toISOString() });
  }

  static warn(message, context = {}) {
    console.warn(message, { context, timestamp: new Date().toISOString() });
  }

  static info(message, context = {}) {
    console.info(message, { context, timestamp: new Date().toISOString() });
  }

  static debug(message, context = {}) {
    // Always log in development, and also log when manually enabled
    if (isDevelopment || localStorage.getItem('debug_logging') === 'true') {
      console.debug(message, { context, timestamp: new Date().toISOString() });
    }
  }

  static transaction(action, data = {}) {
    if (isDevelopment || localStorage.getItem('debug_logging') === 'true') {
      console.debug(`Transaction ${action}:`, {
        ...data,
        timestamp: new Date().toISOString()
      });
    }
  }
}

// Log initial state
Logger.debug('Logger initialized', { isDevelopment });

export default Logger;

import React from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Avatar } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SavingsIcon from '@mui/icons-material/Savings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExploreIcon from '@mui/icons-material/Explore';
import { useAuth } from '../contexts/AuthContext';
import { colors } from '../styles';
import { capturePageContext } from '../services/githubService';

const drawerWidth = 240;

const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const topMenuItems = [
    { text: 'Activity', icon: <AccessTimeIcon />, path: '/activity' },
    { text: 'Budgets', icon: <SavingsIcon />, path: '/budgets' },
    { text: 'Money', icon: <AccountBalanceIcon />, path: '/money' },
    { text: 'Discover', icon: <ExploreIcon />, path: '/discover' },
  ];

  const bottomMenuItems = [
    { 
      text: 'Account', 
      icon: user?.profileImage ? (
        <Avatar 
          src={user.profileImage} 
          sx={{ 
            width: 32, 
            height: 32,
            border: `1px solid ${colors.primary}`,
            marginLeft: '-4px'
          }}
        />
      ) : <AccountCircleIcon />, 
      path: '/account' 
    },
    { 
      text: 'Support', 
      icon: <HelpIcon />, 
      path: '/support',
      onClick: () => {
        // Capture page context before navigating
        const context = capturePageContext();
        sessionStorage.setItem('pageContext', JSON.stringify(context));
        navigate('/support');
      }
    },
  ];

  const ListItemStyled = ({ item }) => {
    const isActive = location.pathname === item.path;
    return (
      <ListItem
        component={item.onClick ? 'div' : Link}
        to={!item.onClick ? item.path : undefined}
        onClick={item.onClick}
        sx={{
          borderRadius: '0.5rem',
          my: 0.5,
          color: isActive ? colors.primary : colors.secondary,
          cursor: 'pointer',
          transition: 'transform 80ms cubic-bezier(0, 0.3, 1, 0.5), background 80ms cubic-bezier(0, 0.3, 1, 0.5)',
          '&:hover': {
            backgroundColor: isActive ? `${colors.primary}20` : 'rgba(0, 0, 0, 0.04)',
            borderRadius: '0.5rem'
          },
          '&:active': {
            transform: 'scale(0.95)',
          },
          ...(isActive && {
            backgroundColor: `${colors.primary}20`,
          }),
        }}
      >
        <ListItemIcon sx={{ 
          color: 'inherit',
          minWidth: '44px'
        }}>{item.icon}</ListItemIcon>
        <ListItemText 
          primary={item.text} 
          primaryTypographyProps={{
            fontWeight: 900,
            fontSize: '1.1rem'
          }}
        />
      </ListItem>
    );
  };

  return (
    <Box sx={{ 
      display: 'flex',
      overflowX: 'hidden', // Prevent horizontal scrolling
      width: '100vw',
      position: 'fixed',
      height: '100vh'
    }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: colors.white,
            color: colors.secondary,
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
            border: 'none'
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box 
            component="img"
            src="/images/budgit-logo.png"
            alt="Budgit Logo"
            sx={{ 
              width: 56,
              height: 56,
              marginRight: 0.5,
              objectFit: 'contain'
            }}
          />
          <Typography 
            variant="h5" 
            sx={{ 
              color: colors.primary, 
              fontWeight: 800,
              letterSpacing: '-0.5px'
            }}
          >
            Budgit
          </Typography>
        </Box>
        <List>
          {topMenuItems.map((item) => (
            <ListItemStyled key={item.text} item={item} />
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <List>
          {bottomMenuItems.map((item) => (
            <ListItemStyled key={item.text} item={item} />
          ))}
          <ListItem
            button
            onClick={() => {
              // Handle async logout
              logout().catch(error => {
                console.error('Error during logout:', error);
              });
            }}
            sx={{
              borderRadius: '0.5rem',
              my: 0.5,
              color: colors.secondary,
              cursor: 'pointer',
              transition: 'transform 80ms cubic-bezier(0, 0.3, 1, 0.5), background 80ms cubic-bezier(0, 0.3, 1, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '0.5rem'
              },
              '&:active': {
                transform: 'scale(0.95)',
              },
            }}
          >
            <ListItemIcon sx={{ 
              color: 'inherit',
              minWidth: '44px'
            }}><ExitToAppIcon /></ListItemIcon>
            <ListItemText 
              primary="Logout"
              primaryTypographyProps={{
                fontWeight: 900,
                fontSize: '1.1rem'
              }}
            />
          </ListItem>
        </List>
      </Drawer>
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          height: '100vh',
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;